import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FileDownload from "js-file-download";
import axios from 'axios';
import { toast } from 'react-toastify';
import { getMonth } from "utility/utils";
import { getCheckData, getMrData, getTopFivePlans, getTSPData, saveMrData, getImarTableDataDashboard } from '../../../../store/actions';
import { URLs } from '../../mrplan/ApiConstants';
import { MR_TITLE, TSP_TITLE } from '../spendplanUtils';
import DashboardMain from './dashboardMain';
import MRStats from './mrStats';
import MyTask from './Mytask';
import MyTasksTSP from './myTasksTSP';
import TopFivePlans from './TopFivePlans';
import { columnsWithNumberValue, data, fixedWidthColumns75, fixedWidthColumns90, headingsLabels } from '../ReportsPages/IMARReports/Imar1Report/data';
import {
	makeStyles,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	withStyles,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	ListItemText,
	Checkbox
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { convertToNumber } from '../PlanningPages/planningHelperFunctions';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useStyles = makeStyles(theme => ({
	dashboard: {
		'@media(max-width:1366px)': {
			height: "80vh",
		},
	},
	root: {
		display: 'flex',
		marginBottom: '10px',
	},
	containerMain: {
		display: 'flex',
		flexWrap: 'wrap',
		padding: '0 20px',
		justifyContent: 'space-between',
	}
}));


const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	anchorOrigin: {
		vertical: "bottom",
		horizontal: "left"
	},
	getContentAnchorEl: null,
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};
const options = [
	{
		category: 'Setup',
		subOptions: [
			{ key: 'PID Mapping', value: '63315c932936a60013bc8106' },
			{ key: 'PY Actuals Retailing', value: '63354788422b62002b61d480' },
			{ key: 'Claims', value: 'claims' },
			{ key: 'FY Actuals Retailing', value: '6331b9a41a2ca4001a8cacc4' },
			{ key: 'SKU Level Offtake', value: '6336adebe993d4001a98c06c' },
			{ key: 'PY Metric', value: '634fee142d9253002c6fc3b0' },
			{ key: 'Retailing Offtake Planning', value: '63907c9a60e161001313cc3c' },
			{ key: 'Offtake Brand VSOD', value: '6396eff21636600020b89c8b' },
			{ key: 'Retail Brand VSOD', value: '6393459085f49e001be9e655' },
			{ key: 'TS SKU Salience', value: '6399848cb738780020513772' },
		]
	},
	{
		category: 'Planning',
		subOptions: [
			{ key: 'Promo Calendar', value: 'promocalendar' },
			{ key: 'Spends Planning', value: '633ed8695c7fe5001213846a' },
			{ key: 'TFI Earnings', value: 'tiffDownload' },
			{ key: 'Media Calendar', value: 'mediaCalendar' },
		]
	},
	{
		category: 'Report',
		subOptions: [
			{ key: 'OUM Checklist', value: '634917cdc2ae80002b8f8481' },
			{ key: 'Engine Earnings', value: 'omni-engine-earning' },
			{ key: 'TS Brand Summary (Sheet 1)', value: 'ts-brand-summary' },
			{ key: 'Brandform Summary', value: 'brandform-summary' },
			{ key: 'Executive Summary (Talksheet)', value: 'omni-executive-summary' },
			{ key: 'Account Summary2 (Top Sheet2)', value: 'account-summary2' },
			{ key: 'Account Summary (Top Sheet)', value: 'accountSummary' },
			{ key: 'Communication Report', value: 'communication' }
		]
	},
	{
		category: 'Configure',
		subOptions: [
			{ key: 'SKU Master', value: '632c047e47d5c3001acf54a8' },
			{ key: 'ITT Master', value: '632c833f9358d20019927370' },
			{ key: 'Master Classification', value: '633a950b4ba700001af91eb1' }
		]
	}
]

const Dashboard = props => {

	const { getTopFivePlans, page, brand, getCheckData, updatedData, account, getImarTableDataDashboard, selectScenario } = props;
	const [dashboardReportData, setDashboardReportData] = useState(null);
	// const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [isSelectAll, setIsSelectAll] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);

	const handleOpenDialog = () => {
		setOpenDialog(true);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};


	const handleDownload = () => {
		handleCloseDialog();
		let _currentFF = '' + getMonth(props.account?.CurrentFF, 'monthNumber')
		_currentFF = _currentFF.length === 1 ? '0' + _currentFF : _currentFF;
        let payload = {
			account: props.account?.AccountName,
			fiscalYear: props.account?.FinancialYear + "",
			currentFF: _currentFF,
			selectedDataModels: selectedOptions
		}
        let url = `${API_BASE_URL}` + URLs.getAlldownload;
        axios({
            url: url,
            method: 'POST',
            data: payload,
			responseType: 'blob',
            headers: {
                appId: props.match.params.appid,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                FileDownload(response.data, `alldownlaod_${props.account?.AccountName}_${props.account?.FinancialYear}_${props.account?.CurrentFF}.xlsx`);
				setSelectedOptions([]);
            })
            .catch((error) => {
                toast.error(error?.data?.message || 'Not able to send workflow emails');
            });
	};

	let allIds = options.map((el) => el.subOptions.flatMap(obj => obj?.value))
	allIds = allIds.reduce((acc, curr) => {
		return acc.concat(...curr);
	 }, []);

	const handleChange = (event, key, val) => {
		const subOption = event.target.value;
		if (key === 'selectAll') {
			setIsSelectAll(prevState => !prevState);
			if (val) {
				setSelectedOptions(allIds)
			} else {
				setSelectedOptions([]);
			}
		} else {
			const index = selectedOptions.indexOf(subOption);
			if (index !== -1) {
				setSelectedOptions(prevItems => prevItems.filter(item => item !== subOption));
			} else {
				setSelectedOptions(prevItems => [...prevItems, subOption]);
			}
		}
		// switch (category) {
		// 	case 'Setup': 
		// 	break;
		// 	case 'Planning': 
		// 	break;
		// 	case 'Report': 
		// 	break;
		// 	case 'Configure': 
		// 	break;
		// }
	};

	useEffect(() => {
		if (selectedOptions.length > 0 && selectedOptions?.length === allIds?.length) {
			setIsSelectAll(true);
		} else {
			setIsSelectAll(false);
		}
	}, [selectedOptions, allIds])

	useEffect(() => {
		if (props.imarDataDashboard && props.imarDataDashboard.length) {
			mapResponse(props.imarDataDashboard)
		}
	}, [props.imarDataDashboard])
	const classes = useStyles();

	const sortData =
		page && page.chart && page.chart.length > 0 &&
		page.chart.filter(cData => cData._id != '60484d8f268b0200135e2bc0');

	const newData = [];
	for (let i = 0; i < sortData.length; i++) {
		if (sortData[i].chartData.name === 'Setup Data') {
			newData[0] = sortData[i];
		} else if (sortData[i].chartData.name === 'Review Reports') {
			newData[2] = sortData[i];
		} else if (sortData[i].chartData.name === 'Monthly Spend Plan') {
			newData[1] = sortData[i];
		} else if (sortData[i].chartData.name === 'Configure') {
			newData[3] = sortData[i];
		}
	}

	useEffect(() => {
		if (brand && props.app.title === TSP_TITLE) {
			fetchData();
		}
	}, [brand, selectScenario]);

	useEffect(() => {
		if (account && props.app.title === MR_TITLE) {
			fetchData();
		}
	}, [account]);

	const mapResponse = (response) => {
		//TODO: need to refactor this function when all 15 is done.
		try {
			let data4Rows = [], data15Rows = [];
			response.forEach((item) => {
				if (item.Section === 4) {
					const { H2_H1, Row, Section, ...rest } = item;
					data4Rows.push(rest);
				}
				if (item.Section === 15) {
					data15Rows.push(item);
				}
			});

			let _data = JSON.parse(JSON.stringify(data));
			_data['data4']['tableData']['rows'] = data4Rows;
			_data['data15']['tableData']['rows'] = data15Rows;
			setDashboardReportData(_data);
		} catch (e) {
			console.log(e)
		}
	};

	let arr = ["01", "02", "03"];
	let filteredArray = arr.filter(x => x !== selectScenario);

	const fetchData = () => {
		let { BrandName, FinancialYear, CurrentFF } = brand;
		let m = '' + getMonth(CurrentFF, 'monthNumber')
		m = m.length === 1 ? '0' + m : m;
		if (props.app.title === TSP_TITLE) {
			// getTopFivePlans({
			// 	appid: props.match.params.appid, brand: BrandName,
			// 	fiscalYear: FinancialYear, currentFF: m,
			// });

			getImarTableDataDashboard({
				appid: props.match.params.appid, brand: BrandName,
				fiscalYear: FinancialYear, currentFF: m, scenario: selectScenario,
				scenarioArr: filteredArray
			});
			getCheckData({
				appid: props.match.params.appid,
				body: {
					formTypes: ["bopvolumes", "rlppermsu", "pyrlp", "rlpsalience", "pyrlppermsu", "rlpytdact", "tpr",
						'spConsumerPromotions', 'spConversionFunds', 'spDisplays', 'spMRandEcommTFIs', 'spTrialDiscounts',
						'itt', 'cts', 'tdc', 'mrecomm', 'corpbud', 'accrualshc', 'f1inputs'
					],
					brand: BrandName,
					fiscalYear: FinancialYear + "",
					currentFF: m,
					scenario: selectScenario,
					action: "finish"
				}
			})
			BrandName = encodeURIComponent(BrandName)
			let getActionsPayload = {
				url: '/setupdata/myActions?brandName=' + BrandName + '&currentFF=' + m + '&fiscalYear=' + props.brand.FinancialYear + '&scenario=' + selectScenario,
				appId: props.match.params.appid,
				dataUniqueId: 'GET_ActionData'
			}
			props.getTSPData(getActionsPayload)
		} else {
			let _currentFF = '' + getMonth(props.account?.CurrentFF, 'monthNumber')
			_currentFF = _currentFF.length === 1 ? '0' + _currentFF : _currentFF;
			let payload = {
				url: URLs.getMrActions,
				appId: props.match.params.appid,
				dataUniqueId: 'MR_actions',
				payload: {
					account: props.account?.AccountName,
					fiscalYear: props.account?.FinancialYear + "",
					currentFF: _currentFF,
					action: "finish"
				}
			}
			props.saveMrData(payload)
			let payloadForAction = {
				url: URLs.getDashboardAction + `?account=${encodeURIComponent(props.account?.AccountName)}&fiscalYear=${props.account?.FinancialYear}&currentFF=${_currentFF}`,
				appId: props.match.params.appid,
				dataUniqueId: 'GET_myActionTask'
			}
			props.getMrData(payloadForAction)
		}
	}

	const getMrActionData = () => {
		if (props.mrData && props.mrData['MR_actions']) {
			return props.mrData['MR_actions']?.result?.data
		}
	}

	const renderTableHeading = (headings, _index) => {
		console.log('headings', headings);
		const getCss = (header) => {
			if (fixedWidthColumns75.includes(header)) {
				return { whiteSpace: 'normal', textAlign: 'center', width: 75 };
			}
			if (fixedWidthColumns90.includes(header)) {
				return { whiteSpace: 'normal', textAlign: 'center', width: 90 };
			}
			return { whiteSpace: 'normal', textAlign: 'center' };
		};

		const isBrandFormKey = (header) => {
			return [5, 6, 15].includes(_index) && header === 'Metrics'
		}
		const { H2_H1, ...headingLabele } = headingsLabels;
		return (
			<StyledTableRow className='sub-table-head'>
				<StyledTableCell className='white-background'>
				</StyledTableCell>
				{
					headings.filter((el) => el !== 'H2_H1').map((header, index) =>
					(<StyledTableCell key={index} className='top-border' style={getCss(header)}>
						<span dangerouslySetInnerHTML={{ __html: headingLabele[header] && headingLabele[header] !== 'FY' ? headingsLabels[header] : isBrandFormKey(header) ? 'Brandforms' : header }} />
					</StyledTableCell>))
				}
			</StyledTableRow>
		);
	};

	const renderTableBody = (num, rows, headers, highlightLastThreeRow, percentageColumns) => {

		const arr = [3, 4, 5, 6, 7, 11, 12, 13, 15]; // last row class needed

		const getClass = (ind) => {
			let _class = '';
			if (ind + 1 === rows.length && arr.includes(num)) {
				_class += 'last-row';
			}
			if (highlightLastThreeRow && (ind + 2 === rows.length || ind + 3 === rows.length)) {
				_class += ' bold-blue-bg';
			}
			return _class;
		};

		const getClass1 = (ind, column, rowSpan) => {
			let _class = '';
			if (ind + 1 === rows.length || rowSpan === 2) {
				_class += 'bottom-border';
			}
			if (column['Description'] === 'Difference') {
				_class += ' bold-blue-bg';
			}
			return _class;
		};


		const leftAlignColumns = ['Description', 'Metrics', 'Channel', 'Brandforms'];

		const getVal = (header, i) => {
			if (percentageColumns?.includes(header)) {
				return i[header] === 0 ? '0.0%' : (Number(i[header])
					.toFixed(2) + '%');
			}
			return columnsWithNumberValue.includes(header) ? convertToNumber(i[header], 1) : i[header];
		};

		const getStyles = (header) => {
			let style = {};
			if (leftAlignColumns.includes(header)) {
				Object.assign(style, { textAlign: 'left', width: 175 });
			}
			if (['UoM', 'UoM_Cover', 'UoM_Trials', 'UoM_Pieces', 'UoM_Spend'].includes(header)) {
				Object.assign(style, { textAlign: 'center' });
			}
			if (fixedWidthColumns75.includes(header)) {
				Object.assign(style, { width: 75 });
			}
			if (fixedWidthColumns90.includes(header)) {
				Object.assign(style, { width: 90 });
			}
			return style;
		};

		const getBGColor = (key, value, rowItem, index) => {
			if (value === "" || num === 15 || ((num === 4 || num === 7 || num === 3) && index + 1 === rows.length)) {// 15th formatting not required
				return {}  // row 4 ,7, 3 will not be override with green, red, white color
			}
			let style = {};
			if (key === 'BOP_FY' && !(index + 1 === rows.length && arr.includes(num))) {  // excluding last row of table
				Object.assign(style, { backgroundColor: '#ffff99' })
			}

			if (num === 1 && rowItem['Description'] === 'Difference' && rowItem['Metrics'] === 'Volume') {
				if (['BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY'].includes(key)) {
					if (value > 0) {
						Object.assign(style, { backgroundColor: '#ff0000', color: '#ffffff' })
						return style;
					}
					if (value < 0) {
						Object.assign(style, { backgroundColor: '#05af50', color: '#ffffff' })
						return style;
					}
					if (value === 0) {
						Object.assign(style, { backgroundColor: '#ffffff' })
					}
				}
			}

			if (num === 1 && rowItem['Description'] === 'Difference' && rowItem['Metrics'] === 'Net Realization') {
				if (['BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY'].includes(key)) {
					if (value > 0) {
						Object.assign(style, { backgroundColor: '#05af50' })
						return style;
					}
					if (value < 0) {
						Object.assign(style, { backgroundColor: '#ff0000' })
						return style;
					}
					if (value === 0) {
						Object.assign(style, { backgroundColor: '#ffffff' })
					}
				}
			}
			if (num === 2 && rowItem['Description'] === 'Difference' && rowItem['Metrics'] === 'MSASD') {
				if (['BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY'].includes(key)) {
					if (value > 0) {
						Object.assign(style, { backgroundColor: '#05af50' })
						return style;
					}
					if (value < 0) {
						Object.assign(style, { backgroundColor: '#ff0000' })
						return style;
					}
					if (value === 0) {
						Object.assign(style, { backgroundColor: '#ffffff' })
					}
				}
			}
			if ((num === 3 || num === 4) && key === 'H2_H1') {
				if (value && Number(value.toString().replace("%", "")) > 0) {
					Object.assign(style, { backgroundColor: '#05af50' })
					return style;
				}
				if ((value && Number(value.toString().replace("%", "")) < 0) || (value.startsWith && value.startsWith("-"))) {
					Object.assign(style, { backgroundColor: '#ff0000' })
					return style;
				}
				if (value && Number(value.toString().replace("%", "")) === 0) {
					Object.assign(style, { backgroundColor: '#ffffff' })
				}
			}
			if ((num === 5 || num === 6) && key === 'H2_H1' || key === 'Delta') {
				if (value && Number(value.toString().replace("%", "")) > 0) {
					Object.assign(style, { backgroundColor: '#05af50' })
					return style;
				}
				if ((value && Number(value.toString().replace("%", "")) < 0) || (value.startsWith && value.startsWith("-"))) {
					Object.assign(style, { backgroundColor: '#ff0000' })
					return style;
				}
				if (value && Number(value.toString().replace("%", "")) === 0) {
					Object.assign(style, { backgroundColor: '#ffffff' })
				}
			}
			if (num === 7 && (key === 'Delta' || key === 'IYADeltaVsTotal')) {
				if (value > 0) {
					Object.assign(style, { backgroundColor: '#05af50' })
					return style;
				}
				if (value < 0 || (value.startsWith && value.startsWith("-"))) {
					Object.assign(style, { backgroundColor: '#ff0000' })
					return style;
				}
				if (value === 0) {
					Object.assign(style, { backgroundColor: '#ffffff' })
				}
			}
			if (num === 8 && rowItem['Description'] === 'Difference' &&
				['Retailing (PM - MR)', 'KBD 1 TFI (PM - MR)', 'KBD 2 TFI (PM - MR)'].includes(rowItem['Metrics'])) {
				if (value > 0) {
					Object.assign(style, { backgroundColor: '#05af50' })
					return style;
				}
				if (value < 0 || (value.startsWith && value.startsWith("-"))) {
					Object.assign(style, { backgroundColor: '#ff0000' })
					return style;
				}
				if (value === 0) {
					Object.assign(style, { backgroundColor: '#ffffff' })
				}
			}
			if (num === 9 && rowItem['Description'] === 'Difference' &&
				['Retailing (PM - Ecommerce)', 'Retailing (PM - Ecommerce)', 'Retailing (PM - Ecommerce)'].includes(rowItem['Metrics'])) {
				if (value > 0) {
					Object.assign(style, { backgroundColor: '#05af50' })
					return style;
				}
				if (value < 0 || (value.startsWith && value.startsWith("-"))) {
					Object.assign(style, { backgroundColor: '#ff0000' })
					return style;
				}
				if (value === 0) {
					Object.assign(style, { backgroundColor: '#ffffff' })
				}
			}
			if (num === 10 && rowItem['Description'] === 'Difference' &&
				['Retailing (Corp - TS Sheet)', 'Retailing (Corp - TS Sheet)'].includes(rowItem['Metrics'])) {
				if (value > 0) {
					Object.assign(style, { backgroundColor: '#05af50' })
					return style;
				}
				if (value < 0 || (value.startsWith && value.startsWith("-"))) {
					Object.assign(style, { backgroundColor: '#ff0000' })
					return style;
				}
				if (value === 0) {
					Object.assign(style, { backgroundColor: '#ffffff' })
				}
			}


			if ([11, 12, 13].includes(num) && ['Spend_Change', 'Cover_Change', 'Trials_Change', 'SKU_Change'].includes(key)) {
				if (value > 0) {
					Object.assign(style, { backgroundColor: '#ff0000' })
					return style;
				}
				if (value < 0 || (value.startsWith && value.startsWith("-"))) {
					Object.assign(style, { backgroundColor: '#05af50' })
					return style;
				}
				if (value === 0) {
					Object.assign(style, { backgroundColor: '#ffffff' })
				}
			}
			if (num === 14) {
				if (key === 'PY_AMJ') {
					Object.assign(style, { backgroundColor: '#ffff99' })
					return style;
				}
				if (['JAS_Perc', 'OND_Perc', 'JFM_Perc', 'AMJ_Perc'].includes(key)) {
					if (value && Number(value.toString().replace("%", "")) > 0) {
						Object.assign(style, { backgroundColor: '#05af50' })
						return style;
					}
					if ((value && Number(value.toString().replace("%", "")) < 0) || (value.startsWith && value.startsWith("-"))) {
						Object.assign(style, { backgroundColor: '#ff0000' })
					}
				}

			}

			return style;
		}

		const getRowSpan = (num, rowIndex, colIndex) => {
			return (num === 8 || num === 9) && (rowIndex === 0 || rowIndex === 2) && colIndex === 0 ? 2 : 1;
		};

		const hideCell = (num, rowIndex, colIndex) => {
			return (num === 8 || num === 9) && (rowIndex === 1 || rowIndex === 3) && colIndex === 0;
		};

		const formatting = (row) => {
			return (row['UoM']?.includes('INR') || row['UoM']?.includes('Crores')) ? 'en-In' : 'en-US';
		}
		const metricIn = ['All HFS', 'HFS-Brand', 'HFS-Corp', 'HFS-Total', 'Modern Retail', 'Ecommerce', 'CSD/CPC/UPC/B2B', 'DTC', 'All Channels', 'Total'];

		return <>
			{
				rows.filter((el) => metricIn.includes(el.Metrics)).map((i, index) => {
					return (
						<StyledTableRow key={index} className={getClass(index)}>
							<StyledTableCell className='white-background remove-bottom-border'>
							</StyledTableCell>
							{
								headers.filter((el) => el !== 'H2_H1').map((header, index1) => {
									let rowSpan = getRowSpan(num, index, index1);
									if (!hideCell(num, index, index1))
										return (
											<StyledTableCell key={index1}
												style={((index + 1 !== rows.length || !arr.includes(num)) || index + 1 === rows.length && arr.includes(num)) ? { ...getStyles(header), ...getBGColor(header, i[header] || "", i, index) } : { ...getStyles(header) }}
												className={getClass1(index, i, rowSpan)}
												rowSpan={rowSpan}>
												<span title={((i[header] + "") + (percentageColumns?.includes(header) ? '%' : ''))}>{getVal(header, i) ? getVal(header, i).toLocaleString(formatting(i)) : '0'}</span>
											</StyledTableCell>
										);
								})
							}
						</StyledTableRow>
					);
				})
			}
			<StyledTableRow>
				<StyledTableCell className='white-background remove-right-border top-border'>
				</StyledTableCell>
			</StyledTableRow>
		</>;
	};

	const myTasks = (props.getData && props.getData['GET_ActionData']) ? props.getData['GET_ActionData'] : []
	const isOmniChannel = props.account?.form === '632c4ed2d6b72100210926c7' ? true : false;

	const renderNoDataText = () => {
		let textToShow = 'No Data Found';
		return (
			<StyledTableRow>
				<StyledTableCell colSpan="9" style={{ textAlign: 'center' }}>
					<h4 style={{ color: '#000' }}>{textToShow}</h4>
				</StyledTableCell>
			</StyledTableRow>
		);
	};

	const data4 = dashboardReportData && dashboardReportData?.dashBoard2Data4?.tableData?.headings?.map(element =>
		element === 'BOP_FY' ? `FY_${selectScenario}` : element === 'BOP_FY_2' ? `FY_${filteredArray[0]}` : element === 'BOP_FY_3' ? `FY_${filteredArray[1]}` : element === 'vs_FY_2' ? `vs_FY_${filteredArray[0]}` : element === 'vs_FY_3' ? `vs_FY_${filteredArray[1]}` : element
	);

	return (
		<div className={classes.dashboard}>
			<div className={'custom-page'} style={{ textAlign: 'right' }}>
				{isOmniChannel && <Button
					variant="contained"
					color="primary"
					className={'m-right-10'}
					size="medium"
					onClick={handleOpenDialog}
					startIcon={<GetAppIcon />}
				>
					{'Download'}
				</Button>}
				<div className='dialog-model' style={{ width: '100%' }}>
					<Dialog open={openDialog} className='custom-dialog' fullWidth style={{ maxWidth: '1000px !important', margin: '-100px auto 0' }}>
						<DialogTitle className='dwn-model-title'>{"Download Confirmation"}</DialogTitle>
						<DialogContent style={{ width: '100%', minHeight: '300px' }}>
							<p className="custom-title">Please Select Below Sections To download?</p>
							<MenuItem key={`selectAll`} value={`selectAll`} className='custom-menu-item'>
								<Checkbox
									checked={isSelectAll}
									className="gray-checkbox"
									style={{ padding: '0px' }}
									onChange={(event) => handleChange(event, `selectAll`, !isSelectAll)}
									onClick={(event) => event.stopPropagation()}
								/>
								<ListItemText primary={'Select All'} className="list-text" />
							</MenuItem>
							<section className='dwn-item-block'>
								{options.map((categoryObject) => (
									<div key={categoryObject.category} className='dwn-custom-section'>
										<h3 className='dwn-custom-title'>{categoryObject.category}</h3>
										<ul>
											{categoryObject.subOptions.map((subOption) => (
												<MenuItem key={subOption?.key} value={subOption?.value} className='custom-menu-item'>
													<Checkbox
														checked={selectedOptions.indexOf(subOption?.value) > -1}
														className="gray-checkbox"
														style={{ padding: '0px' }}
														value={subOption?.value}
														onChange={(event) => handleChange(event, subOption?.key)}
														onClick={(event) => event.stopPropagation()}
													/>
													<ListItemText primary={subOption?.key} className="list-text" />
												</MenuItem>
											))}
										</ul>
									</div>
								))}
							</section>
						</DialogContent>
						<DialogActions style={{ marginBottom: '30px' }}>
							<Button color="primary" variant="contained"
								className={'m-right-10, btn-text'} style={{ width: '110px' }} onClick={handleCloseDialog}>
								Cancel
							</Button>
							<Button color="primary" variant="contained"
								className={'m-right-10, btn-text'} style={{ marginRight: '12px' }} onClick={handleDownload}>
								Download
							</Button>
						</DialogActions>

					</Dialog>
				</div>
			</div>
			<div className={classes.root}>
				{
					props.app.title === TSP_TITLE && <>
						{/* <MyTasksTSP myTasks={myTasks || []} /> */}
						{/* <TopFivePlans topFivePlans={props.topFivePlans} {...props} /> */}
						<div className='imar1-report' >
							<TableContainer className='table-container-dashboard' component={Paper}>
								<Table className='table' aria-label="simple table"
								>
									<TableHead className='table-head'>
									</TableHead>
									<TableBody className='table-body'>
										{!dashboardReportData && renderNoDataText()}
										{
											dashboardReportData &&
											<>
												<>
													{
														<StyledTableRow>
															<StyledTableCell className='white-background row-bold'>

															</StyledTableCell>
															<StyledTableCell className='row-bold top-border' style={{ textAlign: 'start' }} colSpan={15}>
																<span dangerouslySetInnerHTML={{ __html: 'Total Spends By Channel' }} />
															</StyledTableCell>
														</StyledTableRow>
													}
													{
														renderTableHeading(data4)
													}
													{
														renderTableBody(4, dashboardReportData.data4.tableData.rows, dashboardReportData.dashBoard2Data4.tableData.headings, null,
															['BOP_JAS', 'BOP_OND', 'BOP_JFM', 'BOP_AMJ', 'BOP_FY'])
													}
												</>
												{
													<StyledTableRow>
														<StyledTableCell className='white-background row-bold'>

														</StyledTableCell>
														<StyledTableCell className='row-bold top-border' style={{ textAlign: 'start' }} colSpan={10}>
															<span dangerouslySetInnerHTML={{ __html: 'Total SDe Spends' }} />
														</StyledTableCell>
													</StyledTableRow>
												}
												{
													renderTableHeading(dashboardReportData.data15.tableData.headings, 15)
												}
												{
													renderTableBody(15, dashboardReportData.data15.tableData.rows, dashboardReportData.data15.tableData.headings)
												}
											</>
										}
									</TableBody>
								</Table>
							</TableContainer>

						</div>
					</>
				}
				{
					props.app.title === MR_TITLE && <>
						<MyTask {...props} isOmni={isOmniChannel} />
						<MRStats {...props} isOmni={isOmniChannel} />
					</>
				}
			</div>
			<div className={classes.containerMain}>
				{newData.map((cData, i) => (
					<DashboardMain
						app={props.app}
						account={props.account}
						page={page}
						getTSPData={props.getTSPData}
						key={cData.chartUniqueId}
						chartData={cData}
						brand={props.brand}
						queryString={props.queryString}
						appId={props.appId}
						updatedData={(props.app.title === TSP_TITLE) ? updatedData : getMrActionData()}
					/>
				))}
			</div>
		</div >
	);
};

const mapDispatchToProps = {
	getTopFivePlans,
	getCheckData,
	saveMrData,
	getTSPData,
	getMrData,
	getImarTableDataDashboard
}

const mapStateToProps = (state) => {
	return {
		app: state.appConfig.app,
		brand: state.tprReducer?.selectBrand,
		topFivePlans: state.tprReducer?.topFivePlans,
		updatedData: state.tprReducer?.updatedData,
		getData: state.tprReducer?.getData,
		account: state.mrReducer?.account,
		mrData: state.mrReducer?.data,
		mrReducer: state.mrReducer,
		imarDataDashboard: state.tprReducer?.imarDataDashboard,
		selectScenario: state.tprReducer?.selectScenario
	}
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard)));
