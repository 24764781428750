export const URLs = {
    getAlldownload: '/mrspendplan/account/download-mrdata',
    getAllReportsDownload: '/mrspendplan/account/download-allreportdata',
    getPromoData: '/mrspendplan/planning/promo',
    getNonPromoData: '/mrspendplan/planning/nonPromo',
    getEarningsTFI: '/mrspendplan/planning/earning/tiff',
    deletePromoData: '/mrspendplan/planning/promo',
    deleteNonPromoData: '/mrspendplan/planning/nonPromo',
    deletePlanning: '/mrspendplan/upload/',
    getSpendsPromo: '/mrspendplan/report/spends-promo',
    getSummaryVolumeData:'/mrspendplan/report/mr-summary',
    getSummaryVolumeDataDownload:'/mrspendplan/report/mr-summary-download',
    getEngineEarningsData:'/mrspendplan/report/mr-engine-earnings',
    getEngineEarningsDataDownload:'/mrspendplan/report/mr-engine-earnings-download',
    saveNonPromoForeCast : '/mrspendplan/planning/nonPromo/forecast',
    getEngineSpends : '/mrspendplan/report/mr-engine-spends',
    getEngineSpendsDownload: '/mrspendplan/report/mr-engine-spends-download',
    executiveSummary : '/mrspendplan/report/mr-executive-summary',
    executiveSummaryDownload : '/mrspendplan/report/mr-executive-summary-download',
    getBrandSummary : '/mrspendplan/report/mr-brand-report',
    getBrandSummaryDownload : '/mrspendplan/report/mr-brand-report-download',
    getCockpitView : '/mrspendplan/report/mr-cockpit-view',
    getCockpitViewDownload : '/mrspendplan/report/mr-cockpit-view-download',
    getFCMPlanning: '/mrspendplan/report/fcm-planning',
    download: {
        'promo': '/mrspendplan/planning/download/promo',
        'nonPromo': '/mrspendplan/planning/download/nonPromo',
        'earningsTFI': '/mrspendplan/planning/download/earning/tiff',
        'omniPromoCalender': '/mrspendplan/planning/promo-calendar/download',
        'omniTiff': '/mrspendplan/planning/tiff/download',
        'mediaCalendar': '/mrspendplan/planning/media-calender/download'
    },
    freezeMRPlan: '/mrspendplan/account/mr-freezeplan',
    doneUpdates: '/mrspendplan/upload/done',
    getMrActions: '/mrspendplan/upload/actions/getactions',
    getDashboardCockpitData: '/mrspendplan/report/dashboard',
    cockpitRetailing: '/mrspendplan/report/dashboard/retailing',
    cockpitBrandMix: '/mrspendplan/report/dashboard/brand-mix',
    cockpitVsod: '/mrspendplan/report/dashboard/vsod',
    cockpitSkuAnalysis: '/mrspendplan/report/dashboard/sku',
    cockpitSkuAnalysisBarChart: '/mrspendplan/report/dashboard/skuChart',
    cockpitSkuAnalysisLineChart: '/mrspendplan/report/dashboard/skuChart',
    cockpitSpendReview: '/mrspendplan/report/dashboard/spend',
    templateDownload: '/mrspendplan/template/download',
    getMeterOverhead: '/mrspendplan/report/mr-executive-retailing',
    getDashboardAction: '/mrspendplan/report/dashboard/sku-count',
    customerView: '/mrspendplan/report/customer-view',
    categoryView: '/mrspendplan/report/category-view',
    getMRStats: '/mrspendplan/report/mr-card',
    getOmniAccounts: '/mrspendplan/account/omnichannel',
    getTsSkuFYActualsRetailing: '/mrspendplan/report/tssku-retailing-actual',
    getPromoCalendar: '/mrspendplan/planning/promo-calendar',
    omniTiff: '/mrspendplan/planning/tiff',
    getOmniEngineEarnings: '/mrspendplan/report/omni-engine-earning',
    getTsBrandSummary: '/mrspendplan/report/ts-brand-summary',
    getOmniExecutiveSummary: '/mrspendplan/report/omni-executive-summary',
    getOmniAccountSummary: '/mrspendplan/report/account-summary',
    getOmniAccountSummary2: '/mrspendplan/report/account-summary2',
    templateDownloadOmni: '/mrspendplan/omni/template',
    getBrandformSummary: '/mrspendplan/report/brandform-summary',
    getMediaCalendar: '/mrspendplan/planning/media-calender',
    nonGeneralFormat: '/mrspendplan/report/non-general-format1',
    getCommunicationReport: '/mrspendplan/report/communication',
    getOmniTiffXlList: '/mrspendplan/planning/tiff-xl-list',
    downloadOmniTiffXlList: '/mrspendplan/planning/tiff-xl-list/download'
}


export const sortOrder = {
    fyActuals: {account: 1, brand: 1, brandform: 1, sku: 1,},
    fyActualspff: { brand: 1, brandform: 1, metric: 1,},
    skuMaster: {brand: 1, brandform: 1, sku: 1,},
    ittMaster: {brand: 1, brandform: 1},
    skuMapping: {brand: 1, brandform: 1, sku: 1,},
    promo: {brand: 1, brandform: 1, sku: 1,},
    nonPromo: {brand: 1, brandform: 1, sku: 1},
    spendsPlanning: {brand: 1, type: 1},
    earningsTFI: {format: 1, kbdType: 1, brand: 1, brandform: 1, sku: 1,},
    pyskulevel: {brand: 1, brandform: 1, sku: 1,},
    promoCalendar: {brand: 1, brandform: 1, sku: 1,}
}