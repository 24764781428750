import { Paper, TableContainer, TablePagination, Tooltip } from '@material-ui/core'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import axios from 'axios'
import classNames from 'classnames'
import FileDownload from 'js-file-download'
import * as mathjs from "mathjs"
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { deleteIsSuccess, deleteSelectedFormData } from 'store/actions'
import 'styles/react-datasheet.scss'
import { getMonth } from "utility/utils"
import { addReconReviewBaseline, checkSpBGprocesses } from '../../../../../store/actions'
import { addStyle, capitalize, getFileNameWithTimeStamp, getFilteredChannelList } from '../../../../../utility/utils'
import Loader from '../../../../Loader'
import { UploadLoading } from '../../../../Loading/uploadLoading'
import { checkForBGProcesses, getCurrentFFForPayload, getFiscalYearFormat, isActualColumns } from '../../spendplanUtils'
import FilterWorksheet, { ChannelFilterWithTableValues, pagesWithBrandFormFilter, pagesWithChannelFilter, pagesWithMetricFilter, pagesWithMonthFilter, pagesWithPlanTypeFilter, pagesWithQtrFilter, pagesWithUoMFilter, RoleToAccessSpecificSetupPages } from '../FilterWorksheet/index'
import ConfirmDialog from './../ConfirmDialog'
import SetupConfirmDialog from './../SetupConfirmDialog'
import MathExcelSheet from './ds/MathExcelSheet'
import SalienceModal from './salienceModal'
import { commonBrands, generateSheetKey, getDownloadedFileTitle, headerView1, parsePaste, readOnlyColumn, setSalienceColumnBold, setupPagesWithTotalValues, typeWiseColumns, useStyles } from './sheetTableHelper'
import ValueViewerCheckbox from './ValueViewerCheckbox'
import { brandsFreePages } from '../index'
import IncrementModal from './incrementModal'
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL


let months = ['jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'FY']

const SheetTable = props => {
	const classes = useStyles();
	let { user, type, objectKey, view, tprReducer, deleteSelectedFormData, form, deleteIsSuccess, conditions } = props;
	const { selectBrand, setupUploadLoading, selectScenario } = tprReducer
	const [brand, setBrand] = useState(selectBrand)

	const [fieldData, setFieldData] = useState([]);
	const [cols, setCols] = useState([]);
	const [edited, setEdited] = useState(false)
	const [open, setOpen] = useState(false)
	const [alertMessageText, setAlertMessage] = useState("")
	const [deleteList, setdeleteList] = useState([]);
	const [deleteConfirmState, setDeleteConfirm] = useState({ isOpen: false, deleteMessage: '' })
	const [errorDialogVisible, setErrorDialogVisible] = useState(false)
	const [foreCastModel, setForeCastModel] = useState(null);
	const [incrementModel, setIncrementModel] = useState(null);
	const [updatedDataWithPercent, setUpdatedDataWithPercent] = useState([])

	const [selectedBrandForms, setSelectedBrandForms] = useState([])
	const [showIncrementIcon, setShowIncrementIcon] = useState(false)

	const getUpdatedData = () => {
		if ('rlpsalience' === props.type) {
			return props?.state.data && props?.state?.data.map(item => {
				return {
					...item,
					"bopsalience": {
						"jul": item?.bopsalience['jul'] * 100 || 0,
						"aug": item?.bopsalience['aug'] * 100 || 0,
						"sep": item?.bopsalience['sep'] * 100 || 0,
						"oct": item?.bopsalience['oct'] * 100 || 0,
						"nov": item?.bopsalience['nov'] * 100 || 0,
						"dec": item?.bopsalience['dec'] * 100 || 0,
						"jan": item?.bopsalience['jan'] * 100 || 0,
						"feb": item?.bopsalience['feb'] * 100 || 0,
						"mar": item?.bopsalience['mar'] * 100 || 0,
						"apr": item?.bopsalience['apr'] * 100 || 0,
						"may": item?.bopsalience['may'] * 100 || 0,
						"jun": item?.bopsalience['jun'] * 100 || 0,
						"FY": item?.bopsalience['FY'] * 100 || 0,
					},
				}
			})
		}
		if ('itt' === props.type) {
			return props.state.data.map(item => {
				return {
					...item,
					bop: {
						"Oral B": item?.bop['Oral B'] * 100 || 0,
						"Vicks": item?.bop['Vicks'] * 100 || 0,
						"H&S": item?.bop['H&S'] * 100 || 0,
						"Pantene": item?.bop['Pantene'] * 100 || 0,
						"Tide": item?.bop['Tide'] * 100 || 0,
						"Ariel": item?.bop['Ariel'] * 100 || 0,
						"Ambipur": item?.bop['Ambipur'] * 100 || 0,
						"Pampers": item?.bop['Pampers'] * 100 || 0,
						"Old Spice": item?.bop['Old Spice'] * 100 || 0,
						"Gillette": item?.bop['Gillette'] * 100 || 0,
						"Whisper": item?.bop['Whisper'] * 100 || 0,
						"Olay": item?.bop['Olay'] * 100 || 0,
						"HE": item?.bop['HE'] * 100 || 0,
					}
				}
			})
		}
		if ('cts' === props.type) {
			return props.state.data.map(item => {
				return {
					...item,
					"sellersVisibility": item['sellersVisibility'] * 100,
					"logistics": item['logistics'] * 100,
					"dltAdmin": item['dltAdmin'] * 100,
					"totalSalaries": item['totalSalaries'] * 100,
					"delivery": item['delivery'] * 100,
					"brs": item['brs'] * 100,
					"totalLogistics": item['totalLogistics'] * 100,
					"wholesale": item['wholesale'] * 100,
					"subD": item['subD'] * 100,
					"totalDiscounts": item['totalDiscounts'] * 100,
					"rents": item['rents'] * 100,
					"adminExpenses": item['adminExpenses'] * 100,
					"interest": item['interest'] * 100,
					"depreciation": item['depreciation'] * 100,
					"totalNonOperationalExpenses": item['totalNonOperationalExpenses'] * 100,
					"totalCTS": item['totalCTS'] * 100,
				}
			})
		}
		if ('tdc' === props.type) {
			return props.state.data.map(item => {
				return {
					...item,
					tdcExposureToUsd: item['tdcExposureToUsd'] * 100,
					dsdsGos: item['dsdsGos'] * 100,
					odsGos: item['odsGos'] * 100
				}
			})
		}
		return props.state.data
	}

	useEffect(() => {
		let updatedData = getUpdatedData()
		let fieldData = generateFieldData(updatedData)
		setFieldData(fieldData)
		// setType(props.type)
	}, [])

	useEffect(() => {
		if (selectedBrandForms?.length === 1) {
			setShowIncrementIcon(true)
		} else {
			setShowIncrementIcon(false)
		}
	}, [selectedBrandForms])

	useEffect(() => {
		if (selectBrand && typeof selectBrand === 'object' && selectBrand !== null && Object.keys(selectBrand).length > 0) {
			setBrand(selectBrand);
		}
	}, [selectBrand])

	useEffect(() => {
		setSticky()
	})

	useEffect(() => {
		// console.log("it is check data props.state.data", props.state.data)
		let updatedData = getUpdatedData()
		// console.log("it is check data getUpdatedData", updatedData)
		setUpdatedDataWithPercent(updatedData)
		let fieldData = generateFieldData(updatedData)
		// console.log("it is check data fieldData", fieldData)
		setFieldData(fieldData)
		if (deleteList.length) {
			setdeleteList([]);
		}
		// let isAllSelected = deleteList.find(i=> i === 'All');
		// if(isAllSelected){
		//   // when no of records is changing check if checked length is increased or decresed. Remove all checked if length is increased.
		//   let updatedSelectableRows = fieldData.map(d=> d[0].ref && d[0].key ? (d[0].ref || d[0].key):null).filter(i=>i)
		//   let alreadyCheckedItems = deleteList.filter(i=> i !== 'All');
		//     if(updatedSelectableRows.length > alreadyCheckedItems.length){
		//       setdeleteList(alreadyCheckedItems);
		//     }
		// }
	}, [props.state.data, props.objectKey])

	useEffect(() => {
		props.setEditMode(edited)
	}, [edited])

	useEffect(() => {
		if (form.deleteSuccess) {
			props.fetchData(conditions ?? []);
			deleteIsSuccess();
			setdeleteList([])
		}
	}, [form.deleteSuccess])

	const setSticky = () => {
		try {
			const fixedColumns = document.querySelectorAll('.sticky-column');
			if (fixedColumns.length > 0) {
				let bodyWidth = 0, bodyNextWidth = 0;
				let k = 0;
				fixedColumns.forEach((column, index) => {
					let item = Object.values(column.classList)
						.find(i => i.startsWith('tab-'));
					// rows with different color(salience case when spacial channel name)
					// let isHighlightedCell =  Object.values(column.classList).find(i=> i.startsWith('highlighted'));

					if (item) {
						let [i, j] = item.slice(4)
							.split('-');
						if (i > k) {
							bodyWidth = 0;
							bodyNextWidth = 0;
						}
						k = i;
						let currentElement = document.getElementsByClassName(`tab-${i}-${j} sticky-column`);
						if (j === '0') {
							bodyWidth = -1; // adding + 1 in the end for every element no need to add for this
							bodyNextWidth = currentElement[0].offsetWidth;
						} else {
							bodyWidth = bodyNextWidth - Number(j);
							bodyNextWidth += currentElement[0].offsetWidth;
						}
						currentElement[0].style.position = 'sticky';
						currentElement[0].style.zIndex = i === '0' ? 4 : 3;
						currentElement[0].style.left = `${bodyWidth + 1}px`;
						// style.background not working directly or with setAttribute method !important that's why using below function.

						if (type !== 'rlpsalience' && i !== "0") {
							if (Number(i) % 2) {
								addStyle(currentElement[0], 'background-color', "#F5F5F5", true)
							} else {
								addStyle(currentElement[0], 'background-color', "#fff", true)
							}
						}
					}
				});
			}
		} catch (e) {
			console.log(e);
		}
	};

	const getDeleteList = () => {
		console.log("deleteList ==", deleteList);
		return deleteList
	}

	const checkboxOnChanges = (e, id) => {
		e.preventDefault();
		if (id === 'All') {
			// select all case
			if (e.target.checked) {
				let list = allSelectableRows();
				setdeleteList(['All', ...list])
			} else {
				setdeleteList([])
			}
		} else {
			let dl = getDeleteList().filter(i => i !== 'All');
			let list = [...dl]
			if (e.target.checked) {
				list.push(id)
				if (list.length === allSelectableRows().length) {  // when all rows is selected 1 by 1 show all checked.
					list.push('All')
				}
			} else {
				let ind = list.indexOf(id)
				list.splice(ind, 1)
			}
			console.log("list ===", list);
			setdeleteList(list)
		}
	}

	const allSelectableRows = () => {
		return fieldData.map(d => d[0].ref && d[0].key ? (d[0].ref || d[0].key) : null).filter(i => i);
	}

	const getFormula = (keyVal, extraKey = '') => {
		let formulas = {
			// addition of columns(months- jul - june)
			bopvolumes: `=${keyVal}2+${keyVal}3+${keyVal}4+${keyVal}5+${keyVal}6+${keyVal}7+${keyVal}8+${keyVal}9+${keyVal}10+${keyVal}11+${keyVal}12+${keyVal}13`,
			// avg
			rlppermsu: `=(${keyVal}2+${keyVal}3+${keyVal}4+${keyVal}5+${keyVal}6+${keyVal}7+${keyVal}8+${keyVal}9+${keyVal}10+${keyVal}11+${keyVal}12+${keyVal}13)/12`,

			// addition
			pyrlp: `=${keyVal}3+${keyVal}4+${keyVal}5+${keyVal}6+${keyVal}7+${keyVal}8+${keyVal}9+${keyVal}10+${keyVal}11+${keyVal}12+${keyVal}13+${keyVal}14`,

			pyrlppermsu1: `=${keyVal}2+${keyVal}3+${keyVal}4+${keyVal}5+${keyVal}6+${keyVal}7+${keyVal}8+${keyVal}9+${keyVal}10+${keyVal}11+${keyVal}12+${keyVal}13`,
			pyrlppermsu2: `=${keyVal}16+${keyVal}17+${keyVal}18+${keyVal}19+${keyVal}20+${keyVal}21+${keyVal}22+${keyVal}23+${keyVal}24+${keyVal}25+${keyVal}26+${keyVal}27`,

			rlpytdact1: `=${keyVal}3+${keyVal}4+${keyVal}5+${keyVal}6+${keyVal}7+${keyVal}8+${keyVal}9+${keyVal}10+${keyVal}11+${keyVal}12+${keyVal}13+${keyVal}14`,
			rlpytdact2: `=${keyVal}17+${keyVal}18+${keyVal}19+${keyVal}20+${keyVal}21+${keyVal}22+${keyVal}23+${keyVal}24+${keyVal}25+${keyVal}26+${keyVal}27+${keyVal}28`,
		}

		return formulas[type + extraKey];
	}

	const checkRole = () => {
		return props.member.selectedRoles.find(role => RoleToAccessSpecificSetupPages[role.label])
	}

	const hideColumn = (colKey) => {
		return type === 'itt' && !checkRole() && commonBrands.includes(colKey) && colKey !== brand.BrandName
	}

	const renderHeaderCell = (value, totalCountKey) => {
		if (setupPagesWithTotalValues[type] && props?.state?.totalSum && props.state.totalSum[totalCountKey]) {
			return <div style={{ textAlign: "end" }}><div style={{
				color: '#0070C0', textAlign: "end",
				fontWeight: 'bold', marginBottom: 15
			}}>{props?.state?.totalSum[totalCountKey] ? Number(props?.state?.totalSum[totalCountKey]).toFixed(2).toLocaleString() : null}</div><span>{value}</span></div>
		}
		return <div style={{
			textAlign: ((![...typeWiseColumns.accrualshc, "channel", "subChannel", "planType", "month", "brandform", "metrics", "brand", "sd"].includes(totalCountKey) ||
				months.includes(totalCountKey)) ? 'end' : 'left')
		}}><span>{value}</span></div>
	}

	let headerview = {
		'brandform': 'Brandform', 'channel': 'Channel', 'jul': 'Jul', 'aug': 'Aug',
		'sep': 'Sep', 'oct': 'Oct', 'nov': 'Nov', 'dec': 'Dec', 'jan': 'Jan', 'feb': 'Feb', 'mar': 'Mar',
		'apr': 'Apr', 'may': 'May', 'jun': 'Jun', 'FY': 'FY', 'pyamj': 'PY AMJ',
		hfsretailingcrores: 'HFS Cr.', hfsretailingmsu: 'HFS MSU',
		rlpcrores: 'RLP Cr.', rlpsalience: 'RLP Salience',
		'brand': 'Brand', 'sd': 'SD - By Quarter',
		'JAS': 'JAS', 'OND': 'OND',	'JFM': 'JFM', 'AMJ': 'AMJ', 'H1': 'H1', 'H2': 'H2'
	}

	const onSalienceHeaderClick = (month) => {
		setForeCastModel({
			isOpen: true,
			heading: `Salience Forecast for ${capitalize(month)} ${getFiscalYearFormat(brand?.FinancialYear) || ''}`,
			month: month,
		})
	}
	
	const onIncrementHeaderClick = (month) => {
		setIncrementModel({
			isOpen: true,
			heading: `Salience Increment/Decrement for ${capitalize(month)} ${getFiscalYearFormat(brand?.FinancialYear) || ''}`,
			month: month,
		})
	}

	const onCloseSalienceModal = (v, months) => {
		if (v && months) {
			props.applySalienceForecast(months, selectedBrandForms)
		}
		setForeCastModel({ ...foreCastModel, isOpen: false })
	}

	const onCloseIncrementModal = (updatedData) => {
		if (updatedData) {
			let fldData = generateFieldData(updatedData)
			setFieldData(fldData)
			save(null, fldData)
		}
		setIncrementModel({ ...incrementModel, isOpen: false })
	}


	const renderSalienceHeader = (cellObj, colVal) => {
		if (colVal === 'channel' || colVal === 'brandform') {
			return renderHeaderCell(headerview[colVal] || colVal, colVal)
		} else {
			return (<div style={{ display: "flex", justifyContent: 'flex-end' }}>
				{
					!isActualColumns(cellObj.value.toLowerCase(), brand) &&
					<Tooltip title='Salience Forecast'>
						<TrendingUpIcon style={{ cursor: 'pointer', fontSize: 14 }} onClick={() => onSalienceHeaderClick(cellObj.value.toLowerCase())} />
					</Tooltip>
				}
				<span>{cellObj.value}</span>
				{
					!isActualColumns(cellObj.value.toLowerCase(), brand) &&
					<React.Fragment>
						{
							showIncrementIcon ? <Tooltip title='Salience Forecast'>
								<ArrowUpwardIcon
									style={{ cursor: 'pointer', fontSize: 14 }}
									onClick={() => onIncrementHeaderClick(cellObj.value.toLowerCase())}
								/>
							</Tooltip> :
								<Tooltip title='Select one Brandform'>
									<ArrowUpwardIcon
										style={{ fontSize: 14 }}
									// onClick={() => onIncrementHeaderClick(cellObj.value.toLowerCase())}
									/>
								</Tooltip>
						}
					</React.Fragment>
				}
			</div>)
		}
	}

	const generateFieldData = (data) => {
		try {
			// .map(m => `${objectKey}.${m}`) // TODO: Need to implemment these logic
			let c = ["bopvolumes", "rlppermsu", "pyrlppermsu"].includes(type)
			let cols = c
				? type !== 'rlppermsu' ? ['', 'brandform', ...months] : ['', 'brandform', ...months, 'pyamj']
				: ['', 'brandform', 'channel', ...months]
			if (type === 'pysdrlp') {
				cols = ['', 'brand', 'sd', 'JAS', 'OND', 'JFM', 'AMJ', 'FY', 'H1', 'H2']
			}
			let arr = []
			arr[0] = cols.map((c, i) => ({
				checkbox: c === "" ? !props.view && true : null,
				valueViewer: c === "" ? ValueViewerCheckbox : (type === 'rlpsalience' ? ((cellObj) => renderSalienceHeader(cellObj, c)) : () => renderHeaderCell(headerview[c] || c, c)),
				_id: c === "" ? 'All' : null,
				readOnly: true, idKey: c,
				value: headerview[c] || c, header: true, string: true, key: type + (i + 1),
				className: `tab-0-${i}` + ((c !== "" || i === 0) && readOnlyColumn[c] ? ' sticky-column' : ''),
			}))
			if (type === "pyrlppermsu") {
				let t1 = ['HFS Cr.', 'HFS MSU']
				cols = ['', 'brandform',
					...(months.map(m => {
						headerview[`${objectKey[0]}.${m}`] = `${headerview[objectKey[0]]} ${headerview[m]}`
						return `${objectKey[0]}.${m}`
					})), ' ',
					...(months.map(m => {
						headerview[`${objectKey[1]}.${m}`] = `${headerview[objectKey[1]]} ${headerview[m]}`
						return `${objectKey[1]}.${m}`
					}))]
				arr[0] = cols.map((c, i) => {
					return {
						checkbox: c === "" ? !props.view && true : null, valueViewer: c === "" ? ValueViewerCheckbox : () => renderHeaderCell(headerview[c] || c, c), _id: c === "" ? 'All' : null,
						readOnly: true, value: headerview[c] || c, idKey: c,
						header: true, string: true, key: type + (i + 1),
						className: `tab-0-${i}` + ((c !== "" || i === 0) && readOnlyColumn[c] ? ' sticky-column' : ''),
					}
				})
			} else if (type === "rlpytdact") {
				let t1 = ['RLP Cr.', 'RLP Salience']
				cols = ['', 'brandform', 'channel',
					...(months.map(m => {
						headerview[`${objectKey[0]}.${m}`] = `${headerview[objectKey[0]]} ${headerview[m]}`
						return `${objectKey[0]}.${m}`
					})), ' ',
					...(months.map(m => {
						headerview[`${objectKey[1]}.${m}`] = `${headerview[objectKey[1]]} ${headerview[m]}`
						return `${objectKey[1]}.${m}`
					}))]
				arr[0] = cols.map((c, i) => {
					return {
						checkbox: c === "" ? !props.view && true : null, valueViewer: c === "" ? ValueViewerCheckbox : () => renderHeaderCell(headerview[c] || c, c.includes(objectKey[0] + '.') ? c.replace(objectKey[0] + '.', "") : c), _id: c === "" ? 'All' : null,
						readOnly: true, value: headerview[c] || c, idKey: c,
						header: true, string: true, key: type + (i + 1),
						className: `tab-0-${i}` + ((c !== "" || i === 0) && readOnlyColumn[c] ? ' sticky-column' : ''),
					}
				})
			} else if (type === "corpbud" || type === "accrualshc") {
				cols = [...typeWiseColumns[type], ...commonBrands, 'Total']
				arr[0] = cols.map((c, i) => {
					return {
						checkbox: c === "" ? !props.view && true : null, valueViewer: c === "" ? ValueViewerCheckbox : () => renderHeaderCell(headerView1[c] || c, c), _id: c === "" ? 'All' : null,
						readOnly: true, value: [...commonBrands, 'Total'].includes(c) ? `For BOP ${c}` : headerView1[c], idKey: c,
						header: true, string: true, key: type + (i + 1),
						className: `tab-0-${i}` + ((c !== "" || c !== " " || i === 0) && readOnlyColumn[c] ? ' sticky-column' : ''),
					}
				})
			} else if (type === "mrecomm" || type === 'itt') {
				cols = [...typeWiseColumns[type], ...commonBrands]
				if (type === "mrecomm") {
					cols.push('Total');
				}
				arr[0] = cols.map((c, i) => {
					// if(c === 'HE' && brand.brandName === 'HE' && !hideColumn(c)){
					return {
						hide: hideColumn(c),
						checkbox: c === "" ? !props.view && true : null, valueViewer: c === "" ? ValueViewerCheckbox : () => renderHeaderCell(headerView1[c] || c, c), _id: c === "" ? 'All' : null,
						readOnly: true, value: i < ((type === "mrecomm") ? 7 : 6) ? headerView1[c] : c, idKey: c,
						header: true, string: true, key: type + (i + 1),
						className: `tab-0-${i}` + ((c !== "" || i === 0) && readOnlyColumn[c] ? ' sticky-column' : ''),
					}
					// }
				})
			} else if (type === "f1inputs") {
				cols = [...typeWiseColumns[type]]
				arr[0] = cols.map((c, i) => {
					return {
						align: ["tsBrandCategory", "ff", "metrics", "uoM"].includes(c) ? 'left' : 'end',
						checkbox: c === "" ? !props.view && true : null, valueViewer: c === "" ? ValueViewerCheckbox : null, _id: c === "" ? 'All' : null,
						readOnly: true, value: headerView1[c], idKey: c,
						header: true, string: true, key: type + (i + 1),
						className: `tab-0-${i}` + ((c !== "" || c !== " " || i === 0) && readOnlyColumn[c] ? ' sticky-column' : ''),
					}
				})
			} else if (type === "tdc" || type === "cts") {
				cols = [...typeWiseColumns[type]]
				arr[0] = cols.map((c, i) => {
					return {
						align: ["channels", "brandCategory", "tsBrandforms",].includes(c) ? 'left' : 'end',
						checkbox: c === "" ? !props.view && true : null, valueViewer: c === "" ? ValueViewerCheckbox : null, _id: c === "" ? 'All' : null,
						readOnly: true, value: headerView1[c], idKey: c,
						header: true, string: true, key: type + (i + 1),
						className: `tab-0-${i}` + ((c !== "" || c !== " " || i === 0) && readOnlyColumn[c] ? ' sticky-column' : ''),
					}
				})
			}
			setCols(cols);

			let arrData = data && data.map((d, i) => {
				let a = [{
					readOnly: true, value: i + 1, key: d._id, ref: d._id, string: true, checkbox: !props.view && true, _id: d._id,
					valueViewer: ValueViewerCheckbox, className: `p-0 tab-${i + 1}-0 sticky-column`, checked: false,
				}]
				let empRow = { readOnly: true, value: '', key: 'empty-space', ref: 'empty-space', string: true, width: '30px' }
				if (Array.isArray(objectKey)) {
					if (type === "rlpytdact" || type === "pyrlppermsu") {
						a[(type === "rlpytdact" ? 16 : 15)] = empRow
						let keyVal = generateSheetKey(i);
						objectKey.map((oKey, objectKeyIndex) => {
							Object.keys(d).map(key => {
								if (key === oKey) {
									Object.keys(d[key]).map(k1 => {
										let ind = cols.indexOf(`${oKey}.${k1}`)
										if (ind > -1)
											a[ind] = {
												id: k1,
												idKey: `${key}.${k1}`,
												value: d[key][k1],
												edit: false,
												valueViewer: type === "rlpytdact" ? (cellObj) => <span>{cellObj?.cell?.value ? Number(cellObj?.cell?.value).toFixed(2) : 0}</span> : null,
												key: generateSheetKey(i) + ind,
												expr: k1 === 'FY' ? getFormula(keyVal, (objectKeyIndex + 1) + "") : null,
												readOnly: readOnlyColumn[key] || k1 === 'FY', // || i === 1
												className: "bg-transparent" + " tab-" + (i + 1) + "-" + ind + (key !== "" && readOnlyColumn[key] ? ' sticky-column' : ''),
											}
									})
								} else {
									let ind = cols.indexOf(key)
									if (ind > -1)
										a[ind] = {
											align: 'left',
											id: key,
											idKey: key,
											value: d[key],
											edit: false,
											string: readOnlyColumn[key],
											expr: key === 'FY' ? getFormula(keyVal, objectKeyIndex + 1 + "") : null,
											key: generateSheetKey(i) + ind,
											readOnly: readOnlyColumn[key],
											className: "bg-transparent" + " tab-" + (i + 1) + "-" + ind + (key !== "" && readOnlyColumn[key] ? ' sticky-column' : ''),
										}
								}
							})
						})
					}
				} else if (type === "corpbud" || type === "accrualshc" || type === "mrecomm" || type === 'itt') {
					Object.keys(d).map((key, index) => {
						if (key === 'bop') {
							Object.keys(d[key]).map(k1 => {
								let ind = cols.indexOf(k1)
								if (ind > -1)
									a[ind] = {
										id: k1,
										value: d[key][k1],
										hide: hideColumn(k1),
										edit: false,
										valueViewer: (type === 'corpbud' && d['uoM'] === '% RLP' && d['metrics'] === 'MSASD') ? ((cellObj) => <span>{cellObj?.cell?.value ? Number(cellObj?.cell?.value * 100).toFixed(2) : 0}%</span>) :
											(type === 'itt' ? ((cellObj) => <span>{cellObj?.cell?.value ? Number(cellObj?.cell?.value).toFixed(2) : 0}%</span>) : null),
										key: generateSheetKey(i) + ind,
										className: "bg-transparent" + " tab-" + (i + 1) + "-" + ind + (key !== "" && readOnlyColumn[key] ? ' sticky-column' : ''),
									}
							})
						} else {
							let ind = cols.indexOf(key)
							if (ind > -1)
								a[ind] = {
									align: 'left',
									id: key,
									value: d[key],
									edit: false,
									string: true,
									key: generateSheetKey(i) + ind,
									readOnly: readOnlyColumn[key],
									className: "bg-transparent" + " tab-" + (i + 1) + "-" + ind + (key !== "" && readOnlyColumn[key] ? ' sticky-column' : ''),
								}
						}
					})
				} else if (type === "f1inputs") {
					a[10] = empRow
					Object.keys(d).map((key) => {
						let ind = cols.indexOf(key)
						if (ind > -1)
							a[ind] = {
								align: 'left',
								id: key,
								value: d[key],
								edit: false,
								string: true,
								key: generateSheetKey(i) + ind,
								readOnly: readOnlyColumn[key],
								className: "bg-transparent" + " tab-" + (i + 1) + "-" + ind + (key !== "" && readOnlyColumn[key] ? ' sticky-column' : ''),
							}
						if (key === 'ffForex' || key === 'firmForex') {
							Object.keys(d[key]).map(k1 => {
								let ind = cols.indexOf(key + k1)
								if (ind > -1)
									a[ind] = {
										id: key + k1,
										value: d[key][k1],
										edit: false,
										key: generateSheetKey(i) + ind,
										className: "bg-transparent" + " tab-" + (i + 1) + "-" + ind,
									}
							})
						}
					})
				} else if (type === "tdc") {
					a[9] = empRow;
					Object.keys(d).map((key) => {
						let ind = cols.indexOf(key)
						if (ind > -1)
							a[ind] = {
								align: ['brandCategory', 'tsBrandforms'].includes(key) ? 'left' : 'right',
								id: key,
								value: d[key],
								valueViewer: (key === 'tdcExposureToUsd' || key === 'dsdsGos' || key === 'odsGos') ? ((cellObj) => <span>{cellObj?.cell?.value ? Number(cellObj?.cell?.value).toFixed(2) : 0}%</span>) : null,
								edit: !['brandCategory', 'tsBrandforms'].includes(key),
								string: ['brandCategory', 'tsBrandforms'].includes(key),
								key: generateSheetKey(i) + ind,
								readOnly: readOnlyColumn[key] ? true : false,
								className: "bg-transparent" + " tab-" + (i + 1) + "-" + ind + (key !== "" && readOnlyColumn[key] ? ' sticky-column' : ''),
							}
					})
				} else if (type === "cts") {
					a[18] = empRow;
					Object.keys(d).map((key) => {
						let ind = cols.indexOf(key)
						if (ind > -1)
							a[ind] = {
								align: key === 'channels' ? 'left' : 'right',
								id: key,
								value: d[key],
								edit: key === 'channels',
								valueViewer: key !== 'channels' ? ((cellObj) => <span>{cellObj?.cell?.value ? Number(cellObj?.cell?.value).toFixed(2) : 0}%</span>) : null,
								string: key === 'channels',
								key: generateSheetKey(i) + ind,
								readOnly: readOnlyColumn[key] ? true : false,
								className: "bg-transparent" + " tab-" + (i + 1) + "-" + ind + (key !== "" && readOnlyColumn[key] ? ' sticky-column' : ''),
							}
					})
				} else {
					if (!d) return;
					Object.keys(d)?.map(key => {
						// TODO: Neeed to implemment these logic
						// cols.map((key, ind) => {
						if (key === objectKey) {
							Object.keys(d[key])?.map(k1 => {
								let ind = cols?.indexOf(k1)
								let keyVal = generateSheetKey(i);
								if (ind > -1)
									a[ind] = {
										id: k1,
										value: d[key][k1],
										expr: k1 === 'FY' ? getFormula(keyVal) : null,
										edit: false,
										valueViewer: type === 'rlpsalience' ? ((cellObj) => <span title={`${Number(cellObj?.cell?.value).toFixed(1)}%`}>{cellObj?.cell?.value ? Number(cellObj?.cell?.value).toFixed(1) : 0}%</span>) : null,
										key: generateSheetKey(i) + ind,
										readOnly: readOnlyColumn[key] || k1 === 'FY' || (type === 'rlpsalience' ? isActualColumns(k1, brand) : false),
										className: "bg-transparent" + " tab-" + (i + 1) + "-" + ind + (key !== "" && readOnlyColumn[key] ? ' sticky-column' : '')
											+ setSalienceColumnBold(type, d['channel'], d[key][k1]),

									}
							})
						} else {
							let ind = cols.indexOf(key)
							if (ind > -1)
								a[ind] = {
									align: (key === 'FY' || key === 'pyamj') ? 'center' : 'left',
									id: key,
									value: d[key],
									edit: false,
									string: readOnlyColumn[key],
									key: generateSheetKey(i) + ind,
									readOnly: readOnlyColumn[key],
									className: "bg-transparent" + " tab-" + (i + 1) + "-" + ind + (key !== "" && readOnlyColumn[key] ? ' sticky-column' : '')
										+ setSalienceColumnBold(type, d['channel'], null),
								}
						}
					})
				}
				let missingItems = [], m = 0
				for (var o = 0; o < a.length; o++) {
					let aObj = a[o]
					if (aObj === undefined) {
						m++
						missingItems.push(m)
					}
				}
				if (a.length !== cols.length || (missingItems && missingItems.length > 0)) {
					cols.map((cl, inx) => {
						if (inx != 0) {
							let readOnly = (!c && (inx === 1 || inx === 2)) || (c && inx === 1) ? true : false
							if (!a[inx] || a[inx].id !== cl) {
								a[inx] = {
									id: cl,
									value: d[cl],
									hide: hideColumn(cl),
									edit: false,
									string: readOnly,
									key: generateSheetKey(i) + inx,
								}
							}
						}
					})
				}

				return a
			})
			let emptyRow = cols.map((c, inx) => ({
				readOnly: inx === 0 && true,
				value: inx === 0 ? arrData.length + 1 : '',
				string: readOnlyColumn[c] ? true : false,
				key: generateSheetKey(arrData.length + 1) + inx,
				id: c,
				hide: hideColumn(c),
				align: readOnlyColumn[c] ? 'left' : undefined,
				idKey: c,
				empty: true,
				className: "bg-transparent" + " tab-" + (arrData.length + 1) + "-" + inx + ((c !== "" || inx === 0) && readOnlyColumn[c] ? ' sticky-column' : ''),
			}))
			if (arrData && Array.isArray(arrData)) {
				if (view) {
					arrData = arrData.map(fArr => (fArr.map(fObj => ({ ...fObj, readOnly: view }))))
					return [...arr, ...arrData]
				}
				return [...arr, ...arrData, emptyRow]
			}
			return [emptyRow]
		} catch (e) {
			console.log(e);
		}
	}

	const onCellsChanged = (changes, additions) => {
		try {
			console.log("onCellsChanged = changes", changes, additions);
			if (!edited)
				setEdited(true)
			let arrData = [...fieldData]
			if (changes)
				changes.forEach(({ cell, row, col, value, id }) => {
					if (arrData[row][0].empty)
						arrData[row][0].empty = false
					arrData[row][col] = { ...arrData[row][col], value, id: id || arrData[row][col].id, empty: false };
				});
			if (additions) {
				additions.forEach(({ cell, row, col, value, id }) => {
					if (col < arrData[0].length) {
						// adding extra columns with empty values and freezing some columns
						if (!arrData[row]) {
							arrData[row] = [{
								readOnly: true, value: row, key: '', ref: '', string: true,
								className: `bg-transparent tab-${Number(row)}-0 sticky-column`,
							}];
							let obj = { readOnly: false, value: '', key: '', ref: '', string: false };
							let _arr = [...arrData[0]];
							_arr.shift();
							_arr.forEach((i, count) => {
								let _className = `bg-transparent tab-${Number(row)}-${count + 1}`;
								let isStringField = readOnlyColumn[i.idKey] ? readOnlyColumn[i.idKey] : false;
								if (isStringField && i.idKey !== '') {
									_className += ' sticky-column';
								}
								arrData[row].push({ ...obj, string: isStringField, className: _className });
							});
						}
						arrData[row][col] = { ...arrData[row][col], value, id };
					}
				});
			}
			if (additions && !arrData[arrData.length - 1][0].empty || arrData[arrData.length - 1][0].empty === false) {
				let emptyRow = cols.map((c, inx) => ({
					readOnly: inx === 0 && true,
					value: inx === 0 ? arrData.length : '',
					key: generateSheetKey(arrData.length) + inx,
					empty: true,
					align: readOnlyColumn[c] ? 'left' : undefined,
				}))
				arrData = [...arrData, emptyRow]
			}
			// setFieldData(arrData)
			updateAllValues(arrData);
		} catch (e) {
			console.log(e)
		}
	};

	const updateAllValues = (aoaData) => {
		let updatedAoA = aoaData.map((row) => {
			let valueObj = row.reduce((obj, item) => Object.assign(obj, { [item.key]: Number(item.value) }), {});
			return row.map((cell) => {
				const updatedCell = Object.assign(
					{},
					cell,
					computeExpr(cell.key, cell.expr, valueObj, cell)
				);
				return updatedCell;
			})
		})
		setFieldData(updatedAoA)
	}

	const computeExpr = (key, expr, scope, cell) => {
		let value = null;
		if (expr?.charAt(0) === "=") {
			try {
				value = mathjs.evaluate(expr.substring(1), scope);
			} catch (e) {
				value = null;
			}
			if (value !== null) {
				return { value, expr };
			} else {
				return {};
			}
		} else {
			return cell;
		}
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (e, res) => {
		setOpen(false);
	};

	const save = (setupPageType, input = []) => {
		let months = ['jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'FY']
		let data = []
		let d = [];
		if (input?.length > 0) {
			d = input;
		} else {
			d = fieldData
		}
		d = JSON.parse(JSON.stringify(d))
		// fieldData.map((d, i) => {
		d.map((d, i) => {
			let m = getMonth(brand.CurrentFF, 'monthNumber')
			let obj = {
				// need to load these keys dynamic
				"fiscalYear": brand ? '' + brand.FinancialYear : '',
				"brand": brand ? brand.BrandName : "",
				"currentFF": brand ? (m > 9 ? '' : '0') + m : '',
				"formType": type,
				_id: d[0].ref && d[0].key ? (d[0].ref || d[0].key) : undefined
			}

			if (i !== 0) {
				if (type === "rlpytdact" || type === "pyrlppermsu") {
					d.map((o, j) => {
						if (o.idKey && o.id !== " ") { // discarding empty column which has id " ".
							let keys = o.idKey.split('.')
							if (months.includes(keys[1])) {
								if (!obj[keys[0]])
									obj[keys[0]] = {}
								obj[keys[0]][keys[1]] = o.value
							} else
								obj[o.id] = o.value
						}
					})
					// Object.keys(d).map(k => {
					// 	if (k.includes(objectKey[0])) {
					// 		let a = k.split('.')
					// 		obj[a[0]][a[1]] = d[k].value
					// 	} else if (k.includes(objectKey[1])) {
					// 		let a = k.split('.')
					// 		obj[a[0]][a[1]] = d[k].value
					// 	} else {
					// 		obj[k] = d[k].value
					// 	}
					// })
				}
				else if (type === "accrualshc" || type === 'f1inputs' || type === 'corpbud' || type === 'mrecomm' || type === 'itt') {
					if (['corpbud', 'mrecomm', 'accrualshc', 'itt'].includes(type)) {
						delete obj.brand;
					}
					if (type === 'accrualshc' || type === 'corpbud') {
						let subObjectsFields = [...commonBrands, 'Total']
						obj['bop'] = {}
						d.map(o => {
							if (o.id || o.idKey) {
								if (subObjectsFields.includes(o.id)) {
									obj['bop'][o.id] = o.value
								} else {
									obj[o.id] = o.value
								}
							}
						})
					} else if (type === 'mrecomm') {
						let subObjectsFields = [...commonBrands, 'Total']
						obj['bop'] = {}
						d.map(o => {
							if (o.id || o.idKey) {// discarding empty column which has id " ".
								if (subObjectsFields.includes(o.id)) {
									obj['bop'][o.id] = o.value
								} else {
									obj[o.id] = o.value
								}
							}
						})
					} else if (type === 'itt') {
						obj['bop'] = {}
						d.map(o => {
							if (o.id || o.idKey) {
								if (commonBrands.includes(o.id)) {
									obj['bop'][o.id] = o.value
								} else {
									obj[o.id] = o.value
								}
							}
						})
					} else if (type === 'f1inputs') {
						obj['ffForex'] = {}
						obj['firmForex'] = {}
						d.map(o => {
							if (o.id && o.id !== " ") {
								if (o.id && typeof o.id === 'string' && o.id.startsWith('ffForex')) {
									obj['ffForex'][o.id.slice(7)] = o.value
								} else if (o.id && typeof o.id === 'string' && o.id.startsWith('firmForex')) {
									obj['firmForex'][o.id.slice(9)] = o.value
								} else {
									obj[o.id] = o.value
								}
							}
						})
					}
				}
				else if (type === 'tdc' || type === "cts") {
					d.map(o => {
						if (o.id) {// discarding empty column which has id " ".
							obj[o.id] = o.value
						}
					})
				} else {
					obj[objectKey] = {}
					d.map((o, j) => {
						if (months.includes(o.id)) {
							if (!obj[objectKey])
								obj[objectKey] = {}
							obj[objectKey][o.id] = o.value
						} else if (o.id)
							obj[o.id] = o.value
					})
					// Object.keys(d).map(k => {
					// 	if (k.includes(objectKey)) {
					// 		let a = k.split('.')
					// 		obj[a[0]][a[1]] = d[k].value
					// 	} else {
					// 		obj[k] = d[k].value
					// 	}
					// })
				}
				delete obj.pasted
				if (obj[objectKey] && Object.keys(obj[objectKey]).length > 0) {
					if (Object.keys(obj).includes('brandform') ? obj['brandform'] !== "" : true) {
						if (objectKey === "bopsalience" && type === "rlpsalience") {
							let object = {
								...obj,
								"bopsalience": {
									"jul": obj?.bopsalience['jul'] / 100,
									"aug": obj?.bopsalience['aug'] / 100,
									"sep": obj?.bopsalience['sep'] / 100,
									"oct": obj?.bopsalience['oct'] / 100,
									"nov": obj?.bopsalience['nov'] / 100,
									"dec": obj?.bopsalience['dec'] / 100,
									"jan": obj?.bopsalience['jan'] / 100,
									"feb": obj?.bopsalience['feb'] / 100,
									"mar": obj?.bopsalience['mar'] / 100,
									"apr": obj?.bopsalience['apr'] / 100,
									"may": obj?.bopsalience['may'] / 100,
									"jun": obj?.bopsalience['jun'] / 100,
									"FY": obj?.bopsalience['FY'] / 100,
								},
							}
							data.push(object)
						} else {
							data.push(obj)
						}
					}
				}

				if ((type === "rlpytdact" || type === "pyrlppermsu") && obj['brandform'] !== "") {
					if (obj[objectKey[0]] && Object.keys(obj[objectKey[0]]).length > 0
						|| obj[objectKey[1]] && Object.keys(obj[objectKey[1]]).length > 0) {
						data.push(obj)
					}
				} else if (type === 'corpbud') {
					if (obj["channel"] === "All Channels" && obj["metrics"] && obj["uoM"]) {
						data.push(obj)
					} else if (obj["channel"] && obj["qtr"] && obj["month"] && obj["metrics"] && obj["uoM"]) {
						data.push(obj)
					}
				} else if (type === 'accrualshc') {
					if (obj['spendPool'] && obj['spendBucket'] && obj['qtr'] && obj['uoM']) {
						data.push(obj)
					}
				} else if (type === 'f1inputs') {
					if (obj['tsBrandCategory'] && obj['ff'] && obj['metrics'] && obj['uoM']) {
						data.push(obj)
					}
				} else if (type === 'mrecomm') {
					if (obj["qtr"] && obj["month"] && obj["channel"] && obj["subChannel"] && obj["planType"] && ["uoM"]) {
						data.push(obj)
					}
				} else if (type === 'itt') {
					if (obj["qtr"] && obj["month"] && obj["channel"] && obj["subChannel"] && obj["planType"]) {
						let object = {
							...obj,
							bop: {
								"Oral B": obj?.bop['Oral B'] / 100 || 0,
								"Vicks": obj?.bop['Vicks'] / 100 || 0,
								"H&S": obj?.bop['H&S'] / 100 || 0,
								"Pantene": obj?.bop['Pantene'] / 100 || 0,
								"Tide": obj?.bop['Tide'] / 100 || 0,
								"Ariel": obj?.bop['Ariel'] / 100 || 0,
								"Ambipur": obj?.bop['Ambipur'] / 100 || 0,
								"Pampers": obj?.bop['Pampers'] / 100 || 0,
								"Old Spice": obj?.bop['Old Spice'] / 100 || 0,
								"Gillette": obj?.bop['Gillette'] / 100 || 0,
								"Whisper": obj?.bop['Whisper'] / 100 || 0,
								"Olay": obj?.bop['Olay'] / 100 || 0,
								"HE": obj?.bop['HE'] / 100 || 0,
							}
						}
						data.push(object)
					}
				} else if (type === 'tdc') {
					if (obj["brandCategory"] && obj["tsBrandforms"]) {
						let object = {
							...obj,
							tdcExposureToUsd: obj['tdcExposureToUsd'] / 100,
							dsdsGos: obj['dsdsGos'] / 100,
							odsGos: obj['odsGos'] / 100
						}
						data.push(object)
					}
				} else if (type === 'cts') {
					if (obj["channels"]) {
						let object = {
							...obj,
							"sellersVisibility": obj['sellersVisibility'] / 100,
							"logistics": obj['logistics'] / 100,
							"dltAdmin": obj['dltAdmin'] / 100,
							"totalSalaries": obj['totalSalaries'] / 100,
							"delivery": obj['delivery'] / 100,
							"brs": obj['brs'] / 100,
							"totalLogistics": obj['totalLogistics'] / 100,
							"wholesale": obj['wholesale'] / 100,
							"subD": obj['subD'] / 100,
							"totalDiscounts": obj['totalDiscounts'] / 100,
							"rents": obj['rents'] / 100,
							"adminExpenses": obj['adminExpenses'] / 100,
							"interest": obj['interest'] / 100,
							"depreciation": obj['depreciation'] / 100,
							"totalNonOperationalExpenses": obj['totalNonOperationalExpenses'] / 100,
							"totalCTS": obj['totalCTS'] / 100,
						}
						data.push(object)
					}
				}
			}
			return obj
		})
		updateData(data, setupPageType)
	}

	const updateData = (data, setupPageType) => {
		let url = `${API_BASE_URL}/setupdata/${props.page?.pageLevelDataModel}`
		axios({
			url: url,
			method: 'PUT',
			data: data,
			headers: {
				appId: props.match.params.appid,
				'Content-Type': 'application/json',
			}
		}).then((response) => {
			let message = "Data saved successfully"
			if (response.data && response.data.message) {
				message = response.data.message
			}
			setEdited(false)
			setAlertMessage(message)
			handleClickOpen()
			props.fetchData()
			if (setupPageType) {
				let LabelByType = {
					bopvolumes: 'Volume Updated',
					rlppermsu: 'RLP per MSU Updated',
				}
				let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
				m = m.length === 1 ? '0' + m : m;
				let payload = {
					currentFF: m,
					fiscalYear: brand ? `${brand.FinancialYear}` : '',
					label: LabelByType[setupPageType],
					brand: brand.BrandName,
				}
				props.addReconReviewBaseline({ data: payload, appId: props.match.params.appid });
			}
			let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
			m = m.length === 1 ? '0' + m : m;
			let obj = {
				currentFF: m,
				fiscalYear: brand ? `${brand.FinancialYear}` : '',
				brand: encodeURIComponent(brand.BrandName),
				appId: props.match.params.appid,
			}
			checkForBGProcesses(props?.type) && props.checkSpBGprocesses(obj)
		}).catch((error) => {
			console.log(error.response.data);
			let msg = error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something went wrong';
			let d = {
				title: 'Error',
				p1: msg,
			}
			setAlertMessage(d)
			setErrorDialogVisible(true)
		})
	}

	const download = (allFilters, brand = [], channel = [], brandForm = [], subChannel = [], qtr = [], month = [], uom = [],
		spendBucket = [], spendPool = [], ff = [], metric = [], planType = [], tsBrandforms = [], brandCategory = []) => {

		const isChannelFilterRequired = () => {
			let allChannels = ChannelFilterWithTableValues.includes(props.type) ? allFilters['channel'] : getFilteredChannelList(tprReducer?.filterData?.channels).map(i => i.channel)
			return allChannels.length !== channel?.length;
		}

		if (brand && type) {
			let uri = API_BASE_URL + `/setupdata/download/${props.page?.pageLevelDataModel}?`;
			if (!['corpbud', 'mrecomm', 'accrualshc', 'itt'].includes(props.type)) {
				uri = `${uri}brand=${encodeURIComponent(brand.BrandName)}`;
			}
			if (pagesWithBrandFormFilter.includes(props.type) && brandForm?.length && (brandForm?.length !== tprReducer.filterData?.brands?.length)) {
				uri = `${uri}&brandform=${encodeURIComponent(JSON.stringify(brandForm))}`;
			}
			if ([...pagesWithChannelFilter, 'corpbud'].includes(props.type) && channel?.length && isChannelFilterRequired()) {
				uri = `${uri}&${props.type === 'cts' ? 'channels' : 'channel'}=${encodeURIComponent(JSON.stringify(channel))}`;
			}
			if ((props.type === 'itt' || props.type === 'mrecomm') && subChannel?.length) {
				uri = `${uri}&subChannel=${encodeURIComponent(JSON.stringify(subChannel))}`;
			}
			if (pagesWithQtrFilter.includes(props.type) && qtr?.length && (5 !== qtr?.length)) {
				uri = `${uri}&qtr=${encodeURIComponent(JSON.stringify(qtr))}`;
			}
			if (pagesWithMonthFilter.includes(props.type) && month?.length && (13 !== month?.length)) {
				uri = `${uri}&month=${encodeURIComponent(JSON.stringify(month))}`;
			}
			if (pagesWithUoMFilter.includes(props.type) && uom?.length && (allFilters['uoM'].length !== uom?.length)) {
				uri = `${uri}&uoM=${encodeURIComponent(JSON.stringify(uom))}`;
			}
			if (props.type === 'accrualshc' && spendBucket?.length && (allFilters['spendBucket'].length !== spendBucket?.length)) {
				uri = `${uri}&spendBucket=${encodeURIComponent(JSON.stringify(spendBucket))}`;
			}
			if (props.type === 'accrualshc' && spendPool?.length && (allFilters['spendPool'].length !== spendPool?.length)) {
				uri = `${uri}&spendPool=${encodeURIComponent(JSON.stringify(spendPool))}`;
			}
			if (props.type === 'f1inputs' && ff?.length && (allFilters['ff'].length !== ff?.length)) {
				uri = `${uri}&ff=${encodeURIComponent(JSON.stringify(ff))}`;
			}
			if (pagesWithMetricFilter.includes(props.type) && metric?.length && (allFilters['metrics'].length !== metric?.length)) {
				uri = `${uri}&metrics=${encodeURIComponent(JSON.stringify(metric))}`;
			}
			if (pagesWithPlanTypeFilter.includes(props.type) && planType?.length && (allFilters['planType'].length !== planType?.length)) {
				uri = `${uri}&planType=${encodeURIComponent(JSON.stringify(planType))}`;
			}
			if (props.type === 'tdc' && tsBrandforms?.length && (allFilters['tsBrandforms']?.length !== tsBrandforms?.length)) {
				uri = `${uri}&tsBrandforms=${encodeURIComponent(JSON.stringify(tsBrandforms))}`;
			}
			if (props.type === 'tdc' && brandCategory?.length && (allFilters['brandCategory'].length !== brandCategory?.length)) {
				uri = `${uri}&brandCategory=${encodeURIComponent(JSON.stringify(brandCategory))}`;
			}
			if (getMonth(brand?.CurrentFF, 'monthNumber')) {
				let m = getMonth(brand?.CurrentFF, 'monthNumber');
				uri = `${uri}&currentFF=${encodeURIComponent(getCurrentFFForPayload(m))}`;
			}
			if (brand?.FinancialYear) {
				uri = `${uri}&fiscalYear=${encodeURIComponent(brand?.FinancialYear)}`;
			}
			uri = `${uri}&scenario=${encodeURIComponent(selectScenario)}`;
			uri = `${uri}&formType=${encodeURIComponent(type)}`;
			let fyFF = getFiscalYearFormat(brand?.FinancialYear) + '-' + moment().month(brand.CurrentFF).format('MMM');
			axios({
				url: uri,
				method: 'GET',
				responseType: 'blob',
				headers: {
					'Content-Type': "application/json",
					appId: props.match.params.appid
				},
			}).then((response) => {
				let fileNamePrefix = getDownloadedFileTitle[props.type] + '-' + brand.BrandName + '-' + fyFF;
				FileDownload(response.data, getFileNameWithTimeStamp(fileNamePrefix));
			}).catch((error) => {
				console.log(error);
			})
		}
	}

	const deleteHandler = () => {
		if (deleteList?.length) {
			setDeleteConfirm({ ...deleteConfirmState, isOpen: true, deleteMessage: 'Are you sure you want to delete the selected row(s)?' })
		} else {
			setDeleteConfirm({ ...deleteConfirmState, isOpen: true, deleteMessage: 'Are you sure you want to delete the all row(s)?' })
		}
	}


	const onDeleteResponse = (isDelete) => {
		if (isDelete) {
			let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
			m = m.length === 1 ? '0' + m : m;
			let commonObj = {
				currentFF: m,
				fiscalYear: brand ? `${brand.FinancialYear}` : '',
			}
			if(selectScenario) commonObj['scenario'] = `${selectScenario}`;
			if (!brandsFreePages.includes(props.type)) {
				commonObj['brand'] = brand.BrandName
			}
			deleteSelectedFormData({ dataModalId: props.page.pageLevelDataModel, appId: props.match.params.appid, type: props.type, ids: deleteList.filter(i => i !== 'All'), commonObj })
		}
		setDeleteConfirm({ ...deleteConfirmState, isOpen: false })
	}

	return (
		<React.Fragment>
			{setupUploadLoading && <UploadLoading />}
			<div className={classNames(`${classes.headerMainCon} mr-20 mb3`)}>
				<Paper elevation={3} className={classes.headerPaperContainer}>
					<FilterWorksheet
						{...props}
						save={save}
						edited={edited}
						download={download}
						deleteHandler={deleteHandler}
						handleFileSave={props.handleFileSave}
						handlePasteClick={props.handlePasteClick}
						disableDelete={props.state?.data?.length === 0}
						fetchData={() => props.fetchData(conditions ?? [])}
						setSelectedBrandForms={setSelectedBrandForms}
					/>
				</Paper>
			</div>
			{
				props?.state?.loading ? <div className={classes.loading}><Loader /></div> :
					<div className="react-datasheet">
						<TableContainer className={classes.tableContainer} component={Paper}>
							<MathExcelSheet parsePaste={(pasted) => parsePaste(pasted)} checkedRows={deleteList} data={fieldData} type={props?.type} onCellsChanged={onCellsChanged}
								valueRenderer={cell => cell?.value}
								dataRenderer={cell => cell?.expr}
								checkboxOnChanges={checkboxOnChanges} />
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={props?.page?.name === "Channel Retailing Salience" ? [38, 76, 114] : [10, 25, 50, 100]}
							component="div"
							count={props.state.count ? props.state.count : 0}
							className="custom-table-pagination"
							rowsPerPage={props?.pagination?.rowsPerPage ? props.pagination?.rowsPerPage : 50}
							page={props?.pagination?.page ? props.pagination?.page : 0}
							backIconButtonProps={{
								'aria-label': 'Previous Page'
							}}
							nextIconButtonProps={{
								'aria-label': 'Next Page',
								'margin-top': '-18px'
							}}
							onChangePage={props?.handleChangePage}
							onChangeRowsPerPage={props?.handleChangeRowsPerPage}
						/>
					</div>
			}
			<ConfirmDialog open={errorDialogVisible} handleClose={() => setErrorDialogVisible(false)} alertMessageText={alertMessageText} classes={classes} />
			<ConfirmDialog open={deleteConfirmState?.isOpen} handleClose={onDeleteResponse}
				alertMessageText={deleteConfirmState?.deleteMessage} classes={classes} confirm />
			<SetupConfirmDialog open={open} handleClose={handleClose} alertMessageText={alertMessageText} type={'setup'}
				classes={classes} />
			{foreCastModel?.isOpen && <SalienceModal open={true} data={foreCastModel}
				brand={brand} handleClose={onCloseSalienceModal} />}
			{incrementModel?.isOpen && <IncrementModal open={true} data={incrementModel}
				brand={brand} mainData={updatedDataWithPercent} handleClose={onCloseIncrementModal} />}
		</React.Fragment >
	)
}

const mapStateToProps = state => {
	return {
		user: state.user,
		tprReducer: state.tprReducer,
		form: state.form,
		roles: state.appConfig.roles,
		member: state.appConfig.member,
	}
}

const mapDispatchToProps = {
	deleteSelectedFormData,
	deleteIsSuccess,
	addReconReviewBaseline,
	checkSpBGprocesses,
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(SheetTable)))