import { Button, CircularProgress, Paper } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import classNames from 'classnames';
import FileDownload from 'js-file-download';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { withRouter } from 'react-router-dom';
import { getMonth } from '../../../../../utility/utils';
import { useStyles } from '../../../spendplan/PlanningPages/PlanHeader';
import ConfirmDialog from '../../../spendplan/SpendPlan/ConfirmDialog';
import { getFiscalYearFormat } from '../../../spendplan/spendplanUtils';
import { URLs } from '../../ApiConstants';
import { HeaderFilters } from './headerFilters';
import MetricOverHeadTable from './metricOverHeadTable';
import OmniMetricOverHeadTable from './omniMetricOverHeadTable'
import UploadMRPlan from './UploadPlan';

export const ActionType = {
  Save: 'Save',
  Delete: 'Delete',
  Duplicate: 'Duplicate',
  Download: 'Download',
  NewPlan: 'NewPlan',
  Upload: 'Upload',
  Edit: 'Edit',
  DoneUpdates: 'DoneUpdates',
  Clear: 'clearFilters'
};

let mrPlanLinks = [
  {
    'Title': 'Promo Planning',
    'url': 'PromoPlanning',
  },
  {
    'Title': 'Non Promo Planning',
    'url': 'NonPromoPlanning',
  },
  {
    'Title': 'Spends Planning',
    'url': 'SpendsPlanning',
  },
  {
    'Title': 'Earnings Planning',
    'url': 'EarningsPlanning',
  },
];

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const MRPlanHeader = props => {
  const classes = useStyles();
  const [url, setUrl] = useState('');
  const [alertMessageText, setAlertMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState(props.page.name);
  const { saveButtonState, rowSelected, filters, xlList = [] } = props;

  const [successState, setSuccess] = useState({
    isOpen: false,
    message: ''
  })
  const [downloadLoading, setDownloadLoading] = useState(false);

  const handleChange = (name, url) => {
    setUrl(url);
    if (saveButtonState) {
      setOpen(true)
      setAlertMessage({
        title: 'Confirmation',
        p1: `You have unsaved changes in ${link}.`,
        p2: 'Are you sure you want to discard these changes?',
      });
    } else {
      setLink(name)
      if (props.appName === "omnichannel") {
        props.linkChange(true);
      }
      props.history.push(url);
    }
  };


  const handleClose = (e, res) => {
    setOpen(false);
    if (e) {
      props.history.push(url);
    }
  };

  const fetchMetricData = () => {
    if (props.appName === "omnichannel") {
      mrPlanLinks = [
        {
          'Title': 'Promo Calendar',
          'url': 'PromoCalendar',
        },
        {
          'Title': 'Spends Planning',
          'url': 'OmniSpendsPlanning'
        },
        {
          'Title': 'TFI Earnings',
          'url': 'OmniTiff'
        },
        {
          'Title': 'Media Calendar',
          'url': 'MediaCalendar'
        }
      ]
    } else  {
      mrPlanLinks = [
        {
          'Title': 'Promo Planning',
          'url': 'PromoPlanning',
        },
        {
          'Title': 'Non Promo Planning',
          'url': 'NonPromoPlanning',
        },
        {
          'Title': 'Spends Planning',
          'url': 'SpendsPlanning',
        },
        {
          'Title': 'Earnings Planning',
          'url': 'EarningsPlanning',
        },
      ]
      if (props.account || props.modalData) {
        let params = {
          fiscalYear: props.modalData?.year || props.account?.FinancialYear,
          account: encodeURIComponent(props.modalData?.account || props.account?.AccountName),
        }
        let m = '' + getMonth(props.account?.CurrentFF, 'monthNumber')
        params.currentFF = props.modalData?.month || (m.length === 1 ? '0' + m : m);
        let url = URLs.getMeterOverhead + '?' + new URLSearchParams(params).toString();
  
        let payload = {
          url: url,
          appId: props.match.params.appid,
          dataUniqueId: 'GET_meterOverhead',
        }
        props.getMrData(payload)
      }
    }
  }

  let saveSuccess = props.data ? props.data['POST_' + props.type] : null;
  let forecastSuccess = props.data ? props.data['PUT_FORECAST_' + props.type] : null;

  useEffect(() => {
    if (saveSuccess || forecastSuccess) {
      fetchMetricData()
    }
  }, [saveSuccess, forecastSuccess])

  useEffect(() => {
    fetchMetricData()
  }, [props.account])

  const renderSaveButton = () => {
    return (
      <Button className={classNames('btn btn-primary  tpr-button m-right-5')}
        color="primary" variant="contained" onClick={() => props.onAction(ActionType.Save)}
        size="medium"
        disabled={props.saveBtnDisabled}
        startIcon={<SaveIcon />}
      >
        <span>Save</span>
      </Button>
    );
  };

  const renderHeaderButton = (label, onBtnPress) => {
    return (
      <Button
        className={classNames(`btn btn-primary tpr-button m-right-10 `)}
        color="primary" variant="contained" onClick={() => onBtnPress()}
        // disabled={rowSelected === '' && label === 'Duplicate Plan'}
        style={{ color: '#fff' }}
      >
        <span
          className={`${rowSelected === '' && label === 'Duplicate Plan' ? classes.disa6bledBtn : ''}`}>{label}</span>
      </Button>
    );
  };

  const onSuccessResponse = (resp) => {
    setSuccess({ isOpen: false, successMessage: '' })
  }

  const downloadTemplate = () => {
    let uri = API_BASE_URL + (props.appName === "omnichannel" ? URLs.templateDownloadOmni : URLs.templateDownload);
    setDownloadLoading(true)
    axios({
      url: uri,
      method: 'GET',
      responseType: 'blob',
      headers: {
        appId: props.match.params.appid,
      },
    })
      .then((response) => {
        FileDownload(response.data, 'MRSpend_Upload_Templates.zip');
        setDownloadLoading(false)
      })
      .catch((error) => {
        console.log(error);
        setDownloadLoading(false)
      });
  }

  return (<Paper style={{ marginTop: -8, paddingTop: 8, }}>
    <div className={classes.headingBox}>
      <div className="tab-container m-bottom-10" style={{ height: 40, }}>
        {!props.modalData && mrPlanLinks?.map((gLink, i) => {
          const url = `/apps/${props.match.params.appid}/${gLink.url}`;
          return <div key={i}
            className={`setup-data-headings p-bottom-10 p-right-20 p-left-20 ${gLink.Title === (props.page.name === 'Omni Tiff' ? 'TFI Earnings' : (props.page.name === 'Omni Spends Planning' ? 'Spends Planning': props.page.name)) ? `active-global-link` : ``}`}
            onClick={() => {
              handleChange(gLink.Title, url);
            }}>
            <span className={gLink.Title === props.page.name ? 'select-global-link' : 'normal-link'}>{gLink.Title}</span>
          </div>;
        })}
      </div>
     
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {props.appName !== "omnichannel" ? <MetricOverHeadTable {...props} /> : props.type === 'omniPromoCalender' ? <OmniMetricOverHeadTable {...props} /> : '' }
        
        {!props.modalData ? (
          <Button
            title={"Template Files for Upload"}
            onClick={downloadTemplate}
            startIcon={<GetAppIcon />}
            variant="text"
            size="small"
            disabled={downloadLoading}
            style={{ height: 30, color: 'rgb(25, 118, 210)' }}
            
          >
            {
              downloadLoading ? <CircularProgress className={classes.circularBar} size={24} /> : 'Template'
            }      
          </Button>) : null}
        {
          props.appName === "omnichannel" && props.type === 'omniTiff' && 
          <div class="flex-container">
            <div><span style={{width: '10px', backgroundColor: '#f65a22', border: 'solid 1px #f65a22', marginRight: '5px', color: '#f65a22'}}>aa</span>Consumption</div>
            <div><span style={{width: '10px', backgroundColor: '#798eec', border: 'solid 1px #798eec', marginRight: '5px', color: '#798eec'}}>aa</span>Core</div>
            <div><span style={{width: '10px', backgroundColor: '#065606', border: 'solid 1px #065606', marginRight: '5px', color: '#065606'}}>aa</span>Growth</div>
            <div><span style={{width: '10px', backgroundColor: '#ecc779', border: 'solid 1px #ecc779', marginRight: '5px', color: '#ecc779'}}>aa</span>Small</div>
          </div>
        }
      </div>
    </div>

    <div className={classNames(classes.headerMainCon, "filter-container custom-flex-start")}>
      <div className={classes.leftHeaderCon}>
        <HeaderFilters filters={filters} filterValues={props?.filterValues} filterData={props?.filterData} onFilterChange={props?.onFilterChange} appName={props?.appName}/>
      </div>
      
      {<div className={classes.rightHeaderCon}>
        {
       
       props.showClearFilter &&<Button
          className={classNames(`btn btn-primary tpr-button m-right-10 `)}
          color="primary" variant="contained" onClick={() => props.onAction(ActionType.Clear)}
          style={{ color: '#fff', minWidth:'140px'}}
        >
          <span>Clear Filters</span>
        </Button>
        }
        {(saveButtonState && props.access !== 'Read') && (
          <div className={classes.btnGroup}>
            {renderSaveButton()}
          </div>
        )}
        <div className={classes.btnGroup}>
          {
            (!['promo', 'nonPromo'].includes(props.type)) && Boolean(props.showEditBtn) && props.access !== 'Read' &&
            <Button
              className={classNames(`btn btn-primary tpr-button m-right-10 `)}
              color="primary" variant="contained" onClick={() => props.onAction(ActionType.Edit)}
              style={{ color: '#fff' }}
            >
              <span>Edit</span>
            </Button>
          }
          {!props.modalData && props.type === 'omniTiff' && (
  <>
    {xlList?.map((el, i) => (
      <Button
        key={el.name}
        variant="contained"
        color="primary"
        className={classNames(classes.downloadButton, 'm-right-10')}
        size="medium"
        onClick={() => props.handleXlDownload(`${el.path}`, `${el.name}`)}
        startIcon={<GetAppIcon />}
      >
        {/* {`${el?.name?.substr(0, 4)}....xlsx`} */}
        {`Latest ${i + 1}`}
      </Button>
    ))}
  </>
)}
    {
  props.modalData || props.access === 'Read' ? null : (
    <Button
      variant="contained"
      color="primary"
      className={classNames(classes.downloadButton, 'm-right-10')}
      size="medium"
      onClick={() => props.onAction(ActionType.Delete)}
      startIcon={<DeleteIcon />}
    >
      {'Delete'}
    </Button>
  )
}
          

          <Button
            variant="contained"
            color="primary"
            className={'m-right-10'}
            size="medium"
            onClick={() => props.onAction(ActionType.Download)}
            startIcon={<GetAppIcon />}
          > {'Download'} </Button>
          {props.access !== 'Read' && <UploadMRPlan
            {...props}
            uploadType={props.type}
            handleSave={(files) => {
              props.onAction(ActionType.Upload)
            }}
            acceptedFiles={[
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/vnd.ms-excel',
              'application/x-csv',
              'application/csv',
              'text/csv',
              'text/x-csv',
              'text/tab-separated-values',
              'text/comma-separated-values',
              'text/x-comma-separated-values',
            ]} />}
          {props.appName !== "omnichannel" && <Button
            onClick={() => props.onAction(ActionType.DoneUpdates)}
            className={classNames('btn btn-primary btn-color m-right-10', classes.updateBtnStyle)}>
            {`Done Updates  ${getMonth(props?.account?.CurrentFF, 'month')} ${getFiscalYearFormat(props?.account?.FinancialYear)}`}
          </Button>}
        </div>
      </div>}
      <ConfirmDialog open={open} handleClose={handleClose} alertMessageText={alertMessageText}
        classes={classes} confirm={true} />
      <ConfirmDialog open={successState.isOpen} handleClose={onSuccessResponse}
        alertMessageText={successState.confirmMessage} classes={classes} />
    </div>
  </Paper>)

};

export default hot(withRouter(MRPlanHeader));