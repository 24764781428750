import { Divider, Grid, Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TablePagination } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import classNames from 'classnames'
import FileDownload from 'js-file-download'
import * as mathjs from "mathjs"
import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'styles/react-datasheet.scss'
import { addReconReviewBaseline, deleteMRData, deleteSelectedFormData, removeMRSuccessMessage } from '../../../../store/actions'
import { getFileNameWithTimeStamp, getMonth, numberToINR  } from '../../../../utility/utils'
import Loader from '../../../Loader'
import { UploadLoading } from '../../../Loading/uploadLoading'
import ConfirmDialog from '../../spendplan/SpendPlan/ConfirmDialog'
import { convertToNumber } from '../../spendplan/PlanningPages/planningHelperFunctions'
import MathExcelSheet from '../../spendplan/SpendPlan/Worksheet/ds/MathExcelSheet'
import { generateSheetKey, parsePaste, readOnlyColumn } from '../../spendplan/SpendPlan/Worksheet/sheetTableHelper'
import ValueViewerCheckbox from '../../spendplan/SpendPlan/Worksheet/ValueViewerCheckbox'
import { getFiscalYearFormat, MonthsNumberMapping } from '../../spendplan/spendplanUtils'
import { URLs } from '../../mrplan/ApiConstants';
import MRPlanUploadConfirmDialog from '../../mrplan/planingPages/Headers/planUploadConfirmDialog'
import { actualForcastMonths, columnTitles, fyActualHeadingKeys, isMonthForecast, ittMasterObjectKeys, KeyTypes, lastFFMonths, months, ObjectKeyTypes, skuMappingKeys, OmniChannelKeyTypes, claimHeadingKeys, claimHeadingTotalKeys, retailOfftakeQuaterSubKeys, retailingValueQtr, offTakeValueQtr } from '../../mrplan/Sheettable/data'
import SheetHeader from '../../mrplan/Sheettable/header';
import OmniSheetHeader from '../../mrplan/Sheettable/omniSheetTableHeader'
import { save, setSticky } from '../../mrplan/Sheettable/mrSheettableHelper'
import ValueViewerSelect from '../../mrplan/Sheettable/valueViewerSelect'
import Climsdata from './promovalue.json'
import { renderNoDataText, StyledTableCell, StyledTableRow } from "../../mrplan/ReportPages/Summary/summarySheetTable";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const useStyles = makeStyles(theme => ({
    tableContainer: {
        maxWidth: "95vw",
        // margin: "40px auto",
        overflowY: "auto",
        height: 'calc(100vh - 335px) !important',
        borderRadius: "8px",
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: '0.8em',
            height: '0.8em'
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#cdcdcd',
            outline: '1px solid slategrey',
        },
    },
    tableTotalContainer: {
        maxWidth: "95vw",
        marginBottom: "20px",
        overflowY: "auto",
        borderRadius: "8px",
        scrollbarWidth: 'thin',
        color: '#3662cf',
        border: '1px solid',
        height: '222px',
        '&::-webkit-scrollbar': {
            width: '0.8em',
            height: '0.8em'
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent',
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#cdcdcd',
            outline: '1px solid slategrey',
        },
    },
    table: {
        minWidth: 700,
    },
    tableHead: {
        "& th": {
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            fontSize: '16px'
        },
        "& span": {
            fontWeight: "bold !important"
        },
    },
    tableCell: {
        padding: '5px !important'
    },
    tableBody: {
        "& td": {
            borderRight: "1px solid rgba(224, 224, 224, 1)",
            cursor: "pointer"
        },
        "& p": {
            display: "flex"
        }
    },
    tableInput: {
        border: "0",
        background: "transparent",
        maxWidth: "55px",
        appearance: "none",
    },
    quarterHead: {
        paddingRight: "5px",
        borderRight: "1px solid #7b7272",
        marginRight: "5px",
    },
    quarterBody: {
        paddingRight: "5px",
        marginRight: "5px",
    },
    greenFont: {
        "& span": {
            color: "green"
        }
    },
    confirmationDialoge: {
        '& .MuiDialog-paperWidthSm': {
            height: '320px',
            width: '600px'
        }
    },
    para: {
        fontSize: '28px !important',
        fontWeight: 600,
        color: '#000'
    },
    headerMainCon: {
        maxWidth: '95vw',
        // overflowX: 'scroll'
    },
    headerPaperContainer: {
        minWidth: '1066px'
    },
    setupConfirmationDialog: {
        '& .MuiDialog-paperWidthSm': {
            height: '320px',
            width: '600px'
        }
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 400px)',
    },
    totalValue: {
        color: '#0070C0',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 12,
    },
    redColor: {
        color: 'red !important'
    },
    defaultCellClass: {
        backgroundColor: 'transparent !important',
        font: 'inherit !important',
        color: 'inherit !important'
    },
}))

const OmniSheetTable = props => {
    const classes = useStyles();
    let { user, type, objectKey, view, tprReducer, deleteSelectedFormData, form, conditions } = props;
    const { setupUploadLoading } = tprReducer

    const [fieldData, setFieldData] = useState([]);
    const [cols, setCols] = useState([]);
    const [claimCols, setClaimCols] = useState([]);
    const [edited, setEdited] = useState(false)
    const [open, setOpen] = useState(false)
    const [alertMessageText, setAlertMessage] = useState("")
    const [deleteList, setDeleteList] = useState([]);
    const [deleteConfirmState, setDeleteConfirm] = useState({ isOpen: false, deleteMessage: 'Are you sure you want to delete the selected row(s)?' })
    const [errorDialogVisible, setErrorDialogVisible] = useState(false)
    const [localLoading, setLocalLoading] = useState(false)
    const [confirmState, setConfirm] = useState({ isOpen: false, confirmMessage: '' });
    const [totalData, setTotalData] = useState([]);
    const [createNewRow, setCreateNewRow] = useState(false);
    const [isloading, setIsloading] = useState(false);

    useEffect(() => {
        setSticky()
    })

    const getGst = (data) => (
        data.map(i => {
            let j = { ...i };
            months.forEach(m => {
                j.gstrate[m] = i.gstrate[m] * 100
                j.frontmargin[m] = i.frontmargin[m] * 100
            })
            j.status = i.type
            return j
        })
    )

    useEffect(() => {
        let fieldData;
        let totData;
        if (props.state.data) {
            if (props.type === 'skuMaster') {
                let _updatedData = getGst(props.state.data);
                fieldData = generateFieldData(_updatedData, '')
            } else if (props.type === 'ittMaster') {
                let _updatedData = props.state.data.map(i => {
                    let j = { ...i };
                    ittMasterObjectKeys.forEach(obj => {
                        for (let key in j[obj.key]) {
                            j[obj.key][key] = j[obj.key][key] * 100;
                        }

                    })
                    return j
                })
                fieldData = generateFieldData(_updatedData, '')
            } else if (props.type === 'claims' || props.type.includes('fyActuals')) {
                let _updatedData = props.state.data.map(i => {
                    let j = { ...i };
                    j.custName = i.account
                    return j
                })
                fieldData = generateFieldData(_updatedData, '')
            } else {
                fieldData = generateFieldData(props.state.data, '')
                if (props.state.total) {
                    totData = generateFieldData(props.state.total, 'total');
                }
            }
            
            setFieldData(fieldData)
            setTotalData(totData)
            if (deleteList.length) {
                setDeleteList([]);
            }
        }
    }, [props.state.data, props.account, props.state.total])

    useEffect(() => {
        if (props.type === 'claims') {
            let url = `${API_BASE_URL}` + '/mrspendplan/setup/promo-summary';
            let { account } = props;
            setIsloading(true)
            if (account) {
                let payload = {
                    data: {
                        account: account?.AccountName,
                        fiscalYear: "" + Number(account?.FinancialYear),
                        currentFF: (MonthsNumberMapping[account?.CurrentFF])
                    },
                }
                axios({
                    url: url,
                    method: 'POST',
                    data: payload,
                    headers: {
                        appId: props.match.params.appid
                    },
                })
                    .then((response) => {
                        setTotalData(response?.data?.data)
                        setIsloading(false)
                    })
                    .catch((error) => {
                        setIsloading(false)
                        console.log("errors", error)
                    });
            }
        }
    }, [props.state.data, props.account, props.state.total])

    useEffect(() => {
        props.setEditMode(edited)
    }, [edited])

    let deleteData = props.data ? props.data['DELETE_' + props.type] : null
    useEffect(() => {
        if (deleteData) {
            props.fetchData(conditions ?? []);
            props.removeMRSuccessMessage({ dataUniqueId: 'DELETE_' + props.type });
            setDeleteList([])
        }
    }, [deleteData])

    const getDeleteList = () => {
        return deleteList
    }

    const checkboxOnChanges = (e, id) => {
        if (id === 'All') {
            // select all case
            if (allSelectableRows()?.length > 1) {
                if (e.target.checked) {
                    let list = allSelectableRows();
                    setDeleteList(['All', ...list])
                } else {
                    setDeleteList([])
                }
            }
        } else {
            let dl = getDeleteList().filter(i => i !== 'All');
            let list = [...dl]
            if (e.target.checked) {
                list.push(id)
                if (list.length === allSelectableRows().length) {  // when all rows is selected 1 by 1 show all checked.
                    list.push('All')
                }
            } else {
                let ind = list.indexOf(id)
                list.splice(ind, 1)
            }
            setDeleteList(list)
        }
    }

    const allSelectableRows = () => {
        return fieldData.map(d => d[0].ref && d[0].key ? (d[0].ref || d[0].key) : null).filter(i => i);
    }

    const renderFyActualHeader = (object, subTitleArr, monthKey) => {
        let isInput = !isMonthForecast(monthKey, props.account)
        let style = { fontWeight: 'bold', textAlign: 'end', alignSelf: 'center', minWidth: 80 }
        let paddings = {
            0: 12,
            1: 12,
            2: 8,
            3: 4,
            4: 4,
            5: 4,
            6: 4,
        }
        const getTotalValue = (m) => {
            return (props.state?.totalSum?.value?.[m] || props.state?.totalSum?.offTakeValue?.[m] || props.state?.totalSum?.units?.[m] || 0)
        }
        const getTotalVolume = (m) => {
            return (props.state?.totalSum?.volume?.[m] || props.state?.totalSum?.retailingValue?.[m] || props.state?.totalSum?.offtake?.[m] || 0)
        }
        const getQTRValue = (qtr, fy) => {
            return (props.state?.totalSum?.[fy]?.[qtr] || props.state?.totalSum?.[qtr] || 0)
        }
        return <div style={{ minWidth: 140 }}>
            {['fyActuals', 'skulevelofftake', 'pyfyActuals'].includes(props.type) && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ marginLeft: -8 }} className={classes.totalValue} title={getTotalValue(monthKey)}>{convertToNumber(getTotalValue(monthKey), 2).toLocaleString()}</div>
                <div style={{ height: 20, width: 1, background: '#cecece' }} />
                <div style={{ marginRight: -8 }} className={classes.totalValue} title={getTotalVolume(monthKey)}>{convertToNumber(getTotalVolume(monthKey), 2).toLocaleString()}</div>
            </div>}
            {['omniRetailingOffTakePlanning'].includes(props.type) && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {!['retailingValueQtr', 'offTakeValueQtr'].includes(monthKey) && <><div style={{ marginLeft: -8 }} className={classes.totalValue} title={getTotalValue(monthKey)}>{convertToNumber(getTotalValue(monthKey), 2).toLocaleString()}</div>
                <div style={{ height: 20, width: 1, background: '#cecece' }} />
                <div style={{ marginRight: -8 }} className={classes.totalValue} title={getTotalVolume(monthKey)}>{convertToNumber(getTotalVolume(monthKey), 2).toLocaleString()}</div></>}
                {['retailingValueQtr', 'offTakeValueQtr'].includes(monthKey) && <>
                {monthKey === 'retailingValueQtr' && retailingValueQtr?.map((el) => <div style={{ margin: 2, borderRight: 1, textAlign: 'center' }} className={classes.totalValue} title={getQTRValue(el?.key, monthKey)}>{convertToNumber(getQTRValue(el?.key, monthKey), 2).toLocaleString()}</div>)}
                {monthKey === 'offTakeValueQtr' && offTakeValueQtr?.map((el) => <div style={{ margin: 2, borderRight: 1, textAlign: 'center' }} className={classes.totalValue} title={getQTRValue(el?.key, monthKey)}>{convertToNumber(getQTRValue(el?.key, monthKey), 2).toLocaleString()}</div>)}
                </>}
            </div>}
            <div style={{ fontWeight: 'bold', textAlign: "center", background: 'rgb(16, 114, 189)', color: '#fff' }}>{object.title === 'RETAILINGVALUEQTR' ? 'RETAILINGVALUE' : object.title === 'OFFTAKEVALUEQTR' ? 'OFFTAKEVALUE' : object.title}</div>
            <Grid container style={{ display: 'flex', justifyContent: 'space-between' }}>
                {
                    subTitleArr.map((obj, ind) => {
                        return <Grid item xs={12 / subTitleArr.length} style={{ marginLeft: -14, }}>
                            {(obj.key === 'largepack' || obj.key === 'smallpack') &&
                                <div style={{ ...style, paddingRight: (props.type !== 'ittMaster' ? paddings[ind] : 0) }}> {object.subTitle}</div>}
                            {!(object.overrideSubtitle && (obj.key === 'largepack' || obj.key === 'smallpack')) ?
                                <div key={obj.title} style={{ ...style, paddingRight: (props.type !== 'ittMaster' ? paddings[ind] : 0) }}
                                    dangerouslySetInnerHTML={{ __html: obj.title }} /> : <div style={{ height: 18.19 }} />}
                        </Grid>
                    })
                }
            </Grid>
        </div>
    }

    const checkColumnLabels = (key) => {
        if (['jul', 'aug', 'sep', 'oct', 'nov', 'dec', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'lastYearSalience', 'past3mSalience', 'fySalience'].includes(key)) {
            if (type === 'offtakeBrandVSOD' || type === 'retailBrandVSOD' || type === 'tsSkuSalience') {
                return true;
            }
        }
        if (key === 'brand' || key === 'metric' || key === 'uom' || key === 'eventName' || key === 'account' || key === 'category' || key === 'tsSku') {
            return true;
        }
        return false;
    }

    const getCorrectDecimal = (val) => {
        if (val % 1 === 0) {
            return val
        } else {
            return Math.trunc(val * 100) / 100
        }
    }

    const generateFieldData = (data, str, addNewRow = false) => {
        // expanding objects for lymetric only for table view
        if (type === 'lymetric' || type === 'offtakeBrandVSOD' || type === 'retailBrandVSOD' || type === 'tsSkuSalience') {
            data.forEach((dataN) => {
                Object.keys(dataN).forEach((keyVals) => {
                    if (typeof dataN[keyVals] === 'object' && (dataN[keyVals] !== null && dataN[keyVals] !== undefined)) {
                        Object.keys(dataN[keyVals]).forEach((key) => {
                            dataN[key] = dataN[keyVals][key]
                        })
                    }
                })
                delete dataN['months'];
                delete dataN['qtr'];
            })
        }
        if (type === 'tsSkuSalience') {
            for (let i = 0; i < data.length; i++) {
                if (!data[i]['tsSku']) {
                    data[i]['tsSku'] = 'Total';
                }
                if (data[i]['lastYearSalience'] === undefined || data[i]['lastYearSalience'] === null) {
                    data[i]['lastYearSalience'] = 'Total';
                }
                if (data[i]['past3mSalience'] === undefined || data[i]['past3mSalience'] === null) {
                    data[i]['past3mSalience'] = 'Total';
                }
                if (data[i]['fySalience'] === undefined || data[i]['fySalience'] === null) {
                    data[i]['fySalience'] = 'Total';
                }
            }
        }
        let forecastMonths;
        if (type === 'claims' || type.includes('fyActuals') || type === 'skulevelofftake') {
            forecastMonths = actualForcastMonths(props.account);
        }
        try {
            let arr = []
            let generatedCols = [];
            let _cols = []
            if (type === 'skuMaster' || type === 'ittMaster' || type === 'claims' || type.includes('fyActuals') || type === 'skulevelofftake' || type === 'omniMasterClassification' || type === 'omniRetailingOffTakePlanning') {
                // loop to generate header
                (type === 'claims' ? claimHeadingKeys(props.account) : OmniChannelKeyTypes[type]).map((c, i) => {
                    if (typeof c === 'object') {
                        // check only for itt and sku master and discard specific data keys
                        if ((type === "skuMaster" || type === 'ittMaster' || type === 'claims' || type.includes('fyActuals') || type === 'skulevelofftake' || type === 'omniMasterClassification' || type === 'lymetric' || type === 'omniMasterClassification' || type === 'omniRetailingOffTakePlanning') && (c.key !== 'msufactor' && c.key !== 'sib' && c.key !== 'rentals')) {
                            if (c.subKeys?.length) {
                                c.subKeys.forEach((ii, ind) => {
                                    _cols.push(c.key + "." + c.subKeys[ind].key)
                                })
                                let objKey = c.title === 'retailingValueQtr' ? 'retailingValue' : c.title === 'offTakeValueQtr' ? 'offTakeValue' : c.title
                                generatedCols.push({
                                    colSpan: (type === 'claims' || type.includes('fyActuals') || type === 'skulevelofftake') ? 2 : (type === 'lymetric' ? 4 : (type === 'ittMaster' ? 13 : (type === 'omniRetailingOffTakePlanning' ? (['retailingValueQtr', 'offTakeValueQtr'].indexOf(c?.key) !== -1 ? 7 : 3) : 12))),
                                    valueViewer: () => renderFyActualHeader(c, c.subKeys, c.key),
                                    _id: c.subKeys[0].key,
                                    readOnly: true, idKey: c.subKeys[0].key,
                                    value: (objKey + ' ' + c.subKeys[0].key), header: true, string: true, key: type + i,
                                    className: `tab${str}-0-${i}`,
                                })
                            }
                        }

                    } else {
                        _cols.push(c)
                        generatedCols.push({
                            checkbox: c === "" ? !props.view && true : null,
                            valueViewer: c === "" ? ValueViewerCheckbox : null,
                            width: c === "" ? 85 : undefined,
                            _id: c === "" ? 'All' : null,
                            align: 'left',
                            readOnly: true, idKey: c,
                            value: columnTitles[c] || c, header: true, string: true, key: type + i,
                            className: `tab${str}-0-${i}` + ((c !== "" || i === 0) && readOnlyColumn[c] ? ' sticky-column' : ''),
                        })
                    }
                })
            } else if (type === 'pidMapping' || type === 'lymetric' || type === 'offtakeBrandVSOD' || type === 'retailBrandVSOD' || type === 'tsSkuSalience') {
                OmniChannelKeyTypes[type].map((c, i) => {
                    _cols.push(c)
                    generatedCols.push({
                        checkbox: c === "" ? !props.view && true : null,
                        valueViewer: c === "" ? ValueViewerCheckbox : null,
                        width: c === "" ? 85 : undefined,
                        _id: c === "" ? 'All' : null,
                        align: 'left',
                        readOnly: true, idKey: c,
                        value: columnTitles[c] || c, header: true, string: true, key: type + i,
                        className: `tab${str}-0-${i}` + ((c !== "" || i === 0) && readOnlyColumn[c] ? ' sticky-column' : ''),
                    })
                })
            }
            arr[0] = generatedCols;
            setCols(_cols);
            // loop to generate data
            let arrData = data.map((d, i) => {
                let a = [{
                    readOnly: true, value: i + 1, key: d._id, ref: d._id, string: true, checkbox: !props.view && true, _id: d._id,
                    valueViewer: ValueViewerCheckbox, className: `p-0 tab${str}-${i + 1}-0 sticky-column`, checked: false,
                    width: 85,
                }]

                const getValue = (cellObj, oKey) => {
                    // (oKey && oKey.decimalPlaces) || (type === 'lymetric' || type === 'claims' ? 2 : 0)
                    let val = convertToNumber(cellObj?.cell?.value, 2)

                    if (type === 'skuMaster' && cellObj?.cell.idKey && (cellObj.cell.idKey.split('.')[0] === 'msufactor')) {
                        return Number(cellObj?.cell?.value).toFixed(2)
                    }
                    if (oKey.dataType === 'string') {
                        return cellObj?.cell?.value;
                    } else {
                        return val
                    }
                }
                let keyVal = generateSheetKey(i);
                if (type === 'skuMaster' || type === 'ittMaster' || type.includes('fyActuals') || type === 'skulevelofftake' || type === 'omniMasterClassification' || type === 'omniRetailingOffTakePlanning') {
                    ObjectKeyTypes[type].map((oKey, objectKeyIndex) => {
                        Object.keys(d).map(key => {
                            if (key === oKey.key) {
                                Object.keys(d[key]).map(k1 => {
                                    let ind = (type === 'claims' || type.includes('fyActuals') || type === 'skulevelofftake' || type === 'omniRetailingOffTakePlanning') ? cols.indexOf(`${k1}.${oKey.key}`) : cols.indexOf(`${oKey.key}.${k1}`)
                                    if (ind > -1)
                                        a[ind] = {
                                            id: k1 + "." + key,
                                            idKey: `${key}.${k1}`,
                                            value: d[key][k1],
                                            edit: false,
                                            valueViewer: (oKey.isPercent || oKey.decimalPlaces) ? (cellObj) => <span title={cellObj?.cell?.value}>{convertToNumber(cellObj?.cell?.value, 2)}{oKey.isPercent ? '%' : ''}</span> : (cellObj) => <span>{getValue(cellObj, oKey)}</span>,
                                            key: keyVal + ind,
                                            readOnly: (type === 'claims' || type.includes('fyActuals') || type === 'skulevelofftake') ? (readOnlyColumn[key] || forecastMonths[k1]) : readOnlyColumn[key],
                                            className: "bg-transparent" + " tab" + str + "-" + (i + 1) + "-" + ind + (key !== "" && type !== 'omniMasterClassification' && readOnlyColumn[key] ? (type == 'omniRetailingOffTakePlanning' ? ' ' :  ' sticky-column') : '') + (props.type === 'ittMaster' ? ' l-width' : ' m-width'),
                                        }
                                    // oKey.decimalPlaces
                                })
                            } else {
                                let ind = cols.indexOf(key)
                                if (props.type === 'skuMaster' && key === 'status') {
                                    key = 'type';
                                }
                                if (ind > -1)
                                    a[ind] = {
                                        align: 'left',
                                        id: key,
                                        idKey: key,
                                        value: d[key],
                                        edit: (props.type === 'skuMaster' && (addNewRow || edited) === true && i === 0) ? true : false,
                                        valueViewer: (key === 'total') ? (cellObj) => <span>{getValue(cellObj, oKey)}</span> : null,
                                        string: props.type === 'skuMaster' && key === 'type' ? true : readOnlyColumn[key],
                                        key: keyVal + ind,
                                        editing: true,
                                        readOnly: props.type === 'skuMaster' && key === 'type' ? false : (props.type === 'skuMaster' && (addNewRow || edited) === true && i === 0) ? false : readOnlyColumn[key],
                                        className: "bg-transparent" + " tab" + str + "-" + (i + 1) + "-" + ind + (key !== "" && !(props.type === 'skuMaster' && key === 'type') && readOnlyColumn[key] ? ' sticky-column' : '') + (props.type === 'ittMaster' ? ' l-width' : ' m-width'),
                                    }
                            }
                        })
                    })
                } else if (type === 'pidMapping' || type === 'claims') {
                    Object.keys(d).map(key => {
                        let ind = cols.indexOf(key)
                        if (ind > -1)
                            a[ind] = {
                                align: 'left',
                                id: key,
                                idKey: key,
                                value: key === 'month' ? columnTitles[d[key]] : d[key],
                                edit: false,
                                valueViewer: (key === 'total' || key === 'minPromo%') ? (cellObj) => <span>{getValue(cellObj, key)}</span> : null,
                                string: readOnlyColumn[key],
                                key: keyVal + ind,
                                readOnly: readOnlyColumn[key],
                                className: "bg-transparent" + " tab" + str + "-" + (i + 1) + "-" + ind + (key !== "" && readOnlyColumn[key] ? ' sticky-column' : '') + (props.type === 'ittMaster' ? ' l-width' : ' m-width'),
                            }
                    })
                } else if (type === 'lymetric' || type === 'offtakeBrandVSOD' || type === 'retailBrandVSOD' || type === 'tsSkuSalience') {
                    Object.keys(d).map(key => {
                        let ind = cols.indexOf(key)
                        if (ind > -1)
                            a[ind] = {
                                align: 'left',
                                id: key,
                                idKey: key,
                                value: (type === 'offtakeBrandVSOD' || type === 'retailBrandVSOD' || type === 'tsSkuSalience') && (typeof d[key] === 'number') ? getCorrectDecimal(d[key] * 100) + '%' : d[key],
                                title: d[key],
                                edit: false,
                                valueViewer: checkColumnLabels(key) ? null : (cellObj) => <span>{convertToNumber(cellObj?.cell?.value, 2)}</span>,
                                string: (type === 'offtakeBrandVSOD' || type === 'retailBrandVSOD' || type === 'tsSkuSalience') ? true : readOnlyColumn[key],
                                key: keyVal + ind,
                                readOnly: (type === 'offtakeBrandVSOD' || type === 'retailBrandVSOD' || type === 'tsSkuSalience') ? true : readOnlyColumn[key],
                                className: (!(type === 'offtakeBrandVSOD' || type === 'retailBrandVSOD') ? "bg-transparent" : '') + " tab" + str + "-" + (i + 1) + "-" + ind + (key !== "" && readOnlyColumn[key] ? ' sticky-column' : '') + (props.type === 'ittMaster' ? ' l-width ' : ' m-width ') + ((d[key] * 100) > 100 ? classes.redColor : classes.defaultCellClass),
                            }
                    })
                }
                if (type === 'omniRetailingOffTakePlanning') {
                    Object.keys(d).map((keys, i) => {
                        let ind = cols.indexOf(keys)
                        if(['retailingValueQtr', 'offTakeValueQtr'].indexOf(keys) > -1) {
                            retailOfftakeQuaterSubKeys.map((key, inx) => {
                                let objKey = keys === 'retailingValueQtr' ? 'retailingValue' : keys === 'offTakeValueQtr' ? 'offTakeValue' : ''
                                let val = key?.type ? d[`${objKey}${key?.key}`] : d[keys][key?.key]
                                a[(a.length + inx)] = {
                                    align: 'left',
                                    id: `${i}${objKey}${key?.key}`,
                                    idKey: `${i}${objKey}${key?.key}`,
                                    value: val,
                                    title: val,
                                    edit: false,
                                    valueViewer: checkColumnLabels(key) ? null : (cellObj) => <span>{convertToNumber(cellObj?.cell?.value, 2)}</span>,
                                    string: false,
                                    key: keyVal + ind,
                                    readOnly: (type === 'omniRetailingOffTakePlanning' || type === 'retailBrandVSOD' || type === 'tsSkuSalience') ? true : readOnlyColumn[key],
                                    className: (!(type === 'offtakeBrandVSOD' || type === 'retailBrandVSOD') ? "bg-transparent" : '') + " tab" + str + "-" + (i + 1) + "-" + ind + (key !== "" && readOnlyColumn[key] ? ' sticky-column' : '') + (props.type === 'ittMaster' ? ' l-width ' : ' m-width ') + ((d[key] * 100) > 100 ? classes.redColor : classes.defaultCellClass),
                                }
                            })
                        } 
                    })
                }
                a = a.filter((val) => val !== null);
                return a === null ? false : a;
            })
            let emptyRow = cols.map((c, inx) => ({
                readOnly: inx === 0 && true,
                width: c === "" ? 85 : undefined,
                value: inx === 0 ? arrData.length + 1 : '',
                string: readOnlyColumn[c] ? true : false,
                align: (readOnlyColumn[c] || type === 'skuMapping') ? 'left' : undefined,
                key: generateSheetKey(arrData.length + 1) + inx,
                id: c,
                idKey: c,
                empty: true,
                className: "bg-transparent" + " tab" + str + "-" + (arrData.length + 1) + "-" + inx + ((c !== "" || inx === 0) && readOnlyColumn[c] ? ' sticky-column' : '') + (props.type === 'ittMaster' ? ' l-width' : ' m-width'),
            }))
            if (arrData && Array.isArray(arrData)) {
                if (view) {
                    arrData = arrData.map(fArr => (fArr.map(fObj => ({ ...fObj, readOnly: view }))))
                    return [...arr, ...arrData]
                }
                return [...arr, ...arrData, emptyRow]
            }
            return [emptyRow]
        } catch (e) {
            console.log(e);
        }
    }

    const onCellsChanged = (changes, additions) => {
        try {
            if (!edited)
                setEdited(true)
                localStorage.setItem('editedItem', 'true')
            let arrData = [...fieldData]
            if (changes)
                changes.forEach(({ cell, row, col, value, id }) => {
                    if (arrData[row][0].empty)
                        arrData[row][0].empty = false
                    if(id?.indexOf('retailingValue') !== -1 && type === 'omniRetailingOffTakePlanning') {
                        let offtake = value * arrData[row][col-1]['value'];
                        arrData[row][col-2] = { ...arrData[row][col-2], value: offtake};
                    }
                    arrData[row][col] = { ...arrData[row][col], value, id: id || arrData[row][col].id, empty: false };
                });
            if (additions) {
                additions.forEach(({ cell, row, col, value, id }) => {
                    if (col < arrData[0].length) {
                        // adding extra columns with empty values and freezing some columns
                        if (!arrData[row]) {
                            arrData[row] = [{
                                readOnly: true, value: row, key: '', ref: '', string: true,
                                className: `bg-transparent tab-${Number(row)}-0 sticky-column`,
                            }];
                            let obj = { readOnly: false, value: '', key: '', ref: '', string: false };
                            let _arr = [...arrData[0]];
                            _arr.shift();
                            _arr.forEach((i, count) => {
                                let _className = `bg-transparent tab-${Number(row)}-${count + 1}`;
                                let isStringField = readOnlyColumn[i.idKey] ? readOnlyColumn[i.idKey] : false;
                                if (isStringField && i.idKey !== '') {
                                    _className += ' sticky-column';
                                }
                                arrData[row].push({ ...obj, string: isStringField, className: _className });
                            });
                        }
                        arrData[row][col] = { ...arrData[row][col], value, id };
                    }
                });
            }
            if (additions && !arrData[arrData.length - 1][0].empty || arrData[arrData.length - 1][0].empty === false) {
                let emptyRow = cols.map((c, inx) => ({
                    readOnly: inx === 0 && true,
                    value: inx === 0 ? arrData.length : '',
                    key: generateSheetKey(arrData.length) + inx,
                    empty: true,
                    align: (readOnlyColumn[c] || type === 'skuMapping') ? 'left' : undefined,
                    string: readOnlyColumn[c],
                }))
                arrData = [...arrData, emptyRow]
            }
            updateAllValues(arrData);
        } catch (e) {
            console.log(e)
        }
    };

    const updateAllValues = (aoaData) => {
        let updatedAoA = aoaData.map((row) => {
            let valueObj = row.reduce((obj, item) => Object.assign(obj, { [item.key]: Number(item.value) }), {});
            return row?.map((cell) => {
                const updatedCell = Object.assign(
                    {},
                    cell,
                    computeExpr(cell.key, cell.expr, valueObj, cell)
                );
                return updatedCell;
            })
        })
        setFieldData(updatedAoA)
    }

    const computeExpr = (key, expr, scope, cell) => {
        let value = null;
        if (expr?.charAt(0) === "=") {
            try {
                value = mathjs.evaluate(expr.substring(1), scope);
            } catch (e) {
                value = null;
            }
            if (value !== null) {
                return { value, expr };
            } else {
                return {};
            }
        } else {
            return cell;
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (e, res) => {
        setOpen(false);
        if (props.type === 'skuMaster' && props.appName === 'omnichannel' && createNewRow) {
            props.history.push(`/apps/${props.match.params.appid}/AutoPopuplate`);
        }
    };

    const updateData = () => {
        const channelType = props?.type === 'skuMaster' && props.appName === 'omnichannel' ? 'omniSkuMaster' : props?.type;
        let data = save(fieldData, type, props.account, props.appName)
        let obj = {};
        if (data) {
            let url = props.type === 'skuMaster' ? `${API_BASE_URL}/mrspendplan/skumaster/${props.page.pageLevelDataModel}` : `${API_BASE_URL}/mrspendplan/upload/${props.page.pageLevelDataModel}`
            if(props.type === 'skuMaster') {
                localStorage.removeItem('editedItem');
                let m = getMonth(props?.account?.CurrentFF, 'monthNumber');
                m = (m > 9 ? '' : '0') + m;
                data = data?.filter((el) => !el?._id);
                obj = {
                    formType: channelType,
                    fiscalYear: `${props.account?.FinancialYear}`,
                    currentFF: `${m}`,
                    data: data
                }
            } else if(props.type === 'omniRetailingOffTakePlanning') {
                data.pop();
            }
            axios({
                url: url,
                method: props.type === 'skuMaster' ? 'POST' : 'PUT',
                data: props.type === 'skuMaster' ? obj : data,
                headers: {
                    appId: props.match.params.appid,
                    'Content-Type': 'application/json',
                }
            }).then((response) => {
                let message = "Data saved successfully"
                if (response.data && response.data?.result?.data) {
                    message = response.data?.result?.data
                }
                setEdited(false)
                setAlertMessage(message)
                handleClickOpen()
                props.fetchData()
            }).catch((error) => {
                let msg = error.response && error.response.data && error.response.data.message ? error.response.data.message : 'Something went wrong';
                let d = {
                    title: 'Error',
                    p1: msg,
                }
                setAlertMessage(d)
                setErrorDialogVisible(true)
            })
        }
    }

    const download = () => {
        if (type) {
            let uri = API_BASE_URL + `/setupdata/download/${props.page.pageLevelDataModel}?`;
            uri = `${uri}&formType=${encodeURIComponent(type)}`;

            axios({
                url: uri,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    appId: props.match.params.appid
                },
            }).then((response) => {
                FileDownload(response.data, getFileNameWithTimeStamp('fileNamePrefix'));
            }).catch((error) => {
                console.log(error);
            })
        }
    }
    const deleteHandler = () => {
        if (deleteList?.length) {
            setDeleteConfirm({ ...deleteConfirmState, isOpen: true, deleteMessage: 'Are you sure you want to delete the selected row(s)?' })
        } else {
            setDeleteConfirm({ ...deleteConfirmState, isOpen: true, deleteMessage: 'Are you sure you want to delete the all row(s)?' })
        }
    }

    const onDeleteResponse = (isDelete) => {
        if (isDelete) {
            const account = props.account;
            let m = '' + getMonth(account?.CurrentFF, 'monthNumber')
            let payload = {
                dataUniqueId: 'DELETE_' + props.type,
                url: URLs.deletePlanning + props.page.pageLevelDataModel,
                appid: props.match.params.appid,
                data: {
                    ids: deleteList.filter(i => i !== 'All'),
                },
            }
            if (['skuMaster', 'ittMaster', 'fyActuals', 'claims', 'skulevelofftake', 'omniRetailingOffTakePlanning', 'retailBrandVSOD', 'offtakeBrandVSOD', 'tsSkuSalience', 'omniMasterClassification', 'pidMapping', 'lymetric', 'pyfyActuals'].includes(props.type)) {
                payload.data.currentFF = m.length === 1 ? '0' + m : m;
            }
            if (['skuMaster', 'ittMaster', 'fyActuals', 'pyfyActuals', 'claims', 'skulevelofftake', 'lymetric', 'omniRetailingOffTakePlanning', 'retailBrandVSOD', 'offtakeBrandVSOD', 'tsSkuSalience', 'omniMasterClassification', 'pidMapping'].includes(props.type)) {
                payload.data.fiscalYear = props.type === 'pyfyActuals' || props.type === 'lymetric' ? Number(account?.FinancialYear) - 1 : account?.FinancialYear;
            }
            if (['pidMapping', 'claims', 'fyActuals', 'pyfyActuals', 'claims', 'skulevelofftake', 'lymetric', 'omniRetailingOffTakePlanning', 'retailBrandVSOD', 'offtakeBrandVSOD', 'tsSkuSalience', 'omniMasterClassification'].includes(props.type)) {
                payload.data.account = account?.AccountName
            }
            props.deleteMRData(payload);
        }
        setDeleteConfirm({ ...deleteConfirmState, isOpen: false })
    }

    const onConfirmResponse = (resp) => {
        if (resp) {
            finish();
        }
        setConfirm({ isOpen: false, confirmMessage: '' });
    };

    const onDoneUpdateClick = () => {
        setConfirm({
            ...confirmState, isOpen: true,
            confirmMessage: `${props.page.name} data update done for  ${getMonth(props.account?.CurrentFF, 'month')} ${getFiscalYearFormat(props.account?.FinancialYear)}?`,
        });
    }

    const finish = () => {
        let { AccountName, FinancialYear, CurrentFF } = props.account;
        let m = '' + getMonth(CurrentFF, 'monthNumber').toString();
        m = m.length === 1 ? '0' + m : m;
        let data = {
            'formType': props.type,
            account: AccountName, fiscalYear: FinancialYear, currentFF: m,
            'action': 'finish',
        };
        let url = `${API_BASE_URL}` + URLs.doneUpdates;
        axios({
            url: url,
            method: 'POST',
            data: data,
            headers: {
                appId: props.match.params.appid,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                toast.success(response.data?.result?.message || 'Workflow emails sent successfully');
            })
            .catch((error) => {
                toast.success(error?.data?.message || 'Not able to send workflow emails');
            });
    };


    const onSelectChange = (value, index, subIndex) => {
        setEdited(true)
        fieldData[index + 1][subIndex].value = value
        setFieldData([...fieldData])
    }

    const valListArr = ['legalEntity', 'category', 'brand', 'tsBrand', 'brandform', 'tsSku', 'subBrandForm', 'status']
    
    const createNewLine = () => {
        setEdited(true)
        setCreateNewRow(true)
        let data = props.state.data;
        const newRow = {...props?.state?.data[0]};
        newRow.status = newRow.type;
        delete newRow?._id;
        delete newRow?.uniqueConstraint;
        data.unshift(newRow);
        data = data.map(i => {
            let j = { ...i };
            j.status = i.type
            return j
        })
        let fieldData = generateFieldData(data, '', true)
        setFieldData([...fieldData]);
        localStorage.setItem('editedItem', 'true')
    }

    let filterDataN = props.data ? props.data['GET_FILTER_' + props.type] : {};
    // removing empty fields from all filter values
    let filterData = {};
    if (filterDataN) {
        let keys = Object.keys(filterDataN)
        for (let i = 0; i < keys.length; i++) {
            let nArray = [];
            for (let j = 0; j < filterDataN[keys[i]].length; j++) {
                if (filterDataN[keys[i]][j] !== '') {
                    nArray.push(filterDataN[keys[i]][j]);
                }
            }
            filterData[keys[i]] = JSON.parse(JSON.stringify(nArray));
        }
    }

    return (
        <React.Fragment>
            {setupUploadLoading && <UploadLoading />}
            <div className={classNames(`${classes.headerMainCon} mr-20 mb3`)}>
                <Paper elevation={3} className={classes.headerPaperContainer}>
                    <OmniSheetHeader 
                        {...props}
                        handleFileSave={props.handleFileSave}
                        deleteHandler={deleteHandler}
                        modalData={props.modalData}
                        onDoneUpdateClick={onDoneUpdateClick}
                        onDownload={props.onDownload}
                        fetchData={() => props.fetchData(conditions ?? [])}
                        conditions={conditions}
                        handlePasteClick={props.handlePasteClick} save={updateData} edited={edited}
                        showClearFilter={props.showClearFilter}
                        clearAllFilters={props.clearAllFilters}
                        onClearAll={props.onClearAll} 
                        createNewLine={createNewLine}
                    />
                </Paper>
            </div>
            {
                (props.loading || localLoading) ? <div className={classes.loading}><Loader /></div> :
                    <div className="react-datasheet">
                        {
                            (props.type === 'retailBrandVSOD' || props.type === 'offtakeBrandVSOD' || props.type === 'tsSkuSalience') &&
                            <TableContainer className={classes.tableTotalContainer} component={Paper}>
                                <MathExcelSheet parsePaste={(pasted) => parsePaste(pasted)} checkedRows={deleteList} data={totalData?.length ? totalData : []} type={props.type} onCellsChanged={onCellsChanged}
                                    valueRenderer={cell => cell.value}
                                    dataRenderer={cell => cell.expr}
                                    onSelectChange={onSelectChange}
                                    checkboxOnChanges={checkboxOnChanges} />
                            </TableContainer>

                        }

                        {
                            (props.type === 'claims') &&
                            <TableContainer className={classes.tableTotalContainer} component={Paper}>
                                <Table className={classNames(classes.table, "tpr-table custom-pg-table")} aria-label="simple table">
                                    <TableHead className={classNames(classes.tableHead, classes.tableHeader)}>
                                        <TableRow className='sticky-row'>
                                            {
                                                claimHeadingTotalKeys?.map((header, index) => {
                                                    return (<TableCell key={index} rowSpan={2}>
                                                        <span className={classes.tableCell}>{header}</span>
                                                    </TableCell>)
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.tableBody}>
                                        {totalData?.length ? totalData.map((row, index) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    {
                                                        Object.entries(row).map(([keys, val], i) => {
                                                            return (<StyledTableCell key={i} className={classes?.alignLeft}><span className={classes.tableCell}>{ keys !== 'month' ? numberToINR(convertToNumber(val, 2)) : val}</span></StyledTableCell>)
                                                        })
                                                    }
                                                </StyledTableRow>)
                                        }) :
                                        isloading ? (
                                            <StyledTableCell colSpan={claimHeadingTotalKeys.length}>
                                            <div style={{ textAlign: 'center',width: '100%',height: '100%',display: 'flex',justifyContent: 'center',alignItems: 'center',background:'#ccccc' }}>
                                                <Loader />
                                            </div></StyledTableCell>
                                        ) : renderNoDataText()
                                        }

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                        <TableContainer className={classes.tableContainer} component={Paper}>
                            <MathExcelSheet parsePaste={(pasted) => parsePaste(pasted)} checkedRows={deleteList} data={fieldData?.length ? fieldData : []} type={props.type} onCellsChanged={onCellsChanged}
                                valueRenderer={cell => cell.value}
                                dataRenderer={cell => cell.expr}
                                onSelectChange={onSelectChange}
                                checkboxOnChanges={checkboxOnChanges} 
                                filterData={filterData}
                                />
                        </TableContainer>


                        <TablePagination
                            rowsPerPageOptions={[25, 50, 100, 200]}
                            component="div"
                            count={props.state.count ? props.state.count : 0}
                            className="custom-table-pagination"
                            rowsPerPage={props.pagination.rowsPerPage ? props.pagination.rowsPerPage : 50}
                            page={props.pagination.page ? props.pagination.page : 0}
                            backIconButtonProps={{
                                'aria-label': 'Previous Page'
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'Next Page',
                                'margin-top': '-18px'
                            }}
                            onChangePage={props.handleChangePage}
                            onChangeRowsPerPage={props.handleChangeRowsPerPage}
                        />
                    </div>
            }

            <ConfirmDialog open={errorDialogVisible} handleClose={() => setErrorDialogVisible(false)} alertMessageText={alertMessageText} classes={classes} />
            <ConfirmDialog open={deleteConfirmState.isOpen} handleClose={onDeleteResponse}
                alertMessageText={deleteConfirmState.deleteMessage} classes={classes} confirm />
            <MRPlanUploadConfirmDialog open={open} handleClose={handleClose} alertMessageText={alertMessageText} title={props.page.title} />
            <ConfirmDialog open={confirmState.isOpen} handleClose={onConfirmResponse}
                alertMessageText={confirmState.confirmMessage} classes={classes} confirm />
        </React.Fragment >
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
        account: state.mrReducer?.account,
        form: state.form,
        data: state.mrReducer?.data,
        tprReducer: state.tprReducer,
    }
}

const mapDispatchToProps = {
    deleteSelectedFormData,
    addReconReviewBaseline,
    deleteMRData,
    removeMRSuccessMessage,
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(OmniSheetTable)))
