import { Avatar, Box, CssBaseline, Grid, Link, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Popover, Select } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AddIcon from '@material-ui/icons/Add';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getBrand, getEntity, getMemberById, getRoles, logoutUser, selectBrand } from 'store/actions';
// import dsilologo from '../images/dsilo-white.svg';
import dsilologo from '../images/dsilo-black.png';
import { TradePriceReductionService } from '../modules/api';
import { checkFreezeProcess, checkSpBGprocesses, getMRAccounts, selectAccount, getMrData, getImarScenarioList, getSelScenario, updateScenarioLable } from '../store/actions';
import { getMonth } from "../utility/utils";
import AddScenarioModal from './customPages/spendplan/ReportsPages/Scenario/addScenarioModal';
import AddReconBaselineModal from './customPages/spendplan/ReportsPages/ReconReview/addReconBaselineModal';
import FinalScenarioModal from './customPages/spendplan/ReportsPages/FinalScenario/finalScenarioModal'
import ConfirmDialog from './customPages/spendplan/SpendPlan/ConfirmDialog';
import { getCurrentFFForPayload, getFiscalYearFormat, monthsUpperCase, MR_TITLE, TSP_TITLE, OMNI_TITLE, MR_CAPTION } from "./customPages/spendplan/spendplanUtils";
import { getFFFYValues } from './table/navbarHelper';
import { URLs } from './customPages/mrplan/ApiConstants';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const REACT_THEME_DESIGN = process.env.REACT_APP_THEME_DESIGN;
const REACT_APP_APPLICATION = process.env.REACT_APP_APPLICATION;
const isIBC = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'ibc';
const isSpendPlan = REACT_APP_APPLICATION && REACT_APP_APPLICATION === 'spendplan';

const a = {
	textDecoration: 'none',
	color: '#fff'
};
const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: '#060606',
		flexGrow: 1,
		'& .MuiInputBase-root': {
			color: ' #fff'
		},
		'& .Mui-disabled': {
			color: '#fff',
			backgroundColor: '#002744'
		},

	},
	confirmationDialoge: {
		'& .MuiDialog-paperWidthSm': {
			height: '320px',
			width: '600px'
		}
	},
	para: {
		fontSize: '28px !important',
		fontWeight: 600,
		color: '#000'
	},
	// header: {
	// 	backgroundColor: '#060606',
	// 	borderBottom: '1px solid #282828'
	// },
	sectionDesktop: {
		display: 'none',
		marginBottom: '10px',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	button: {
		margin: theme.spacing(1),
	},
	// appBar: {
	// 	color: "#fff",
	// 	backgroundColor: "#fff",
	// 	top: "0",
	// 	left: "auto",
	// 	right: 0,
	// 	position: "fixed",
	// 	width: "100%",
	// 	display: "flex",
	// 	zIndex: "1",
	// 	boxSizing: "border-box",
	// 	flexShrink: "0",
	// 	flexDirection: "column",
	// 	boxShadow: "0px 2px 4px - 1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
	// },
	logoImg: {
		width: "55px"
	},
	menuLeft: {
		justifyContent: "flex-end",
		flexWrap: "nowrap"
	},
	flex: {
		display: "flex",
		alignItems: "center",
		marginBottom: "2px"
	},
	flexDirection: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: "left",
		marginBottom: "2px"
	},
	caption: {
		fontStyle: 'italic',
		fontSize: '12px'
	},
	menuNav: {
		display: "flex"
	},
	menulists: {
		color: REACT_THEME_DESIGN === 'dark' ? '#fff' : '#111',
		padding: "0px 15px",
		fontSize: "16px",
		borderRight: "1px solid #E4E4E4"
	},
	hamburgerImg: {
		width: "33px"
	},
	menulist: {
		color: REACT_THEME_DESIGN === 'dark' ? '#fff' : '#fff',
		padding: "0px 5px",
		fontSize: "16px",
		position: 'relative',
		top: '4px'
	},
	large: {
		width: "56px !important",
		height: "40px !important"
	},
	notifyIcon: {
		fontSize: "20px"
	},
	// backgroundTheme: {
	// 	padding: '0.5rem 0 !important',
	// 	// borderBottom: REACT_THEME_DESIGN === 'dark' ? '1px solid  #282828' : null,
	// 	backgroundColor: isIBC ? '#102d5e' : REACT_THEME_DESIGN === 'dark' ? '#060606' : '#fff',
	// 	boxShadow: 'none',
	// 	...(isIBC && { borderBottom: '1px solid #ffffff' })
	// },
	textColor: {
		color: '#fff',
	},
	fontWhite: {
		color: '#fff',
	},
	icon: {
		fill: '#fff',
		top: '4px !important',
		fontSize: '22px !important',
	},
	customHeight:{
		maxHeight:"65px",
	}
}));

const NavBar = (props) => {

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [role, setRole] = React.useState('');
	const [state, setState] = React.useState({ userMenu: null });
	const { user, sptprData, header, app, brands } = props;
	const uniqBrands = brands.length ? [
		...new Map(brands.map((item) => [item["BrandName"], item])).values(),
	] : [];
	const { selectBrand, brandEmpty, scenarioList } = sptprData;
	const [brand, setBrand] = useState("")
	const [addBaselineDialogVisible, toggleAddBaselineDialog] = useState(false);
	const [addScenarioDialogVisible, toggleAddScenarioDialog] = useState(false);
	const [finalScenarioDialogVisible, toggleFinalScenarioDialog] = useState(false);
	const [_interval, _setInterval] = useState(null);
	const [_interval1, _setInterval1] = useState(null);
	const [confirmHandler, setConfirmHandler] = useState({ isOpen: false, msg: "" })
	const [scenarioArr, setScenarioArr] = useState([...scenarioList])
	const [selScenario, setSelScenario] = useState('01');
	const [activeScenario, setActiveScenario] = useState();
	const [isFFFYDisabled, setIsFFFYDisabled] = useState(true)
	const [scenarioLable, setScenarioLable] = useState('')
	const account = props?.omniAccounts?.data.find((el) => el._id === brand?._id)
	const isDummy = account && account?.AccountName === "Dummy" ? true : false;
	const defaultBrand = props?.omniAccounts?.data.find((el) => el.AccountName !== "Dummy")

	useEffect(() => {
		if (props.match.params.appid) {
			if (app.title === TSP_TITLE) {
				props.getBrand(props.match.params.appid);
			} else {
				let payload = {
					appId: props.match.params.appid,
					// id: app.appLevelDataModelController, // no need of this config now.
				}
				props.getMRAccounts(payload)
				let url = URLs.getOmniAccounts;
				let payloadNew = {
					url: url,
					appId: props.match.params.appid,
					dataUniqueId: 'GET_omniAccounts',
				}
				props.getMrData(payloadNew)
			}
		}
	}, [app])

	const checkSpBGprocesses = () => {
		if (Object.keys(sptprData?.selectBrand).length) {
			let m = '' + getMonth(sptprData?.selectBrand?.CurrentFF, 'monthNumber');
			m = m.length === 1 ? '0' + m : m;
			let obj = {
				appId: props.match.params.appid,
				brand: encodeURIComponent(sptprData?.selectBrand.BrandName),
				fiscalYear: sptprData?.selectBrand.FinancialYear + "",
				currentFF: m
			}
			props.checkSpBGprocesses(obj)
		}
	}

	const checkFreezePlanProcesses = () => {
		props.checkFreezeProcess({
			appId: props.match.params.appid,
		})
	}

	useEffect(() => {
		if(props.match.params.appid && brand?.BrandName && app.title === TSP_TITLE) {
			let m = getMonth(brand.CurrentFF, 'monthNumber');
			props.getImarScenarioList({
				appId: props.match.params.appid,
				"account": brand.BrandName,
				"fiscalYear": brand.FinancialYear,
				"currentFF": getCurrentFFForPayload(m)
			})
		}
	},[props.match.params.appid, brand?.BrandName])

	useEffect(() => {
		setIsFFFYDisabled(prev => props?.name === 'Dashboard' ? false : true)
	}, [props?.name]);


	const isInValidBGProcesses = () => {
		let threeMinuteAgoTime = moment.utc().subtract(3, "minutes")
		return props.sptprData?.bgProcesses?.every(i => moment(i.createdDate).diff(threeMinuteAgoTime) < 0)
	}

	const getBGProcessMessage = () => {
		let threeMinuteAgoTime = moment.utc().subtract(3, "minutes")
		let data = props.sptprData?.bgProcesses;
		if (data?.length) {
			let filteredData = data.filter(i => moment(i.createdDate).diff(threeMinuteAgoTime) > 0)
			return filteredData.length ? filteredData[0].message : null;
		}
	}

	const getFreezePlanProcessMessage = () => {
		let _data = props.sptprData?.freezePlanProcesses;
		return _data.length ? _data[0].message : null;
	}

	useEffect(() => {
		if (!_interval) {
			if (props.sptprData?.bgProcesses?.length && !isInValidBGProcesses()) {
				_setInterval(setInterval(checkSpBGprocesses, 10000))
			}
		}
		if (!props.sptprData?.bgProcesses?.length || isInValidBGProcesses()) {
			clearInterval(_interval);
			_setInterval(null);
		}
	}, [props.sptprData?.bgProcesses, getBGProcessMessage()])


	useEffect(() => {
		if (!_interval1) {
			if (props.sptprData?.freezePlanProcesses?.length) {
				_setInterval1(setInterval(checkFreezePlanProcesses, 10000))
			}
		}
		if (!props.sptprData?.freezePlanProcesses?.length) {
			clearInterval(_interval1);
			_setInterval(null);
		}
	}, [props.sptprData?.freezePlanProcesses])

	useEffect(() => {
		if (selectBrand && typeof selectBrand === 'object' && selectBrand !== null && Object.keys(selectBrand).length > 0) {
			setBrand(selectBrand);
		}
	}, [selectBrand])

	useEffect(() => {
		if (scenarioList && scenarioList.length > 0) {
			const isActive = scenarioList.find(element => element.isActive);
			setActiveScenario(isActive || scenarioList[0]);
			isActive?.value ? setSelScenario(`${isActive?.value}`) : setSelScenario(`01`);
			setScenarioArr(scenarioList);
			if(isActive) setScenarioLable(isActive?.newLabel);
		}
	}, [selectBrand, scenarioList])

	const handleChange = (e) => {
		let selectedBrand = props.brands ? props.brands.find(i => i._id === e.target.value) : null;
		if (selectedBrand) {
			saveBrandToLocalStorage(selectedBrand);
			setBrand(selectedBrand)
			props.selectBrand(selectedBrand)
		}
	}

	const userMenuClick = event => {
		setState({ ...state, userMenu: event.currentTarget });
	};

	const userMenuClose = () => {
		setState({ ...state, userMenu: null });
	};

	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const menuId = 'primary-search-account-menu';

	const handleAskDsilo = (event) => {
	}

	const handleAppConfig = (event) => {
	}

	const checkURLforBrand = () => {
		const params = props.match.params,
			urlSearchParams = new URLSearchParams(window.location.search);
		let brandName = '';

		if (params.brand) {
			brandName = params.brand;
		} else if (urlSearchParams.get('brand')) {
			brandName = urlSearchParams.get('brand');
		}

		return brandName;
	}


	const handleAccountChange = (e) => {
		let selectedAccount = props.omniAccounts?.data?.find(i => i._id === e.target.value)
		if (selectedAccount) {
			setBrand(selectedAccount)
			localStorage.setItem('selectedAccount', selectedAccount._id)
			props?.selectAccount(selectedAccount)
			props?.selectAccountChange(selectedAccount)
		}
	}

	useEffect(() => {
		if (isDummy) {
			const selectedAccount = props?.omniAccounts?.data.find((el) => el.AccountName !== "Dummy")
			if (selectedAccount) {
				setBrand(selectedAccount)
				localStorage.setItem('selectedAccount', selectedAccount._id)
				props?.selectAccount(selectedAccount)
				props?.selectAccountChange(selectedAccount)
			}
		}
	}, [isDummy])

	useEffect(() => {
		if (props.app && props.app._id && !props.rolesLoading) {
			props.getRoles(props.app._id);
			props.getMemberById(props.app._id)
		}
	}, [props.app])

	useEffect(() => {
		if (props.match.params.appid && props.member && props.member.entityId) {
			props.getEntity(props.member.entityId, props.match.params.appid);
		}
	}, [props.member])

	useEffect(() => {
		if (props.roles && props.member && props.member.selectedRoles)
			props.roles && props.roles.forEach(r => {
				props.member && props.member.selectedRoles &&
					props.member.selectedRoles.forEach(ur => {
						if (r?._id === ur?.value) {
							setRole(ur?.label)
							return;
						}
					})
			})
	}, [props?.roles, props?.member])

	const fun = async () => {
		if (app.title === TSP_TITLE) {
			let _brandId = await fetchBrandFromLocalStorage('selectedBrand')
			if (Array.isArray(props.brands) && props.brands.length > 0) {
				let brandName = checkURLforBrand();
				if (brandName) {
					props.brands.some(brandObj => {
						if (brandObj.BrandName === brandName) {
							setBrand(brandObj)
							saveBrandToLocalStorage(brandObj)
							props.selectBrand(brandObj)
							return true
						}
					})
				} else {
					if (_brandId && props.brands.find(i => i._id === _brandId)) {
						let selectedBrand = props.brands.find(i => i._id === _brandId);
						setBrand(selectedBrand)
						props.selectBrand(selectedBrand)
					} else {
						setBrand(props.brands[0])
						saveBrandToLocalStorage(props.brands[0])
						props.selectBrand(props.brands[0])
					}
				}
			} else {
				setBrand("")
			}
		} else {
			let accountId = await fetchBrandFromLocalStorage('selectedAccount')
			if (accountId && props.omniAccounts?.data?.find(i => i._id === accountId)) {
				let selectedAccount = props.omniAccounts?.data?.find(i => i._id === accountId);
				setBrand(selectedAccount)
				props?.selectAccount(selectedAccount);
				props?.selectAccountChange(selectedAccount);

			} else if (props.omniAccounts?.data?.length) {
				const selectedAccount = props?.omniAccounts?.data.find((el) => el.AccountName !== "Dummy")
				setBrand(selectedAccount)
				localStorage.setItem('selectedAccount', selectedAccount?._id)
				props?.selectAccount(selectedAccount);
				// props?.selectAccountChange(selectedAccount);
				props?.selectAccountChange(selectedAccount);
			} else {
				setBrand("")
			}
		}
	}

	useEffect(() => {
		fun();
	}, [props.brands, props.omniAccounts, props.omniAccounts?.data, selScenario])


	const saveBrandToLocalStorage = (brand) => {
		localStorage.setItem('selectedBrand', brand._id)
	}

	const fetchBrandFromLocalStorage = (brand) => {
		let brandId = localStorage.getItem(brand);
		if (brandId) {
			return brandId;
		} else {
			return null
		}
	}

	const onScenarioChange = (e) => {
		let m = getMonth(brand.CurrentFF, 'monthNumber');
		let payload = {
			data: {
				"brand": brand.AccountName,
				"fiscalYear": brand.FinancialYear,
				"currentFF": `${m}`,
				"selScenario": `${e.target.value}`
			},
			appId: props.match.params.appid
		}
		props.getSelScenario({...payload});
		setSelScenario(e.target.value);
		const scenario = scenarioList.find(element => element.value === e.target.value);
		setActiveScenario(scenario);
		setScenarioLable(scenario?.newLabel || '');
	}

	const addScenario = (e, val) => {
		let arr = [...scenarioArr];
		if (arr.length === 2 && !val) {
			toggleAddScenarioDialog(true);
			return;
		}
		if (arr.length === 3) return;
		let m = getMonth(brand.CurrentFF, 'monthNumber');
		let payload = {
			data: {
				"brand": brand.BrandName,
				"fiscalYear": brand.FinancialYear,
				"currentFF": getCurrentFFForPayload(m),
				"scenarioFrom": val ? `${val}` : `0${arr?.length}`,
				"scenarioTo": `0${arr?.length + 1}`
			},
			appId: props.match.params.appid
		}
		TradePriceReductionService.addScenario(payload)
			.then((res) => {
				if (res.status === 200) {
					window.location.reload(false)	
					// props.getImarScenarioList({
					// 	appId: props.match.params.appid,
					// 	"account": brand.BrandName
					// })
				} else {
					setConfirmHandler({
						isOpen: true,
						p5: res.message,
						title: 'Error',
					})
				}
			}).catch(err => {
				setConfirmHandler({
					isOpen: true,
					p5: err.message,
					title: 'Error',
				})
			})
	}

	const finalScenario = (e, val) => {
		let payload = {
			data: {
				"brand": brand.BrandName,
				"fiscalYear": brand.FinancialYear,
				"currentFF": getCurrentFFForPayload(m),
				"scenarioFrom": val ? `${val}` : `0${arr?.length}`,
				"scenarioTo": `0${arr?.length + 1}`
			},
			appId: props.match.params.appid
		}
		TradePriceReductionService.finalScenario(payload)
			.then((res) => {
				if (res.status === 200) {
					window.location.reload(false)	
					// props.getImarScenarioList({
					// 	appId: props.match.params.appid
					// })
				} else {
					setConfirmHandler({
						isOpen: true,
						p5: res.message,
						title: 'Error',
					})
				}
			}).catch(err => {
				setConfirmHandler({
					isOpen: true,
					p5: err.message,
					title: 'Error',
				})
			})
	}

	const onFFFYChange = (e) => {
		let val = e.target.value.split('-');
		let m = getMonth(brand.CurrentFF, 'monthNumber');
		let payload = {
			data: {
				"brand": brand.BrandName,
				"from": {
					"fiscalYear": brand.FinancialYear,
					"currentFF": getCurrentFFForPayload(m)
				},
				"to": {
					"fiscalYear": val[0],
					"currentFF": getCurrentFFForPayload(Number(val[1]))
				}
			},
			appId: props.match.params.appid
		}
		TradePriceReductionService.changeFFFY(payload)
			.then((res) => {
				if (res.status === 200) {
					window.location.reload(false)
				} else {
					setConfirmHandler({
						isOpen: true,
						p5: res.message,
						title: 'Error',
					})
				}
			}).catch(err => {
				setConfirmHandler({
					isOpen: true,
					p5: err.message,
					title: 'Error',
				})
			})
	}

	const isChannelManger = () => {
		// if only 1 role exist with Channel manger only then we need to hide this dropdown because this page is all brands page
		// in case of channel manager we need to give access of  account: "All accounts" to current user
		let channelMangerRole = props.roles.find(i => i.name === 'Channel Manager')
		if (channelMangerRole) {
			let _selectedRoles = props.member.selectedRoles
			return _selectedRoles?.length === 1 && _selectedRoles[0].value === channelMangerRole._id
		}
	}

	const scenarioLableChange = () => {
		let payload = {
			data: {
				"label": `_${scenarioLable}`,
				"_id": activeScenario?._id
			},
			appId: props.match.params.appid
		}
		// props.updateScenarioLable({
		// 	appId: props.match.params.appid,
		// 	"label": `${activeScenario?.label}_${scenarioLable}`,
		// 	"_id": activeScenario?._id
		// });
		TradePriceReductionService.updateScenarioLable(payload)
			.then((res) => {
				if (res.status === 200) {
					window.location.reload(false)
					// props.getImarScenarioList({
					// 	appId: props.match.params.appid,
					// 	"account": brand.BrandName
					// })
				} else {
					setConfirmHandler({
						isOpen: true,
						p5: res.message,
						title: 'Error',
					})
				}
			}).catch(err => {
				setConfirmHandler({
					isOpen: true,
					p5: err.message,
					title: 'Error',
				})
			})
	}

	const handleClose = () => {
		setConfirmHandler({
			isOpen: false,
			msg: '',
		})
	}
	const classes = useStyles();
	return (
		<div className="dashboardkeywordB-main">
			<div className={classes.root}>
				<CssBaseline />
				<AppBar className={[classes.appBar, classes.backgroundTheme, "background-theme", "app-header-dark",classes.customHeight]}>
					<Toolbar className={classes.toolbar}>
						<Grid container>
							<Grid item sm={12} md={6} className={classes.flex}>
								<Typography component="div" className={classes.flex}>
									{/* <img src={isIBC ? IBClogo : disiloLogo} alt="Logo Image" className={isIBC ? 'ibc-logo' : classes.logoImg} /> */}
									<img src={(app?.logoId) ? `${API_BASE_URL}/logo/${app?.logoId}` : dsilologo}
										alt="Logo Image"
										className={isIBC ? 'ibc-logo logo-styles' : `logo-styles ${classes.logoImg}`}
										style={isSpendPlan ? { borderRadius: '30px' } : {}}
									/>
									{app.poweredBy &&
										<div className={classes.flex}>
											Powered by <img src={dsilologo} alt="dsiloLogo" height="15"
												style={{ marginLeft: '5px' }} />
										</div>
									}
									{app.displayTitle && <div className={classNames(classes.flexDirection, 'header-app-title title-dark')}>
										{app.title === MR_TITLE ? OMNI_TITLE : app.title}
										<span className={classes.caption}>{MR_CAPTION}</span>
									</div>}
								</Typography>
								{/* </Grid>
							<Grid item sm={3} className={classes.flex}> */}
								{REACT_APP_APPLICATION === 'spendplan' && (getBGProcessMessage() || Boolean(props.sptprData?.freezePlanProcesses?.length)) &&
									<div style={{ display: 'flex' }}>
										<div style={{ width: 40, }}><CircularProgress disableShrink /></div>
										{getBGProcessMessage() &&
											<span style={{ paddingLeft: 15, alignSelf: 'center' }}>{getBGProcessMessage()}</span>}
										{Boolean(props.sptprData?.freezePlanProcesses?.length) &&
											<span style={{ paddingLeft: 15, alignSelf: 'center' }}>{getFreezePlanProcessMessage()}</span>
										}
									</div>}
							</Grid>

							<Grid item sm={12} md={6} className="navmenu-bar">
								<Grid container direction="row" className={classes.menuLeft}>
									{(app.title === TSP_TITLE && !brandEmpty) &&
										<Grid item xs={12} sm={app.title === TSP_TITLE ? 4 : 2} className="setup-data-headings-align-right " style={{ display: 'flex' }}>
											<Select
												value={selScenario}
												className="custom-select fontWhite"
												inputProps={{
													classes: {
														icon: classes.icon,
													},
												}}
												onChange={onScenarioChange}>
												{scenarioList.map((item, i) => {
													return <MenuItem key={i} value={item?.value}>{item?.label}{item?.newLabel}</MenuItem>
												})}
											</Select>
											{scenarioArr?.length < 3 && <Button
												variant="text"
												size="small"
												style={{ padding: 0, textTransform: 'none', whiteSpace: 'nowrap', color: '#FFF' }}
												onClick={addScenario}
											><AddIcon /></Button>}
										</Grid>
									}
									{(app.title === TSP_TITLE && !brandEmpty && scenarioArr?.length > 0) &&
										<Grid item xs={12} sm={app.title === TSP_TITLE ? 5 : 3} className="setup-data-headings-align-right " style={{ display: 'flex' }}>
											<Button
												variant="contained"
												color="primary"
												size="small"
												style={{ marginRight: 20, textTransform: 'none', whiteSpace: 'nowrap' }}
												onClick={() => toggleFinalScenarioDialog(!finalScenarioDialogVisible)}
											> {'Final Scenario'} </Button>
										</Grid>

									}
									{(app.title === TSP_TITLE && !brandEmpty && scenarioArr?.length > 0) &&
										<Grid item xs={12} sm={app.title === TSP_TITLE ? 5 : 3} className="setup-data-headings-align-right " style={{ display: 'flex' }}>
											{/* <TextField id="label" label="Outlined" variant="outlined" sx={{position: 'relative', width: '300px'}} /> */}
											<input  value={scenarioLable} style={{padding: '6px 2px', borderRadius: '4px', width: '150px'}} onChange={(e) => setScenarioLable(e.target.value)}/>
											<Button
												variant="contained"
												color="primary"
												size="small"
												style={{ marginRight: 20, textTransform: 'none', whiteSpace: 'nowrap' }}
												onClick={scenarioLableChange}
											> {'Save'} </Button>
										</Grid>

									}
									{(app.title === TSP_TITLE && !brandEmpty) &&
										<Grid item xs={12} sm={app.title === TSP_TITLE ? 5 : 3} className="setup-data-headings-align-right " style={{ display: 'flex' }}>
											<Button
												variant="contained"
												color="primary"
												size="small"
												style={{ marginRight: 20, textTransform: 'none', whiteSpace: 'nowrap' }}
												onClick={() => toggleAddBaselineDialog(!addBaselineDialogVisible)}
												startIcon={<AddIcon />}
											> {'Add Recon Baseline'} </Button>
										</Grid>

									}
									{
										addScenarioDialogVisible && <AddScenarioModal
											open={addScenarioDialogVisible}
											scenarioArr={scenarioArr}
											add={addScenario}
											handleClose={() => toggleAddScenarioDialog(!addScenarioDialogVisible)} />
									}
									{
										finalScenarioDialogVisible && <FinalScenarioModal
											open={finalScenarioDialogVisible}
											handleClose={() => toggleFinalScenarioDialog(!finalScenarioDialogVisible)} />
									}
									{
										addBaselineDialogVisible && <AddReconBaselineModal
											open={addBaselineDialogVisible}
											handleClose={() => toggleAddBaselineDialog(!addBaselineDialogVisible)} />
									}
									{isSpendPlan && <Grid item xs={12} sm={app.title === TSP_TITLE ? 7 : 9} className="setup-data-headings-align-right " style={{ display: 'flex', justifyContent: 'flex-end' }}>
										{props.match.params.appid && <>
											{(brand && app.title === TSP_TITLE) &&
												<>
													<Select
														value={brand.FinancialYear + '-' + (monthsUpperCase.indexOf(brand.CurrentFF) + 1)}
														className="custom-select fontWhite"
														inputProps={{
															classes: {
																icon: classes.icon,
															},
														}}
														disabled={isSpendPlan && isFFFYDisabled}
														onChange={onFFFYChange}>
														{getFFFYValues(brand).map((item, i) => {
															return <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
														})}
													</Select>
													<Select
														value={brand._id}
														className="custom-select fontWhite"
														inputProps={{
															classes: {
																icon: classes.icon,
															},
														}}
														style={{ marginLeft: 15 }}
														onChange={handleChange}>
														{uniqBrands.map((item, i) => {
															return <MenuItem key={item._id + i} value={item._id.toString()}>{item.BrandName}</MenuItem>
														})}
													</Select>
												</>
											}
											{(brand && props.omniAccounts?.data && app.title === MR_TITLE) && !isChannelManger() &&
												<>
													<span className="setup-data-headings" style={{ alignSelf: 'center' }}>
														{brand && brand?.FinancialYear && brand?.CurrentFF
															? `FY ${getFiscalYearFormat(brand?.FinancialYear)} ${getMonth(brand?.CurrentFF, 'month')} FF`
															: 'FY XXXX XXX FF'}
													</span>
													<Select
														value={`${brand?._id}`}
														className="custom-select fontWhite"
														inputProps={{
															classes: {
																icon: classes.icon,
															},
														}}
														onChange={handleAccountChange}>
														{props?.omniAccounts?.data?.map((item, i) => {
															return (item?.AccountName === 'Dummy' ? '' : <MenuItem key={item?._id + i} value={item?._id.toString()}>{item?.AccountName === 'Dummy' ? '' : item?.AccountName}</MenuItem>)
														})}
													</Select>
												</>
											}
										</>}
									</Grid>}
									<List component="nav" className={classes.menuNav}>
										{/* <ListItem className={classes.menulists}>
											<Box>
												<ListItemIcon>
													<Search className={classes.notifyIcon} />
												</ListItemIcon>
											</Box>
										</ListItem>
										<ListItem className={classes.menulists}>
											<Box>
												<ListItemIcon>
													<Notifications className={classes.notifyIcon} />
												</ListItemIcon>
											</Box>
										</ListItem> */}
										<ListItem className={classes.menulist}
											style={{ justifyContent: "flex-end", paddingRight: "0", position: 'relative', right: '-5px' }}>
											<Box style={{ padding: '0' }}>
												<ListItemAvatar>
													{user.photoURL
														? <Avatar alt="user photo" src={user.photoURL} className={classes.large} />
														: <AccountCircleIcon className={classes.large} />
													}
												</ListItemAvatar>
											</Box>
										</ListItem>
										<ListItem
											// className={classes.menulist}
											// onClick={userMenuClick}
											// style={{ cursor: "pointer" }}
											style={{ fontSize: "16px", padding: "0" }}
										>
											<div>
												<Typography component="span" style={{ display: "inline-flex" }} className={classes.textColor}>
													<span style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>{user?.email || ''}</span>
												</Typography>

												{/* <Typography color="textSecondary" style={{ display: "inline-flex" }} className={classes.textColor}>
													<span style={{ whiteSpace: 'nowrap' }}>
														{props.entity && props.entity.name ? props.entity.name : user.orgName}</span>
												</Typography> */}

												<Typography color="textSecondary" style={{ display: "inline-flex" }} className={classes.textColor}>
													<span style={{ whiteSpace: 'nowrap' }}>{role}</span>
												</Typography>
											</div>
										</ListItem>
										<ListItem
											style={{ cursor: "pointer", justifyContent: "flex-start", paddingRight: '0' }}
											onClick={() => {
												props.logoutUser();
												userMenuClose();
											}}>
											<ListItemIcon title="Logout">
												<ExitToAppOutlinedIcon alt="logout"
													className={classNames(classes.textColor, 'logout-icon')} />
											</ListItemIcon>
										</ListItem>


										{/* <ListItem className={classes.menulist}>
											<Box>
												<img src={hamburger} alt="menu icon" className={classes.hamburgerimg} />
											</Box>
										</ListItem> */}
									</List>
									<Popover
										open={Boolean(state.userMenu)}
										anchorEl={state.userMenu}
										onClose={userMenuClose}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center'
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'center'
										}}
										classes={{
											paper: "py-8"
										}}
									>
										{user.role === 'guest' ? (
											<React.Fragment>
												<MenuItem component={Link} to="/login">
													<ListItemIcon>
														<LockOutlinedIcon />
													</ListItemIcon>
													<ListItemText className="pl-0" primary="Login" style={{ color: "#000" }} />
												</MenuItem>
												<MenuItem component={Link} to="/register">
													<ListItemIcon>
														<PersonAddOutlinedIcon />
													</ListItemIcon>
													<ListItemText className="pl-0" primary="Register" style={{ color: "#000" }} />
												</MenuItem>
											</React.Fragment>
										) : (
											<React.Fragment>
												<MenuItem component={Link} to="/pages/profile" onClick={userMenuClose}>
													<ListItemIcon>
														<AccountCircleOutlinedIcon />
													</ListItemIcon>
													<ListItemText className="pl-0" primary="My Profile" style={{ color: "#000" }} />
												</MenuItem>
												<MenuItem component={Link} to="/apps/mail" onClick={userMenuClose}>
													<ListItemIcon>
														<MailOutlinedIcon />
													</ListItemIcon>
													<ListItemText className="pl-0" primary="Inbox" style={{ color: "#000" }} />
												</MenuItem>
												<MenuItem
													onClick={() => {
														props.logoutUser();
														userMenuClose();
													}}>
													<ListItemIcon>
														<ExitToAppOutlinedIcon />
													</ListItemIcon>
													<ListItemText className="pl-0" primary="Logout" />
												</MenuItem>
											</React.Fragment>
										)}
									</Popover>
								</Grid>
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>
			</div>
			{confirmHandler.isOpen && <ConfirmDialog open={true} handleClose={handleClose} alertMessageText={confirmHandler}
				classes={classes} />}
		</div >
	)
}

const mapDispatchToProps = {
	logoutUser,
	getRoles,
	getMemberById,
	getEntity,
	selectBrand,
	getBrand,
	checkSpBGprocesses,
	getMRAccounts,
	selectAccount,
	checkFreezeProcess,
	getMrData,
	getImarScenarioList,
	getSelScenario,
	updateScenarioLable
};

const mapStateToProps = (state) => {
	return {
		app: state.appConfig.app,
		login: state.login,
		user: state.user,
		navigation: state.navigation,
		roles: state.appConfig.roles,
		rolesLoading: state.appConfig.rolesLoading,
		member: state.appConfig.member,
		memberLoading: state.appConfig.memberLoading,
		entity: state.appConfig.entity ? state.appConfig.entity : {},
		brands: Object.keys(state.app.brands).length === 0 && state.app.brands.constructor === Object ? [] : state.app.brands,
		sptprData: state.tprReducer,
		controlData: state.dataModelReducer.controlData,
		omniAccounts: state.mrReducer && state.mrReducer.data && state.mrReducer.data['GET_omniAccounts']
	}
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar)));
