export function promoFormulas(key, uom) {
    switch (key) {
        //Promo Calendar
        case 'retailQtyForecast':
            return `
    1. FUTURE MONTH: Brand Retailing (*10 ^ 7) from Retailing Offtake Planning Tab* TS_SKU Retailing Salience from TS SKU Salience Tab * VSOD Retailing value as per Brand's event type in Retailing Brand VSOD Tab/ RLP.
    2. ACTUALIZED MONTH: Qty from FY Actual Retailing tab (at TS_SKU Level) * VSOD Retailing value as per Brand's event type in Retailing Brand VSOD Tab.
`;
            break;

        case 'retailingValue':
            return `
            1. FUTURE MONTH: Retailing Qty Forecast (Pieces)* RLP.
            2. ACTUALIZED MONTH: Value from FY Actual Retailing tab (at TS_SKU Level) * VSOD Retailing value as per Brand's event type in Retailing Brand VSOD Tab.
        `;
            break;

        case 'offtakeForecastPieces':
            return `
           ALL MONTH: Brand Offtake* (10 ^ 7) from Retailing Offtake Planning Tab * TS_SKU Offtake Salience from TS SKU Salience Tab * VSOD Offtake value as per Brand's event type in Offtake Brand VSOD Tab / RLP.
              
        `;
            break;

        case 'offtakeActualPieces':
            return `
           1. ACTUALIZED MONTH: Offtake Qty actuals (at TS_SKU level) from SKU Level Offtake tab * VSOD Offtake value as per Brand's event type in Offtake Brand VSOD Tab.
           2. FUTURE MONTH: Offtake Forecast (Pieces).
        `;
            break;

        case 'offtakeActualINR':
            return `
           ALL MONTH: [Offtake Actuals (Pieces)]* [Retailing Forecast Value (Pieces *RLP)]/ [Retailing Qty Forecast (Pieces)].
        `;
            break;

        case 'sdSpend':
            return `
           [Promo Depth % on RLP * Offtake Actuals (Pieces) *RLP] / (1+ Brand GST%).
        `;
            break;

        case 'sdSpendFinal':
            return `
           1. FUTURE MONTH = SD Spend.
           2. ACTUALIZED MONTH = Promo value (Ex. Of GST) (If Claims/ZEUS input is there) OR Populate Value as per SD Spend.
        `;
            break;

        case 'claimAmount':
            return `
           From Claims Tab:- Sum of all Subbrand forrm of the TS_SKU.
        `;
            break;

        case 'asp':
            return `
           From Claims Tab:- (ASP * Min Qty) / Min Qty of all Subbrand forrm of the TS_SKU.
        `;
            break;

        case 'minQty':
            return `
           From Claims Tab:- Sum of all Subbrand forrm of the TS_SKU.
        `;
            break;

        case 'minPromo%':
            return `
           From Claims Tab:- [(Sum of Claim Amount)/ Min Qty] *ASP of Subbrand forrm of the TS_SKU.
        `;
            break;

        case 'promoValue':
            return `
           From Claims Tab:- Sum of all Subbrand forrm of the TS_SKU.
        `;

            break;
            case 'spendVSpromo':
                return `
               Sdspend - PromoValue.
            `;
            
                break;

        case 'hitRate':
            return `
           Offtake Actuals (Pieces) /Offtake Forecast (Pieces).
        `;
            break;
            //Engine Earnings
        case 'Retailing (INR Lakh)':
            return `
            Actualized Months: FY Actual Retailing tab Value Data summed at Sub-brand form level 
            Future Months: Promo Calendar tab Retailing Value INR summed at TS_SKU level (for all event types).
        `;
            break;

        case 'KBD1 (INR Lakh)':
            return `
            1.Actualized Months: FY Actual Retailing tab Value Data summed at Sub-brand form level 
            Future Months: Promo Calendar tab Retailing Value INR summed at TS_SKU level (for all event types).
            • Multiply by ITT % of KBD1 
            o In ITT Master tab- check if ITT is available in TS_SKU Level- if not, then Brand Form Level, if not then Brand level  accordingly pick that % & multiply .
        `;
            break;
        case 'KBD2 (INR Lakh)':
            return `
            1.Actualized Months: FY Actual Retailing tab Value Data summed at Sub-brand form level 
            Future Months: Promo Calendar tab Retailing Value INR summed at TS_SKU level (for all event types).
            • Multiply by ITT % of KBD2 
            o In ITT Master tab- check if ITT is available in TS_SKU Level- if not, then Brand Form Level, if not then Brand level  accordingly pick that % & multiply.
        `;
            break;
        case 'CTLSIB (INR Lakh)':
            return `
            1.Actualized Months: FY Actual Retailing tab Value Data summed at Sub-brand form level 
            Future Months: Promo Calendar tab Retailing Value INR summed at TS_SKU level (for all event types).
            • Multiply by ITT % of CTLSIB 
            • In ITT Master tab- check if ITT is available in TS_SKU Level- if not, then Brand Form Level, if not then Brand level -> accordingly pick that % & multiply.
            
        `;
            break;
            
            //TS Brand Summary (Sheet 1)
        case 'Retailing':
            return `
            Actualized Months: FY Actual Retailing tab Value Data summed at Sub-brand form level 
            Forecast Months: Promo Calendar tab Retailing Value INR summed at TS Brand level (for all event types).   
        `;
            break;
        case 'Retailing IYA':
            return `
            1. Actualized Months: FY Actual Retailing tab Value Data summed at Sub-brand form level 
            Forecast Months: Promo Calendar tab Retailing Value INR summed at TS Brand level (for all event types)
            2. Take PY retailing from PY Actual Retailing tab (summed at Quarterly or FY level for respective views)
            Divide 1 by 2.
        `;
            break;
            case 'KBD 1 Earnings- Total':
                return !uom ?`
                Addition of KBD 1 Earnings- ITT & KBD 1 Earnings- TFI.
            `: `
            KBD 1 Earnings- Total INR Lakh/ Total Retailing 
            `;
                break;
                case 'KBD 1 Earnings- ITT':
                    return !uom ? `
                    1.Actualized Months: FY Actual Retailing tab Value Data summed at Sub-brand form level 
                    Future Months: Promo Calendar tab Retailing Value INR summed at TS_SKU level (for all event types).
                    • Above is multiplied by ITT % of KBD1 
                    o In ITT Master tab- check if ITT is available in TS_SKU Level- if not, then Brand Form Level, if not then Brand level  accordingly pick that % & multiply
                    o Then above is summed at TS_Brand Level
                    (You can verify it from values at Engine Earnings KBD1 Earnings). 
                ` : `
                KBD 1 Earnings- ITT INR Lakh/ Retailing INR Lakh
                `;
                    break;
                    case 'KBD 1 Earnings- TFI':
                        return !uom ?`
                        In TFI Earning tab Sheet,
                        • Filter at Type = KBD1 & at Earnings on as Brandform 
                        Sum above.
                    `: `
                    KBD 1 Earnings- TFI INR Lakh/ Retailing INR Lakh
                    `;
                    case 'KBD 2 Earnings- Total':
                    return !uom ?`
                    Addition of KBD 2 Earnings- ITT & KBD 2 Earnings- TFI.
                `: `
                KBD 2 Earnings- Total INR Lakh/ Total Retailing 
                `;
                    break;
                    case 'KBD 2 Earnings- ITT':
                    return !uom ? `
                    1.Actualized Months: FY Actual Retailing tab Value Data summed at Sub-brand form level 
                    Future Months: Promo Calendar tab Retailing Value INR summed at TS_SKU level (for all event types).
                    • Above is multiplied by ITT % of KBD2 
                    o In ITT Master tab- check if ITT is available in TS_SKU Level- if not, then Brand Form Level, if not then Brand level  accordingly pick that % & multiply
                    o Then above is summed at TS_Brand Level
                    (You can verify it from values at Engine Earnings KBD2 Earnings).
                `: `
                KBD 2 Earnings- ITT INR Lakh/ Retailing INR Lakh
                `;
                    break;
                    case 'KBD 2 Earnings- TFI':
                    return !uom ?`
                    In TFI Earning tab Sheet,
                    • Filter at Type = KBD2 & at Earnings on as TS Brand
                    Sum above.
                `: `
                KBD 2 Earnings- TFI INR Lakh/ Retailing INR Lakh
                `;
                    break;
                    case 'CTLSIB Earnings':
                    return !uom?`
                    1.Actualized Months: FY Actual Retailing tab Value Data summed at Sub-brand form level 
                     Future Months: Promo Calendar tab Retailing Value INR summed at TS_SKU level (for all event types).
                    •	Above is multiplied by ITT % of CTLSIB
                    o	In ITT Master tab - check if ITT is available in TS_SKU Level- if not, then Brand Form Level, if not then Brand level -> accordingly pick that % & multiply
                    o	Then above is summed at TS_Brand Level
                    (You can verify it from values at Engine Earnings CTLSIB Earnings).
                `: `
                CTLSIB Earnings INR Lakh/ Retailing INR Lakh
                `;
                    break;
                    case 'KBD Spends':
                        return !uom?`
                        •	KBD 1 Spends + KBD 2 Spends Total + CTLSIB Spends.
                    `: `
                    KBD Spends INR Lakh/ Retailing INR Lakh
                    `;
                        break;
                        case 'KBD 1 Spends':
                        return !uom?`
                        From Promo Calendar tab, pick column- SD Spend (Final- Actual/Forecast) & sum at at TS_Brand Level.
                    `: `
                    KBD 1 Spends INR Lakh/ Retailing INR Lakh
                    `;
                        break;
                        case 'KBD 2 Spends Total':
                            return !uom?`
                            Summation of KBD 2 Spends Contracted & KBD 2 Spends Absolute.
                        `: `
                        KBD 2 Spends Total INR Lakh/ Total Retailing
                        `;
                            break;
                            case 'KBD 2 Spends Contracted':
                            return !uom?`
                            From Spends Planning tab get Contracted % spend & Multiply with Retailing.
                        `: `
                        KBD 2 Spends Absolute INR Lakh/ Retailing INR Lakh
                        `;
                            break;
                            case 'KBD 2 Spends Absolute':
                            return !uom?`
                            From Spends Planning tab, filter at TS_Brand and sum.
                        `: `
                        KBD 2 Spends Absolute INR Lakh/ Retailing INR Lakh
                        `;
                            break;
                            case 'CTLSIB Spends':
                                return !uom?`
                                • From Spends Planning tab get CTLSIB % spend & Multiply with Retailing.
                            `: `
                            CTLSIB Spends INR Lakh/ Retailing INR Lakh
                            `;
                                break;
                                case 'Reclass':
                                return `
                                Subtract KBD 2 Earnings (ITT) (INR) - KBD2 Spend Contracted (INR).
                            `;
                                break;
                                case 'Offtake':
                                return `
                                From FY Offtake tab Filter TS Brand - Month -> then Sum Col. Offtake INR.
                            `;
                                break;
                                case 'Media':
                                return `
                                From Media Calendar tab, Filter TS_Brand & Sum cost
                            `;
                                break;
                                case 'eMedia ROI':
                                return `
                                1.Actualized Months: FY Actual Retailing tab Value Data summed at Sub-brand form level 
                                Future Months: Promo Calendar tab Retailing Value INR summed at TS_SKU level (for all event types).
                                2. From Media Calendar tab, Filter TS_Brand & Sum cost.
                                Divide 1 by 2.
                            `;
                                break;
                                //Brandform Summary

                                case 'Retailing':
                                return `
                                Actualized Months:
                                • FY Actual Retailing tab Value Data summed at Brandform level 
                                Forecast Months:
                                • Promo Calendar tab Retailing Value INR summed at Brandform level (for all event types). 

                            `;

                            case 'Retailing IYA':
                                return `
                                1.Actualized Months: FY Actual Retailing tab Value Data summed at Sub-brand form level 
                                Future Months: Promo Calendar tab Retailing Value INR summed at TS_SKU level (for all event types).
                                2. Take PY retailing from PY Actual Retailing tab (summed at Quarterly or FY level for respective views) 
                                Divide 1 by 2.


                            `;
                            case 'KBD 1 Earnings- Total':
                                return `
                                Addition of KBD 1 Earnings- ITT & KBD 1 Earnings- TFI. 

                            `;
                            case 'KBD 1 Earnings- ITT':
                                return `
                                1.Actualized Months: FY Actual Retailing tab Value Data summed at Sub-brand form level 
                                Future Months: Promo Calendar tab Retailing Value INR summed at TS_SKU level (for all event types).
                                • Above is multiplied by ITT % of KBD1 
                                o In ITT Master tab- check if ITT is available in TS_SKU Level- if not, then Brand Form Level, if not then Brand level  accordingly pick that % & multiply
                                o Then above is summed at Brandform Level
                                (You can verify it from values at Engine Earnings KBD1 Earnings).
                            `;
                            case 'KBD 1 Earnings- TFI':
                                return `
                                In TFI Earning tab Sheet,
                                • Filter at Type = KBD1 & at Earnings on as Brandform 
                                Sum above.
 

                            `;
                            case 'KBD 2 Earnings- Total':
                                return `
                                Addition of KBD 2 Earnings- ITT & KBD 2 Earnings- TFI. 

                            `;
                            case 'KBD 2 Earnings- ITT':
                                return `
                                1.Actualized Months: FY Actual Retailing tab Value Data summed at Sub-brand form level 
                                Future Months: Promo Calendar tab Retailing Value INR summed at TS_SKU level (for all event types).
                                • Above is multiplied by ITT % of KBD2
                                o	In ITT Master tab- check if ITT is available in Brandform Level- if not, then Brand Form Level, if not then Brand level  accordingly pick that % & multiply
                                o	Then above is summed at Brandform Level
                                (You can verify it from values at Engine Earnings KBD2 Earnings).

 

                            `;
                            case 'KBD 2 Earnings- TFI':
                                return `
                                In TFI Earning tab Sheet,
                                •	Filter at Type = KBD2 & at Earnings on as Brandoform 
                                Sum above.
 

                            `;
                            case 'CTLSIB Earnings':
                                return `
                                1.Actualized Months: FY Actual Retailing tab Value Data summed at Sub-brand form level 
                                Future Months: Promo Calendar tab Retailing Value INR summed at TS_SKU level (for all event types).
                                •	Above is multiplied by ITT % of CTLSIB
                                o	In ITT Master tab - check if ITT is available in Brandform Level- if not, then Brand Form Level, if not then Brand level  accordingly pick that % & multiply
                                o	Then above is summed at Brandform Level
                                (You can verify it from values at Engine Earnings CTLSIB Earnings).
 

                            `;
                            case 'KBD Spends':
                                return `
                                KBD 1 Spends + KBD 2 Spends Total + CTLSIB Spends. 

                            `;
                            case 'KBD 1 Spends':
                                return `
                                From Promo Calendar tab, pick column- SD Spend (Final- Actual/Forecast) & sum at at T Brandform Level. 

                            `;
                            case 'KBD 2 Spends Total':
                                return `
                                Summation of KBD 2 Spends Contracted & KBD 2 Spends Absolute. 

                            `;
                            case 'KBD 2 Spends Contracted':
                                return `
                                From Spends Planning tab get Contracted % spend & Multiply with Retailing. 

                            `;
                            case 'KBD 2 Spends Absolute':
                                return `
                                From Spends Planning tab, filter at Brandform and sum . 

                            `;
                            case 'CTLSIB Spends':
                                return `
                                From Spends Planning tab get CTLSIB % spend & Multiply with Retailing. 

                            `;
                            case 'Reclass':
                                return `
                                Subtract KBD 2 Earnings (ITT) (INR) - KBD2 Spend Contracted (INR). 

                            `;
                            case 'Offtake':
                                return `
                                From FY Offtake tab Filter Brandform - Month -> then Sum Col. Offtake INR. 

                            `;
                            case 'Media':
                                return `
                                From Media Calendar tab, Filter Brandform & Sum cost. 

                            `;
                            case 'eMedia ROI':
                                return `
                                1.Actualized Months: FY Actual Retailing tab Value Data summed at Sub-brand form level 
                                Future Months: Promo Calendar tab Retailing Value INR summed at TS_SKU level (for all event types).
                                2.From Media Calendar tab, Filter TS_Brand & Sum cost.
                                Divide 1 by 2.
                            `;

                            //Executive Summary (Talksheet/Summary)
                            case 'Retailing':
                                return `
                                Actualized Months:
                                •	FY Actual Retailing tab Value Data summed at Brand level 
                                Forecast Months:
                            •	Promo Calendar tab- Retailing Value INR summed at Brand level (for all event types)

                            `;
                            case 'SD':
                                return `
                                1.	From Promo Calendar tab, sum up SD Spend (Final- Actual/Forecast) at Brand Level
                                2.	From Spend Planning tab, sum up KBD2 Absolute at Brand Level
                                3.	From Spends Planning tab, take KBD Contracted %
                                a.	Under this Brand check how many TS Brand
                                b.	For each TS Brand
                                i.	Actualized month: FY Retailing tab, Sum full retailing at TS Brand Level
                                1.	Multiply this with Contracted %
                                ii.	Future month: Promo Calendar tab, Sum full retailing at TS Brand level
                                1.	Multiply this with Contracted %
                                c.	Add above 2

                                4.	From Spends Planning tab, take CTLSIB %
                                a.	Under this Brand check how many TS Brand
                                b.	For each TS Brand
                                i.	Actualized month: FY Retailing tab, Sum full retailing at TS Brand Level
                                1.	Multiply this with Contracted %
                                ii.	Future month: Promo Calendar tab, Sum full retailing at TS Brand level
                                1.	Multiply this with Contracted %
                                c.	Add above 2

                                Add 1+2+3+4


                            `;
                            case 'Offtake':
                                return `
                                From FY Offtake tab Filter Brand - Month -> then Sum Col Offtake INR
                            `;
                            case 'eMedia':
                                return `
                                From Media Calendar tab Filter Brand - Month -> then Sum 
                            `;
                            case 'SD + eMedia':
                                return `
                                eMedia + SD.
                            `;
                            
                            case 'KBD 1 Spends':
                                return `
                                From Promo Calendar tab, pick column- SD Spend (Final- Actual/Forecast) & sum at at T Brandform Level.
                            `;
                            case 'KBD 2 Spends Total':
                                return `
                                Summation of KBD 2 Spends Contracted & KBD 2 Spends Absolute.
                            `;
                            case 'CTLSIB Spends':
                                return `
                                From Spends Planning tab get CTLSIB % spend & Multiply with Retailing.
                            `;
                            case 'Retailing IYA':
                                return `
                                1.	Actualized Months:
                                •	FY Actual Retailing tab Value Data summed at Brand level 
                                Forecast Months:
                                •	Promo Calendar tab- Retailing Value INR summed at Brand level (for all event types)
                                
                                2.	Take PY retailing from PY Actual Retailing tab (summed at Quarterly or FY level for respective views) 
                                Divide 1 by 2.

                            `;
                            case 'Sde':
                                return `
                                1.	From PY Metrics tab, pick column SD and sum 
                                2.	From PY Actuals Retailing tab, pick retailing & sum
                                Get SD Spends % RLP for Past year by dividing 1 by 2
                                Above value divided by above SD Spends %RLP (Below one)
.
                            `;
                            case 'SD% RLP':
                                return `
                                SD (below) Divided by Above Retailing value .
                            `;
                            case 'eMedia %RLP':
                                return `
                                eMedia INR lakh/ Retailing INR Lakh 
                            `;
                            case 'eMedia Effectiveness':
                                return `
                                •	From PY Metrics tab, pick eMedia % RLP 
                                •	Divide above by eMedia % RLP

                            `;
                            case 'eMedia %Offtake':
                                return `
                                eMedia/ Offtake
                            `;
                            case '(SD + eMedia) %RLP':
                                return `
                                eMedia + SD /Retailing 
                            `;
                            case 'PY(SD + eMedia) %RLP':
                                return `
                                1.	From PY Metrics tab, pick SD & media & sum
                                2.	From PY Retailing tab  sum retailing at Brand Level
                                Divide 1 by 2
                                
                            `;
                            case 'KBD 1 Spends %RLP':
                                return `
                                KBD 1 Spends (INR) / Retailing (INR).
                            `;
                            case 'Investment effectiveness':
                                return `
                                [PY (SD + eMedia) % RLP] / (SD + eMedia) % RLP.
                            `;
                            case 'KBD 2 Spends Total %RLP':
                                return `
                                KBD 2 Spends Total(INR) / Retailing (INR).
                            `;
                            case 'CTLSIB Spends %RLP':
                                return `
                                CTLSIB Spends Total (INR) / Retailing (INR).
                            `;
                            
                           
    }
}
export function accountFormulas(key, isForcast) {
    switch (key) {
        //Account Summary (Top Sheet)
        case 'Retailing':
            return `
            •	From Executive Summary tab, sum retailing for the account.
        `;
        case 'Retailing IYA':
            return `
            •	Above retailing divided by PY Retailing for the account.
        `;
        case 'SD Spends %RLP':
            return `
            •	From Executive Summary tab, sum SD (INR) column & divided by above Retailing.
        `;
        case 'SDe':
            return `
            1.	From PY Metrics tab, pick column SD & sum 
            2.	From PY FY Actuals tab, pick retailing & sum
            o	Get SD Spends %RLP for Past year by dividing 1 by 2
            •	Above value divided by above SD Spends %RLP.

        `;
        case 'SD Earnings %RLP':
            return `
            1.	For that Brand check ITT at what level
            a.	If SKU Level then pick % & multiply by retailing of SKU
            i.	Actualized - FY Actual Retailing - summed at TS_SKU Level
            ii.	Forecast- Promo Calnedar- summed for all event types of the TS_SKU
            b.	If Brandform Level then pick % & multiply by retailing of SKU
            i.	Actualized - FY Actual Retailing - summed at Brandform Level
            ii.	Forecast- Promo Calnedar- summed for all event types of the TS_SKU- which is summed at branform level
            c.	If Brand Level then pick % & multiply by retailing of SKU
            i.	Actualized - FY Actual Retailing - summed at Brand Level
            ii.	Forecast- Promo Calnedar- summed for all event types of the TS_SKU- which is summed at bran level.

        `;
        case 'eMedia':
            return `
            Go to Media Calendar and sum all cost.
        `;
        case 'eMedia %RLP':
            return `
            eMedia INR lakh/ Retailing INR Lakh .
        `;
        case 'eMedia Effectiveness':
            return `
            1.	From PY Metrics tab, sum eMedia at account level
            2.	From PY Retailing, sum up at account level
            a.	Then divide 2 by 1 to get PY (eMedia %RLP)
            3.	Above divided by  eMedia %RLP.

        `;
        case 'Earning':
            return `
            KBD 1 Earnings + KBD 2 Earnings + CTLSIB Earnings

        `;
        case 'KBD 1 Earnings':
            return `
            From TS Brand Summary tab, filter the desired Brand and Metric as INR -> Sum the KBD 1 Earnings ITT + KBD 1 Earnings TFI

        `;
        case 'KBD 2 Earnings':
            return `
            From TS Brand Summary tab, filter the desired Brand and Metric as INR -> Sum the KBD 2 Earnings ITT + KBD 2 Earnings TFI

        `;
        case 'CTLSIB Earnings':
            return `
            From TS Brand Summary tab, filter the desired Brand and Metric as INR -> Sum the KBD 1 Earnings ITT + KBD 1 Earnings TFI

        `;
        case 'Spending':
            return `
            KBD 1 Spends + KBD 2 Spends + CTLSIB Spends

        `;
        case 'KBD 1 Spends':
            return `
            From TS Brand Summary tab, filter the desired Brand and Metric as INR -> Sum the KBD 1 Spends

        `;
        case 'KBD 2 Spends':
            return `
            From TS Brand Summary tab, filter the desired Brand and Metric as INR -> Sum the KBD 2 Spends Total

        `;
        case 'CTLSIB Spends':
            return `
            From TS Brand Summary tab, filter the desired Brand and Metric as INR -> Sum the CTLSIB Spends

        `;
        case '(KBD1 + KBD2) difference':
            return `
            (KBD 1 Earnings + KBD 2 Earning + CTLSIB Earning) - (KBD 1 Spends + KBD 2 Spends + CTLSIB Spends)

        `;
        
        case 'Total Difference':
            return `
            (KBD 1 Earnings + KBD 2 Earning) - (KBD 1 Spends + KBD 2 Spends

        `;
        case 'KBD 1 Difference':
            return `
            (KBD 1 Earnings - KBD 1 Spends)

        `;
        case 'KBD 2 Difference':
            return `
            (KBD 2 Earning - KBD 2 Spends)

        `;
        case 'CTLSIB Difference':
            return `
            (CTLSIB Earning - CTLSIB Spends)

        `;

    }
}