import { AppService } from 'modules/api/index';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMonth } from 'utility/utils';
import { getFilteredChannelList } from '../../../../utility/utils';
import GlobalLinks from '../GlobalLinks/index';
import { getCurrentFFForPayload, MonthsNumberMapping } from '../spendplanUtils';
import { ChannelFilterWithTableValues, customChannelForITT, pagesWithBrandFormFilter, pagesWithChannelFilter, pagesWithMetricFilter, pagesWithMonthFilter, pagesWithPlanTypeFilter, pagesWithQtrFilter, pagesWithUoMFilter } from './FilterWorksheet';
import Worksheet from './Worksheet';
import { setupPagesWithTotalValues } from './Worksheet/sheetTableHelper';
import axios from 'axios';
import ConfirmDialog from './ConfirmDialog';
import { makeStyles } from '@material-ui/core';

export const brandsFreePages = ['corpbud', 'mrecomm', 'accrualshc', 'itt'];
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const useStyles = makeStyles(theme => ({
	confirmationDialoge: {
		'& .MuiDialog-paperWidthSm': {
			height: '320px',
			width: '600px'
		}
	},
	para: {
		fontSize: '28px !important',
		fontWeight: 600,
		color: '#000'
	}
}))
const SpendPlan = props => {
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(props.page.name === "Channel Retailing Salience" ? 38 : 100)
    const [conditions, setConditions] = useState([])
    const [state, setState] = useState({ data: [], paste: false, loading: false })
    const { tprReducer } = props
    const modalData = props.modalData ? props.modalData : ''
    const { selectBrand, selectScenario } = tprReducer
    const [brand, setBrand] = useState(selectBrand)
    const [readOnly, setReadOnly] = useState(false)
    const [edited, setEdited] = useState(false)
    const [alertMessageText, setAlertMessage] = useState('');
    const classes = useStyles();

    const handleChangePage = (event, page) => {
        setPage(page);
        fetchData(conditions, page)
    };

    const handleChangeRowsPerPage = event => {
        setPage(0);
        setRowsPerPage(event.target.value)
        fetchData(conditions, 0, event.target.value)
    };

    const getApiDataFromDataUrl = (url) => {
        setState({ ...state, loading: true })
        AppService.getApiDataFromDataUrl(url, props.match.params.appid)
            .then(res => {
                setState({ ...state, data: res.data, count: res.total, totalSum: res.totalSum, loading: false })
            }).catch(()=>{
                setState({ ...state, loading: false })
            })
    }
    const isReadOnly = (FF, FY) => {
        let currentMonth = brand ? getMonth(brand.CurrentFF, 'monthNumber') : null,
            currentYear = brand ? Number(brand.FinancialYear) : null;

        if (currentMonth != null && currentYear != null && typeof FF != "undefined" && typeof FY != "undefined") {
            if (Number(FY) < currentYear || Number(FF) < currentMonth)
                return true;
        } else {
            return false;
        }
    }

    const fetchData = (conditions, p, rpp) => {
        console.log("brand xxx 111", conditions, p, rpp);
        let conds = [{
            "selectedConditionDataElement": props?.type === 'f1inputs' ? 'tsBrandCategory' : 'brand',
            "selectedConditionOperator": "matches",
            "value": "" + brand?.BrandName,
            "selectedConditionClause": "and"
        }, {
            "selectedConditionDataElement": 'fiscalYear',
            "selectedConditionOperator": "matches",
            "value": "" + brand?.FinancialYear,
            "selectedConditionClause": "and"
        }, {
            "selectedConditionDataElement": 'currentFF',
            "selectedConditionOperator": "matches",
            "value": "" + MonthsNumberMapping[brand?.CurrentFF],
            "selectedConditionClause": "and"
        }, {
            "selectedConditionDataElement": 'scenario',
            "selectedConditionOperator": "is_match",
            "value": "" + selectScenario || '01',
            "selectedConditionClause": "and"
        }]
        const params = props.match.params,
            urlSearchParams = new URLSearchParams(window.location.search);
        let payload = {}, readonly = false, isModal = false;

        if (modalData && modalData.brand && modalData.month && modalData.year) {
            payload = {
                brand: modalData.brand, year: modalData.year, currentFF: getCurrentFFForPayload(modalData?.month), scenario: '01'
            };
            isModal = true;
        } else if (params.brand && params.currentFF && params.year) {
            payload = {
                brand: params.brand, currentFF: getCurrentFFForPayload(params?.currentFF), year: params.year, scenario: '01'
            };
            readonly = true;
        } else if (urlSearchParams.get('brand') && urlSearchParams.get('year') && urlSearchParams.get('month')) {
            payload = {
                brand: urlSearchParams.get('brand'), currentFF: getCurrentFFForPayload(urlSearchParams.get('month')), year: urlSearchParams.get('year')
            };
            readonly = true;
        }
        if ((readonly && isReadOnly(payload?.currentFF, payload?.year) && !readOnly) || isModal) {
            setReadOnly(true);
        } else if (readOnly) {
            setReadOnly(false);
        }

        if (conditions)
            conds = [...conds, ...conditions]
        let selectedDataElement = []
        let targetURL = API_BASE_URL + "/dataconnect/table/" + props?.page?.pageLevelDataModel;
        const reducer = (accumulator, currentValue) => accumulator + currentValue.value + ',';
        targetURL += `?type=list&`;
        targetURL += '&page=' + (p || page) + '&rowsPerPage=' + (rpp || rowsPerPage) + "&";
        if (payload.brand) {
            targetURL += `brand=${payload.brand}&`;
        }
        if (payload.year) {
            targetURL += `fiscalYear=${payload.year}&`;
        }
        if (payload.currentFF) {
            targetURL += `currentFF=${getCurrentFFForPayload(payload.currentFF)}&`;
        }
        if (brandsFreePages.includes(props.type)) {
            conds.shift();
        }
        if (setupPagesWithTotalValues[props.type]) {
            if ('bopvolumes' === props.type && conditions?.length) {
                targetURL += `sum=filter&`;
            } else {
                targetURL += `sum=total&`;
            }
        }
        targetURL += `sortOrder=${encodeURIComponent(JSON.stringify({ _id: 1 }))}&`;

        targetURL += (conds ? "conditions=" + encodeURIComponent(JSON.stringify(conds)) + "&" : '')
        targetURL += selectedDataElement.reduce(reducer, '&fields=');
        console.log('targetURL', targetURL);
        getApiDataFromDataUrl(targetURL)
    }

    useEffect(() => {
        if (selectBrand && typeof selectBrand === 'object' && selectBrand !== null && Object.keys(selectBrand).length > 0) {
            setBrand(selectBrand);
        }
    }, [selectBrand])

    useEffect(() => {
        if (!props.isPopup) {
            fetchData(conditions)
        }
    }, [brand, selectScenario])

    const handleFileSave = () => {
        fetchData()
    }

    const handlePasteClick = () => {
        setState({ ...state, paste: true })
    }

    const closePasteClick = () => {
        setState({ ...state, paste: false })
    }

    const handleFilterChange = (filter, allFilters) => {
        setState({ ...state, data: [], totalSum: null, })
        let conditions = [];

        const isChannelFilterRequired = ()=> {
            let allChannels = ChannelFilterWithTableValues.includes(props.type)?allFilters['channel']:getFilteredChannelList(tprReducer.filterData?.channels).map(i => i.channel)
            return allChannels.length !== filter.channel?.length;
        }
        if (pagesWithBrandFormFilter.includes(props.type) && filter.brandform?.length && (filter.brandform?.length !== tprReducer.filterData?.brands?.length)) {
            conditions.push({
                "selectedConditionDataElement": 'brandform',
                "selectedConditionOperator": "in",
                "value": filter.brandform,
                "selectedConditionClause": "and"
            })
        }
        if ([...pagesWithChannelFilter, 'corpbud'].includes(props.type) && filter.channel?.length && isChannelFilterRequired()) {
            conditions.push({
                "selectedConditionDataElement": props.type ==='cts'?'channels':'channel',
                "selectedConditionOperator": "in",
                "value": filter.channel,
                "selectedConditionClause": "and"
            })
        }
        if ((props.type === 'itt' || props.type === 'mrecomm') && filter.subChannel?.length && (customChannelForITT.length !== filter.subChannel?.length)) {
            conditions.push({
                "selectedConditionDataElement": 'subChannel',
                "selectedConditionOperator": "in",
                "value": filter.subChannel,
                "selectedConditionClause": "and"
            })
        }
        if (pagesWithMonthFilter.includes(props.type) && filter.month?.length && (13 !== filter.month?.length)) {
            conditions.push({
                "selectedConditionDataElement": 'month',
                "selectedConditionOperator": "in",
                "value": filter.month,
                "selectedConditionClause": "and"
            })
        }
        if (pagesWithQtrFilter.includes(props.type) && filter.qtr?.length && (5 !== filter.qtr?.length)) {
            conditions.push({
                "selectedConditionDataElement": 'qtr',
                "selectedConditionOperator": "in",
                "value": filter.qtr,
                "selectedConditionClause": "and"
            })
        }
        if (pagesWithMetricFilter.includes(props.type) && filter.metric?.length && (allFilters['metrics'].length !== filter.metric?.length)) {
            conditions.push({
                "selectedConditionDataElement": 'metrics',
                "selectedConditionOperator": "in",
                "value": filter.metric,
                "selectedConditionClause": "and"
            })
        }
        if (pagesWithUoMFilter.includes(props.type) && filter.uom?.length && (allFilters['uoM'].length !== filter.uom?.length)) {
            conditions.push({
                "selectedConditionDataElement": 'uoM',
                "selectedConditionOperator": "in",
                "value": filter.uom,
                "selectedConditionClause": "and"
            })
        }
        if (pagesWithPlanTypeFilter.includes(props.type) && filter.planType?.length && (allFilters['planType'].length !== filter.planType?.length)) {
            conditions.push({
                "selectedConditionDataElement": 'planType',
                "selectedConditionOperator": "in",
                "value": filter.planType,
                "selectedConditionClause": "and"
            })
        }
        if (props.type === 'f1inputs' && filter.ff?.length && (allFilters['ff'].length !== filter.ff?.length)) {
            conditions.push({
                "selectedConditionDataElement": 'ff',
                "selectedConditionOperator": "in",
                "value": filter.ff,
                "selectedConditionClause": "and"
            })
        }
        if (props.type === 'accrualshc' && filter.spendPool?.length && (allFilters['spendPool'].length !== filter.spendPool?.length)) {
            conditions.push({
                "selectedConditionDataElement": 'spendPool',
                "selectedConditionOperator": "in",
                "value": filter.spendPool,
                "selectedConditionClause": "and"
            })
        }
        if (props.type === 'accrualshc' && filter.spendBucket?.length && (allFilters['spendBucket'].length !== filter.spendBucket?.length)) {
            conditions.push({
                "selectedConditionDataElement": 'spendBucket',
                "selectedConditionOperator": "in",
                "value": filter.spendBucket,
                "selectedConditionClause": "and"
            })
        }
        if (props.type === 'tdc' && filter.brandCategory?.length && (allFilters['brandCategory'].length !== filter.brandCategory?.length)) {
            conditions.push({
                "selectedConditionDataElement": 'brandCategory',
                "selectedConditionOperator": "in",
                "value": filter.brandCategory,
                "selectedConditionClause": "and"
            })
        }
        if (props.type === 'tdc' && filter.tsBrandforms?.length && (allFilters['tsBrandforms']?.length !== filter.tsBrandforms?.length)) {
            conditions.push({
                "selectedConditionDataElement": 'tsBrandforms',
                "selectedConditionOperator": "in",
                "value": filter.tsBrandforms,
                "selectedConditionClause": "and"
            })
        }
        setPage(0);
        let rpp = props.page.name === "Channel Retailing Salience" ? 38 : 50;
        setRowsPerPage(rpp)
        setConditions(conditions)
        fetchData(conditions, 0, rpp)
    }

    const setEditMode = (edit) => {
        setEdited(edit)
    }

    const onSalienceForecast = (months, brandform) => {
        let m = '' + getMonth(brand.CurrentFF, 'monthNumber');
        m = m.length === 1 ? '0' + m : m;
        let payload = {
            brand: brand.BrandName,
            fiscalYear : brand.FinancialYear,
            currentFF: m,
            ...months,
        }
        
        if(brandform && brandform.length){
            payload['brandform'] = brandform
        }

        console.log("it is payload ===", payload)
        axios({
            url: API_BASE_URL+ '/setupdata/salience-forecast',
            method: 'POST',
            data: payload,
            headers: {
              'Content-Type': 'application/json',
              appId: props.match.params.appid,
            },
          })
            .then((response) => {
                let msg = response.data?.result?.data?.message || 'Updated successfully';
                setAlertMessage({isOpen: true, data: { title: 'Salience Forecast - Save', p1: msg }})
                fetchData(conditions)
            })
            .catch((error) => {
                let msg = error.data?.message || 'Unable to add Salience forecast';
                setAlertMessage({isOpen: true, data: { title: 'Salience Forecast - Save', p1: msg }})
            });
    }

    return <>
        {!props?.view && <GlobalLinks {...props} edited={edited} />}
        <div style={!props?.view ? { marginTop: -10 } : {}}>
            <Worksheet state={state} handleChangePage={handleChangePage} {...props} fetchData={fetchData} conditions={conditions}
                handleFilterChange={handleFilterChange} pagination={{ page, rowsPerPage }} isPopup={props?.isPopup}
                handleChangeRowsPerPage={handleChangeRowsPerPage} closePasteClick={closePasteClick} view={readOnly || props?.view}
                applySalienceForecast={onSalienceForecast}
                handleFileSave={handleFileSave} handlePasteClick={handlePasteClick} setEditMode={setEditMode} />
        </div>
        <ConfirmDialog open={alertMessageText?.isOpen} handleClose={()=> setAlertMessage({isOpen: false, data: {p1: '', title: ''}})}
                alertMessageText={alertMessageText?.data} classes={classes} />
    </>
}

const mapDispatchToProps = {
}

const mapStateToProps = ({ user, navigation, tprReducer }) => {
    return {
        user,
        navigation,
        tprReducer
    }
}

export default hot(withRouter(connect(mapStateToProps, mapDispatchToProps)(SpendPlan)))
